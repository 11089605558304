import React from "react";
import logo from '../../assets/bb-logo-404.svg';
import {
    Grid
} from "@mui/material";

class NotFound extends React.Component {

    render() {
        return (
            <Grid item container style={{ backgroundColor: "#202942", height: "100vh", textAlign: "center", color: "#ebeff0" }}>
                <div className="loading-position">
                    <img src={logo} alt="logo" />
                    <span style={{ fontSize: "11em", fontWeight: "700", lineHeight: "100%", display: "block" }}>
                        404
                    </span>
                    <span style={{ fontSize: "2em", fontWeight: "400", display: "block" }}>
                        Sorry, Page Not Found
                    </span>
                    <span style={{ fontSize: "1em", fontWeight: "400", display: "block" }}>
                        The page you requested could not be found
                    </span>
                </div>
            </Grid >
        );
    }
}

export default NotFound;
