import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Drawer, List, ListItem, ListItemText, Box, Divider } from '@mui/material';
import Icon from '@mui/material/Icon';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { withRouter } from 'react-router-dom';
import '../../components/sidebar/Sidebar.css';
import logo from '../../assets/bb-logo.png';
import { connect } from 'react-redux';
import ConfirmationDialog from '../common/ConfirmationDialog';
import { logout } from '../../redux/merchant/actions';
import { hasRole } from '../../security/Security';

class Sidebar extends Component {

    state = {
        items: this.props.items,
        openLogoutDialog: false
    };

    handleClickItem = () => {
        if (this.props.mobileOpen) {
            this.props.handleDrawerToggle();
        }
    };

    render() {

        const drawer = (
            <div>
                <div style={{ textAlign: 'center', height: 67, padding: 7, cursor: 'pointer' }} onClick={() => this.props.history.push("/revenue")}>
                    <img width="auto" height="50" src={logo} alt="logo" title={this.props.t("go_to_home")} />
                </div>
                <div className="username" style={{ paddingLeft: '20px', paddingBottom: '10px', fontWeight: 'bold' }}>
                    {this.props.user.username}
                </div>
                <List disablePadding>
                    <PerfectScrollbar style={{ height: 'calc(100vh - 70px)' }}>
                        {this.state.items.map((item, parentIndex) => {
                            if (hasRole(this.props.user, item.roles)) {
                                return <div key={"parent-" + parentIndex}>
                                    <ListItem
                                        classes={{ selected: "sidebar-item-selected" }}
                                        className={this.props.location.pathname.includes(item.link) ? "sidebar-item-selected" : "sidebar-item-not-selected"}
                                        selected={this.props.location.pathname.includes(item.link)}
                                        button
                                        onClick={() => this.handleClickItem()}
                                        component={Link}
                                        to={item.link}>
                                        <Icon style={{ marginRight: 10 }}>{item.icon}</Icon>
                                        <ListItemText primaryTypographyProps={{ fontWeight: this.props.location.pathname.includes(item.link) ? 'bold' : 'unset' }} primary={this.props.t(item.title)} />
                                    </ListItem>
                                    {item.divider && <Divider style={{ marginLeft: 10, marginRight: 10, backgroundColor: 'lightGray' }} />}
                                </div>
                            }
                            return null;
                        })}
                        <span className="logout">
                            <ListItem
                                button
                                key={"logout"}
                                onClick={() => this.setState({ openLogoutDialog: true })}>
                                <Icon style={{ marginRight: 10 }}>logout</Icon>
                                <ListItemText primary={this.props.t("logout")} />
                            </ListItem>
                        </span>
                    </PerfectScrollbar>
                </List>
                <div style={{ position: 'absolute', textAlign: 'center', bottom: 20, width: '100%' }}>
                    v{process.env.REACT_APP_VERSION || 'NOT_SET'}
                </div>
            </div >
        );

        const container = window !== undefined ? () => window.document.body : undefined;

        return (
            <Box
                boxShadow={10}
                component="nav"
                sx={{ width: { sm: '240px' }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    classes={{
                        paper: "drawerPaper",
                    }}
                    container={container}
                    variant="temporary"
                    open={this.props.mobileOpen}
                    onClose={this.props.handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '240px' },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    classes={{
                        paper: "drawerPaper",
                    }}
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '240px' },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
                <ConfirmationDialog
                    isOpen={this.state.openLogoutDialog}
                    title={this.props.t("logout")}
                    message={this.props.t("logout_message")}
                    onClose={() => this.setState({ openLogoutDialog: false })}
                    onConfirm={this.props.logout}
                    closeButtonTitle={this.props.t("no")}
                    confirmButtonTitle={this.props.t("yes")}
                />
            </Box>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.merchantReducer.user
});

export default connect(mapStateToProps, { logout })(withRouter(withTranslation()(Sidebar)));
