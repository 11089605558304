import React from "react";
import moment from "moment";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  Grid,
  Button,
  Fab,
  Typography,
  TextField,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  FormControl,
  Select,
  MenuItem,
  Alert,
  InputLabel
} from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ConfirmationDialog from "../../components/common/ConfirmationDialog";
import { hasRole } from '../../security/Security';
import { getPOSMerchants } from '../../redux/admin/actions';

class Notifications extends React.Component {

  emptyValidation = {
    emailError: false,
    emailErrorText: ''
  };

  constructor(props) {
    super(props);
    this.state = {
      initialEmails: [],
      emails: [],
      editEmailIndex: -1,
      validation: JSON.parse(JSON.stringify(this.emptyValidation)),
      emailToDelete: null,
      selectedMerchant: null
    };
  }

  componentDidMount() {
    if (hasRole(this.props.user, ["MERCHANT"])) {
      this.getEmails();
    }
    if (hasRole(this.props.user, ["ADMIN"])) {
      this.props.getPOSMerchants().then(response => {
        this.setState({ merchants: response.data });
      });
    }
  }

  getEmails(id = null) {
    this.props.getEmails(id).then(response => {
      this.setState({ emails: JSON.parse(JSON.stringify(response.data)), editEmailIndex: -1 });
    });
  }

  handleChangeEmail = (e, index) => {
    let { emails, validation } = this.state;
    emails[index].email = e.target.value;

    let regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (e.target.value.length === 0) {
      validation.emailError = true;
      validation.emailErrorText = this.props.t("required_field");
    } else if (!regex.test(e.target.value)) {
      validation.emailError = true;
      validation.emailErrorText = this.props.t("invalid_email_message");
    } else {
      validation.emailError = false;
      validation.emailErrorText = "";
    }

    this.setState({ emails: emails, validation: validation });
  }

  handleChangeStatus = (e, index) => {
    let { emails } = this.state;
    emails[index].status = e.target.value;
    this.setState({ emails: emails });
  }

  addEmail = () => {
    let { emails } = this.state;
    let newEmail = {
      id: -1,
      email: '',
      status: 'ACTIVE'
    }
    emails.push(newEmail);
    this.setState({ emails: emails, editEmailIndex: emails.length - 1 });
  }

  save = (index) => {
    let { emails, selectedMerchant } = this.state;
    let action = null;
    if (hasRole(this.props.user, ["MERCHANT"])) {
      action = emails[index].id > -1 ? this.props.updateEmail(emails[index].id, emails[index]) : this.props.createEmail(emails[index]);
    } else {
      let email = emails[index];
      email.merchantId = selectedMerchant;
      action = emails[index].id > -1 ? this.props.updateEmail(emails[index].id, emails[index]) : this.props.createEmail(email);
    }

    action.then(status => {
      if (status === 200) {
        if (hasRole(this.props.user, ["MERCHANT"])) {
          this.getEmails();
        } else {
          this.getEmails(selectedMerchant);
        }
      }
    });
  }

  cancel = (index) => {
    let { emails } = this.state;
    if (this.props.reducer.emails[index]) {
      emails[index] = JSON.parse(JSON.stringify(this.props.reducer.emails[index]));
    } else {
      emails.splice(index, 1);
    }
    this.setState({ emails, editEmailIndex: -1, validation: JSON.parse(JSON.stringify(this.emptyValidation)) });
  }

  deleteEmail = () => {
    const { emailToDelete, selectedMerchant } = this.state;
    this.props.deleteEmail(emailToDelete.id).then(status => {
      if (status === 200) {
        this.setState({ openDeleteDialog: false });
        if (hasRole(this.props.user, ["MERCHANT"])) {
          this.getEmails();
        } else {
          this.getEmails(selectedMerchant);
        }
      }
    });
  }

  handleChangeMerchant = (event) => {
    this.setState({ selectedMerchant: event.target.value }, () => this.getEmails(event.target.value));
  }

  render() {
    const { emails, validation, editEmailIndex, merchants, selectedMerchant } = this.state;

    let data;
    if (emails && emails.length > 0) {
      data = emails.map((email, index) => {
        return <TableRow key={index}>
          <TableCell className="table-cell">
            {index !== editEmailIndex ? email.email :
              <TextField
                autoFocus
                id={"email" + index}
                name={"email" + index}
                onChange={(e) => this.handleChangeEmail(e, index)}
                value={email.email}
                required
                inputProps={{
                  autoComplete: 'off',
                  spellCheck: false
                }}
                helperText={validation.emailErrorText}
                error={!email.email || validation.emailError}
              />
            }
          </TableCell>
          <TableCell className="table-cell">
            {index !== editEmailIndex ? this.props.t(email.status.toLowerCase()) :
              <FormControl>
                <Select
                  id={"status" + index}
                  name={"status" + index}
                  value={email.status}
                  onChange={(e) => this.handleChangeStatus(e, index)}
                >
                  <MenuItem key="ACTIVE" value="ACTIVE">{this.props.t("active")}</MenuItem>
                  <MenuItem key="DISABLED" value="DISABLED">{this.props.t("disabled")}</MenuItem>
                </Select>
              </FormControl>}
          </TableCell>
          <TableCell className="table-cell">{email.created && moment.tz(email.created, email.timeZone).format('DD.MM.YYYY. HH:mm:ss')}</TableCell>
          <TableCell className="table-cell">{email.updated && moment.tz(email.updated, email.timeZone).format('DD.MM.YYYY. HH:mm:ss')}</TableCell>
          <TableCell className="table-cell">
            {index !== editEmailIndex ?
              <>
                <Fab disabled={editEmailIndex > -1} className="table-fab" title={this.props.t("edit")} onClick={() => this.setState({ editEmailIndex: index })} style={{ marginRight: 10 }} color="primary">
                  <EditIcon className="table-fab-icon" />
                </Fab>
                <Fab disabled={editEmailIndex > -1} className="table-fab" title={this.props.t("delete")} id={"emails-tbl-actions-del-btn-" + index} color="inherit" aria-label="delete" onClick={() => this.setState({ openDeleteDialog: true, emailToDelete: email })}>
                  <DeleteIcon className="table-fab-icon" />
                </Fab>
              </>
              : <>
                <Fab disabled={!emails[index].email || validation.emailError || JSON.stringify(this.props.reducer.emails) === JSON.stringify(emails)} color="primary" style={{ marginRight: '10px' }} className="table-fab" title={this.props.t("save")} aria-label="save" onClick={() => this.save(index)}>
                  <CheckCircleIcon className="table-fab-icon" />
                </Fab>
                <Fab className="table-fab" title={this.props.t("cancel")} color="inherit" aria-label="cancel" onClick={() => this.cancel(index)}>
                  <CancelIcon className="table-fab-icon" />
                </Fab>
              </>}
          </TableCell>
        </TableRow>
      });
    } else {
      data = <TableRow>
        <TableCell align="center" colSpan={5}>
          {this.props.t("records_not_found")}
        </TableCell>
      </TableRow>
    }

    return (
      <>
        <Grid container direction="row" spacing={2}>
          <Grid item container>
            <Typography variant="h6" style={{ alignItems: 'center', display: 'flex' }}>
              <Fab
                className="table-fab"
                title={this.props.t("back")}
                size="small"
                color="primary"
                onClick={() => this.props.history.goBack()}>
                <ArrowBack />
              </Fab> <span style={{ marginLeft: 10, marginRight: 20 }}>{this.props.t("notifications")}</span>
            </Typography>
            {hasRole(this.props.user, ["ADMIN"]) &&
              <FormControl required style={{ width: '300px' }}>
                <InputLabel id="merchant">{this.props.t("pos_merchant")}</InputLabel>
                <Select
                  labelId="merchant"
                  id="merchant"
                  name="merchant"
                  value={selectedMerchant || ''}
                  onChange={this.handleChangeMerchant}
                  label="merchant">
                  {merchants && merchants.length > 0 && merchants.map(merchant => {
                    return <MenuItem key={merchant.id} value={merchant.id}>{merchant.name}</MenuItem>
                  })}
                </Select>
              </FormControl>}
            <Button disabled={editEmailIndex !== -1 || (hasRole(this.props.user, ["ADMIN"]) && !selectedMerchant)} style={{ marginLeft: 'auto', height: '40px' }} onClick={() => this.addEmail()} color="primary">
              {this.props.t("add_email")}
            </Button>
          </Grid>
          <Grid item container direction="column" xs={12} sm={12} md={12} lg={10} xl={6}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className="header-table-cell" component="th" scope="row" width="30%">{this.props.t("email")}</TableCell>
                    <TableCell className="header-table-cell" component="th" scope="row" width="20%">{this.props.t("status")}</TableCell>
                    <TableCell className="header-table-cell" component="th" scope="row" width="20%">{this.props.t("created")}</TableCell>
                    <TableCell className="header-table-cell" component="th" scope="row" width="20%">{this.props.t("updated")}</TableCell>
                    <TableCell className="header-table-cell" component="th" scope="row" width="10%">{this.props.t("actions")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data}
                </TableBody>
              </Table>
            </TableContainer>
            <Alert severity="info">{this.props.t("notifications_email_info_message")}</Alert>
          </Grid>
        </Grid>
        {this.state.emailToDelete && <ConfirmationDialog
          isOpen={this.state.openDeleteDialog}
          title={this.props.t("delete_email_dialog_title")}
          message={this.props.t("delete_email_dialog_message", { email: this.state.emailToDelete.email })}
          onClose={() => this.setState({ openDeleteDialog: false })}
          onConfirm={() => this.deleteEmail()}
          closeButtonTitle={this.props.t("no")}
          confirmButtonTitle={this.props.t("yes")}
        />}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.merchantReducer.user
})

const mapActionsToProps = { getPOSMerchants }

export default connect(mapStateToProps, mapActionsToProps)(withTranslation()(Notifications))
