export const SET_USER_LOADING = 'SET_USER_LOADING';
export const SET_USER = 'SET_USER';
export const SET_LOGIN_ERROR = 'SET_LOGIN_ERROR';

export const SET_ORDERS_LOADING = 'SET_ORDERS_LOADING';
export const SET_ORDERS = 'SET_ORDERS';
export const SET_ORDERS_PAGE_SIZE = 'SET_MERCHANTS_PAGE_SIZE';

export const SET_ORDER_HISTORY_LOADING = 'SET_ORDER_HISTORY_LOADING';
export const SET_ORDER_HISTORY = 'SET_ORDER_HISTORY';
export const SET_ORDER_HISTORY_PAGE_SIZE = 'SET_ORDER_HISTORY_PAGE_SIZE';

export const SET_TOKENS_LOADING = 'SET_TOKENS_LOADING';
export const SET_TOKENS = 'SET_TOKENS';
export const SET_TOKENS_PAGE_SIZE = 'SET_TOKENS_PAGE_SIZE';

export const SET_TOKEN_LOADING = 'SET_TOKEN_LOADING';

export const SET_TRANSACTIONS_LOADING = 'SET_TRANSACTIONS_LOADING';
export const SET_TRANSACTIONS = 'SET_TRANSACTIONS';
export const SET_TRANSACTIONS_PAGE_SIZE = 'SET_TRANSACTIONS_PAGE_SIZE';

export const SET_POS_USERS_LOADING = 'SET_TRANSACTIONS_LOADING';
export const SET_POS_USERS = 'SET_POS_USERS';
export const SET_POS_USERS_PAGE_SIZE = 'SET_POS_USERS_PAGE_SIZE';

export const SET_RECEIPT_DATA = 'SET_RECEIPT_DATA';

export const SET_REVENUE = 'SET_REVENUE';
export const SET_DAILY_REVENUES = 'SET_DAILY_REVENUES';

export const SET_2FA_DATA_LOADING = 'SET_2FA_DATA_LOADING';
export const SET_2FA_DATA = 'SET_2FA_DATA';

export const SET_EMAILS_LOADING = 'SET_EMAILS_LOADING';
export const SET_EMAILS = 'SET_EMAILS';

export const SET_CURRENT_BALANCE = 'SET_CURRENT_BALANCE';

const types = {
    SET_USER_LOADING,
    SET_USER,
    SET_LOGIN_ERROR,
    SET_ORDERS_LOADING,
    SET_ORDERS,
    SET_ORDERS_PAGE_SIZE,
    SET_ORDER_HISTORY_LOADING,
    SET_ORDER_HISTORY,
    SET_ORDER_HISTORY_PAGE_SIZE,
    SET_TOKENS_LOADING,
    SET_TOKENS,
    SET_TOKENS_PAGE_SIZE,
    SET_TOKEN_LOADING,
    SET_TRANSACTIONS_LOADING,
    SET_TRANSACTIONS,
    SET_TRANSACTIONS_PAGE_SIZE,
    SET_POS_USERS_LOADING,
    SET_POS_USERS,
    SET_POS_USERS_PAGE_SIZE,
    SET_RECEIPT_DATA,
    SET_REVENUE,
    SET_DAILY_REVENUES,
    SET_2FA_DATA_LOADING,
    SET_2FA_DATA,
    SET_EMAILS_LOADING,
    SET_EMAILS,
    SET_CURRENT_BALANCE
};

export default types;