import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Logout from '@mui/icons-material/Logout';
import AccountCircle from '@mui/icons-material/AccountCircle';
import '../../components/sidebar/Sidebar.css';
import { connect } from 'react-redux';
import { logout } from './../../redux/merchant/actions';
import './Navbar.css';
import ConfirmationDialog from '../common/ConfirmationDialog';
import LanguageMenu from '../common/LanguageMenu';

class Navbar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openLogoutDialog: false
        }
    }

    render() {
        return (
            <AppBar color="default" position="fixed" className={this.props.authenticated ? "appBar" : ""}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={this.props.handleDrawerToggle}
                        className="menuButton">
                        <MenuIcon />
                    </IconButton>
                    <div className="title">
                        {this.props.t("crypto_payment_gateway")}
                    </div>
                    <div className='navbar-menu'>
                        <Typography style={{ alignItems: 'center', display: 'flex', fontWeight: 'bold' }}>
                            <AccountCircle style={{ marginRight: '5px', color: '#155DA7' }} /> {this.props.merchantReducer.user.username} ({this.props.t(this.props.merchantReducer.user.role.split("_")[1])})
                            <Button style={{ marginLeft: '20px', marginRight: '20px' }} color="primary" onClick={() => this.setState({ openLogoutDialog: true })} startIcon={<Logout />}>
                                {this.props.t("logout")}
                            </Button>
                        </Typography>
                    </div>
                    <LanguageMenu />
                </Toolbar>
                <ConfirmationDialog
                    isOpen={this.state.openLogoutDialog}
                    title={this.props.t("logout")}
                    message={this.props.t("logout_message")}
                    onClose={() => this.setState({ openLogoutDialog: false })}
                    onConfirm={this.props.logout}
                    closeButtonTitle={this.props.t("no")}
                    confirmButtonTitle={this.props.t("yes")}
                />
            </AppBar>
        );
    }
}

const mapStateToProps = (state) => ({
    merchantReducer: state.merchantReducer
})

export default connect(mapStateToProps, { logout })(withTranslation()(Navbar));
