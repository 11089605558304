import React from "react";
import { withTranslation } from 'react-i18next';
import moment from "moment";
import { connect } from 'react-redux';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Fab,
  Typography,
  Collapse,
  Box,
  TableHead,
  Button,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { hasRole } from '../../security/Security';
import Base58Table from "../../components/common/Base58Table";
import { getMerchantPOSReports, getAdminPOSReports } from '../../redux/reports/actions';
import { getPOSMerchants, getMerchantOrders } from '../../redux/admin/actions';
import { getDailyRevenues } from '../../redux/merchant/actions';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { PointOfSale } from "@mui/icons-material";
import NumberFormat from "react-number-format";
import CreditScore from '@mui/icons-material/CreditScore';
import ConfirmationDialog from "../../components/common/ConfirmationDialog";

class POSReports extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      params: { sort: 'created,desc' },
      collapseIndex: -1,
      selectedMerchant: -1,
      openUpdateOrderStatusDialog: false,
      updateOrder: null
    };
  }

  componentDidMount() {
    this.getOrders(0);
    if (hasRole(this.props.user, ["MERCHANT"])) {
      this.props.getDailyRevenues({ params: { from: moment().format('YYYY-MM-DD'), to: moment().format('YYYY-MM-DD') } });
    }
    if (hasRole(this.props.user, ["ADMIN"])) {
      this.props.getPOSMerchants();
    }
  }

  getOrders = (page) => {
    let { params, selectedMerchant } = this.state;
    params.page = page;
    params.size = this.props.reducer.size;

    if (!params.sort) {
      params.sort = "created,desc";
    }

    this.setState({ page: page, collapseIndex: -1 },
      () => selectedMerchant > -1 ? this.props.getMerchantOrders(selectedMerchant, { params: params }) : this.props.getOrders({ params: params }));
  }

  getMerchantOrders = (value, page) => {
    let { params } = this.state;
    params.page = page;
    params.size = this.props.reducer.size;

    this.setState({ page: page, collapseIndex: -1 },
      () => this.props.getMerchantOrders(value, { params: params }));
  }

  onPageChange = (event, page) => {
    const { selectedMerchant } = this.state;
    this.setState({ page: page }, () => {
      selectedMerchant > -1 ? this.getMerchantOrders(selectedMerchant, page) : this.getOrders(page);
    })
  }

  onRowsPerPageChange = (event) => {
    const { selectedMerchant } = this.state;
    this.props.updateOrdersPageSize(event.target.value).then(() => {
      this.setState({ page: 0 });
      selectedMerchant > -1 ? this.getMerchantOrders(selectedMerchant, 0) : this.getOrders(0);
    })
  }

  status(status) {
    switch (status) {
      case 'NEW':
        return <span style={{ color: 'blue', fontWeight: 'bold' }}>{this.props.t(status)}</span>;
      case 'PAID':
        return <span style={{ color: 'green', fontWeight: 'bold' }}>{this.props.t(status)}</span>;
      case 'CONFIRMED':
        return <span style={{ color: 'green', fontWeight: 'bold' }}>{this.props.t(status)}</span>;
      default:
        return <span style={{ color: 'red', fontWeight: 'bold' }}>{this.props.t(status)}</span>;
    }
  }

  getAmountDifferenceColor(status, amount) {
    switch (status) {
      case 'PAID':
        return { color: amount > 0 ? 'green' : 'red', fontWeight: 'bold' };
      case 'CONFIRMED':
        return { color: amount > 0 ? 'green' : 'red', fontWeight: 'bold' };
      case 'UNDERPAID':
        return { color: amount > 0 ? 'green' : 'red', fontWeight: 'bold' };
      case 'UNDERPAID_CONFIRMED':
        return { color: amount > 0 ? 'green' : 'red', fontWeight: 'bold' };
      default:
        return { display: 'none' };
    }
  }

  export = () => {
    if (hasRole(this.props.user, ["ADMIN"])) {
      this.props.getAdminPOSReports({
        params: {
          merchantId: this.state.selectedMerchant
        },
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/pdf',
          //'Access-Control-Expose-Headers': 'Content-Disposition',
          "Accept-Language": localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') : "en"
        },
        responseType: 'blob'
      }).then(response => {
        this.handleContentDisposition(response);
      });
    } else {
      this.props.getMerchantPOSReports({
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/pdf',
          //'Access-Control-Expose-Headers': 'Content-Disposition',
          "Accept-Language": localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') : "en"
        },
        responseType: 'blob'
      }).then(response => {
        this.handleContentDisposition(response);
      });
    }
  }

  handleContentDisposition(response) {
    const blob = new Blob([response.data]);
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    const contentDisposition = response.headers["content-disposition"];
    let fileName = contentDisposition.split("filename=")[1];
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  }

  getPaymentData = (orderId, status) => {
    this.props.getPaymentData(orderId).then(() => {
      this.props.history.push(status.startsWith("UNDERPAID") ? "/pos/UNDERPAID" : "/pos/NEW");
    });
  }

  handleChangeMerchant = (event) => {
    this.setState({ selectedMerchant: event.target.value }, () => event.target.value !== -1 ? this.getMerchantOrders(event.target.value, 0) : this.getOrders(0));
  }

  updateOrderStatus = () => {
    const { updateOrder, page } = this.state;
    this.props.updateOrderStatus(updateOrder.orderId).then(() => {
      this.setState({
        openUpdateOrderStatusDialog: false,
        updateOrder: null
      }, () => this.onPageChange(null, page));
    });
  }

  render() {
    const { page, selectedMerchant } = this.state;
    let { orders, totalElements, size, isLoading } = this.props.reducer;
    let { posMerchants } = this.props.adminReducer;

    // if (page === 0 && isLoading) {
    //   return (
    //     <div className="loading-position">
    //       <CircularProgress className="spinner" color="primary" />
    //     </div>
    //   )
    // }

    let colSpan = hasRole(this.props.user, ["MERCHANT"]) ? 12 : 11;

    let data;
    if (orders && orders.length > 0) {
      data = orders.map((order, index) => {
        return <React.Fragment key={order.orderId}>
          <TableRow>
            {hasRole(this.props.user, ["MERCHANT", "ADMIN"]) && <TableCell style={{ fontWeight: index === this.state.collapseIndex ? 'bold' : 'unset' }} className="table-cell">
              {order.childMerchantUsername ? order.childMerchantUsername : order.merchantUsername}
            </TableCell>}
            <TableCell style={{ fontWeight: index === this.state.collapseIndex ? 'bold' : 'unset' }} className="table-cell">{order.referenceNo}</TableCell>
            <TableCell align="right" style={{ fontWeight: index === this.state.collapseIndex ? 'bold' : 'unset', borderLeft: '1px solid black' }} className="table-cell">
              <NumberFormat value={order.fiatPrice} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} /> {order.currency}
            </TableCell>
            {hasRole(this.props.user, ["ADMIN"]) && <TableCell align="right" style={{ fontWeight: index === this.state.collapseIndex ? 'bold' : 'unset' }} className="table-cell">
              {order.payedAmountInFiat !== 0 && <span><NumberFormat value={order.payedAmountInFiat} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} /> {order.currency}</span>}
            </TableCell>}
            <TableCell align="right" style={{ fontWeight: index === this.state.collapseIndex ? 'bold' : 'unset' }} className="table-cell">
              {order.payedAmountInFiatMinusFiatPriceWithFiatFee !== 0 && <span style={this.getAmountDifferenceColor(order.status, order.payedAmountInFiatMinusFiatPriceWithFiatFee)}><NumberFormat value={order.payedAmountInFiatMinusFiatPriceWithFiatFee} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} /> {order.currency} </span>}
            </TableCell>
            {hasRole(this.props.user, ["ADMIN"]) && <>
              <TableCell align="right" style={{ fontWeight: index === this.state.collapseIndex ? 'bold' : 'unset', borderLeft: '1px solid black' }} className="table-cell">
                <NumberFormat value={order.quota} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={8} fixedDecimalScale={true} /> {order.cryptoAsset}
              </TableCell>
              <TableCell align="right" style={{ fontWeight: index === this.state.collapseIndex ? 'bold' : 'unset' }} className="table-cell">
                {order.payedCryptoAmount && <span><NumberFormat value={order.payedCryptoAmount} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={8} fixedDecimalScale={true} /> {order.cryptoAsset}</span>}
              </TableCell>
              <TableCell align="right" style={{ fontWeight: index === this.state.collapseIndex ? 'bold' : 'unset' }} className="table-cell">
                {order.payedCryptoAmountMinusQuota !== 0 && <span style={this.getAmountDifferenceColor(order.status, order.payedCryptoAmountMinusQuota)}><NumberFormat value={order.payedCryptoAmountMinusQuota} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={8} fixedDecimalScale={true} /> {order.cryptoAsset} </span>}
              </TableCell>
            </>}
            <TableCell style={{ fontWeight: index === this.state.collapseIndex ? 'bold' : 'unset', borderLeft: '1px solid black' }} className="table-cell">
              {this.status(order.status)} {hasRole(this.props.user, ["ADMIN"]) && order.status.startsWith("UNDERPAID") && <Fab style={{ marginLeft: '10px' }} title={this.props.t("update_order_status")} color="primary" className="table-fab" variant="extended" onClick={() => this.setState({ openUpdateOrderStatusDialog: true, updateOrder: order })}><CreditScore className="table-icon" /></Fab>}
            </TableCell>
            <TableCell className="table-cell">
              <Button
                disabled={order.items && order.items.length === 0}
                id={"items-list-" + index}
                onClick={() => this.setState({ collapseIndex: this.state.collapseIndex !== index ? index : -1 })}>
                {index === this.state.collapseIndex ? this.props.t("collapse") : this.props.t("expand")}
              </Button>
            </TableCell>
            <TableCell style={{ fontWeight: index === this.state.collapseIndex ? 'bold' : 'unset' }} className="table-cell">{moment.tz(order.created, order.timeZone).format('DD.MM.YYYY. HH:mm:ss')}</TableCell>
            <TableCell style={{ fontWeight: index === this.state.collapseIndex ? 'bold' : 'unset' }} className="table-cell">
              {(order.status === "NEW" || order.status === "UNDERPAID") && <Fab title={this.props.t("go_to_pos")} className="table-fab" onClick={() => this.getPaymentData(order.orderId, order.status)} color="primary" aria-label="pos">
                <PointOfSale className="table-fab-icon" />
              </Fab>}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0, backgroundColor: '#E4E9F8' }} colSpan={colSpan}>
              <Collapse in={index === this.state.collapseIndex} timeout="auto" unmountOnExit style={{ paddingTop: 10, paddingBottom: 20 }}>
                <Box sx={{ margin: 1 }}>
                  <Typography variant="h6" gutterBottom>
                    {this.props.t("items")}
                  </Typography>
                  <TableContainer component={Paper} style={{ width: '500px' }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell className="header-table-cell" component="th" scope="row" width="50%">{this.props.t("item")}</TableCell>
                          <TableCell className="header-table-cell" component="th" scope="row" width="20%">{this.props.t("quantity")}</TableCell>
                          <TableCell className="header-table-cell" component="th" scope="row" width="30%">{this.props.t("price")}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {order.items && order.items.length > 0 && order.items.map(item => {
                          return <TableRow key={item.id}>
                            <TableCell className="table-cell">{item.item}</TableCell>
                            <TableCell className="table-cell">
                              <NumberFormat value={item.quantity} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} />
                            </TableCell>
                            <TableCell className="table-cell" align="right">
                              <NumberFormat value={item.price} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} /> {order.currency}
                            </TableCell>
                          </TableRow>
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        </React.Fragment>
      });
    } else {
      data = <TableRow>
        <TableCell align="center" colSpan={colSpan}>
          {this.props.t("records_not_found")}
        </TableCell>
      </TableRow>
    }

    let columns = [];

    const childMerchant = { name: "child_merchant", dbName: 'childMerchantUsername', width: '10%', sort: false, filter: true };

    if (hasRole(this.props.user, ["MERCHANT", "ADMIN"])) {
      columns.push(childMerchant);
    }

    let commonColumns = [
      {
        name: "reference_no",
        dbName: 'referenceNo',
        width: '10%',
        sort: false,
        filter: true
      },
      {
        name: "required_amount",
        dbName: 'fiatPrice',
        width: '8%',
        sort: true,
        filter: false
      }];

    columns = columns.concat(commonColumns);

    if (hasRole(this.props.user, ["ADMIN"])) {
      const payedAmount = {
        name: "payed_amount",
        dbName: 'payedAmountInFiat',
        width: '8%',
        sort: false,
        filter: false
      };
      columns.push(payedAmount);
    }

    let amountDifference = {
      name: "amount_difference",
      dbName: 'quota',
      width: '8%',
      sort: false,
      filter: false
    }
    columns.push(amountDifference);

    if (hasRole(this.props.user, ["ADMIN"])) {
      let adminColumns = [{
        name: "required_crypto_amount",
        dbName: 'quota',
        width: '9%',
        sort: false,
        filter: false
      },
      {
        name: "payed_crypto_amount",
        dbName: 'payedCryptoAmount',
        width: '9%',
        sort: false,
        filter: false
      },
      {
        name: "crypto_amount_difference",
        dbName: 'quota',
        width: '10%',
        sort: false,
        filter: false
      }];
      columns = columns.concat(adminColumns);
    }

    let commonColumns2 = [{
      name: "status",
      dbName: 'status',
      type: "select",
      items: ["NEW",
        "EXPIRED",
        "CHANGE_ADDRESS_DIRTY",
        "PAID",
        "CONFIRMED",
        "CONFIRMED_WITH_CHANGE",
        "COMPLETED",
        "FAILED",
        "UNDERPAID_RETURNED",
        "UNDERPAID_NO_RETURN",
        "UNDERPAID_CONFIRMED",
        "UNDERPAID",
        "TIMEOUT_RETURNED",
        "TIMEOUT_NO_RETURN",
        "TIMEOUT_CONFIRMED",
        "TIMEOUT",
        "ADDRESS_DIRTY",
        "DUPLICATE",
        "DUPLICATE_ADDRESS_DIRTY",
        "NOTIFY_FAILED",
        "NOTIFIED",
        "RETURN_MONEY_FAILED",
        "LOCK_INPUTS_FAILED",
        "IMMEDIATE_EXCHANGE",
        "IMMEDIATE_EXCHANGE_FAILED",
        "ADMIN_CHANGED_STATUS"],
      width: '11%',
      sort: false,
      filter: true
    },
    {
      name: "items",
      width: '5%'
    },
    {
      name: "created",
      dbName: 'created',
      width: '8%',
      sort: true
    },
    {
      name: "pos",
      width: '4%'
    },
    ];

    columns = columns.concat(commonColumns2);

    return (
      <Grid container direction="row" spacing={2}>
        <Grid item container style={{ alignItems: 'center', display: 'flex' }}>
          <Typography variant="h6" style={{ alignItems: 'center', display: 'flex' }}>
            <Fab
              className="table-fab"
              title={this.props.t("back")}
              size="small"
              color="primary"
              onClick={() => this.props.history.goBack()}>
              <ArrowBack />
            </Fab> <span style={{ marginLeft: 10, marginRight: 20 }}>{this.props.t("pos_reports")}</span>
          </Typography>
          {hasRole(this.props.user, ["ADMIN"]) &&
            <FormControl required style={{ width: '300px' }}>
              <InputLabel id="merchant">{this.props.t("pos_merchant")}</InputLabel>
              <Select
                labelId="merchant"
                id="merchant"
                name="merchant"
                value={selectedMerchant || ''}
                onChange={this.handleChangeMerchant}
                label="merchant">
                <MenuItem key="all" value={-1}>{this.props.t("all")}</MenuItem>
                {posMerchants && posMerchants.length > 0 && posMerchants.map(merchant => {
                  return <MenuItem key={merchant.id} value={merchant.id}>{merchant.name}</MenuItem>
                })}
              </Select>
            </FormControl>}
          <Button disabled={hasRole(this.props.user, ["ADMIN"]) && selectedMerchant === -1} style={{ marginLeft: 'auto', height: '40px' }} onClick={() => this.export()} color="primary" startIcon={<PictureAsPdfIcon />}>
            {this.props.t("export")}
          </Button>
        </Grid>
        <Grid item container>
          <Base58Table
            isLoading={isLoading}
            columns={columns}
            data={data}
            count={totalElements}
            rowsPerPage={size}
            page={page}
            onPageChange={this.onPageChange}
            onRowsPerPageChange={this.onRowsPerPageChange}
            onFilterChange={(params) => this.setState({ params: params }, () => this.getOrders(0))}
          />
        </Grid>
        {this.props.merchantReducer.dailyRevenues && this.props.merchantReducer.dailyRevenues.length > 0 && <Grid item container>
          <Typography variant="h6">
            {this.props.t("daily_revenue")}
          </Typography>
        </Grid>}
        {this.props.merchantReducer.dailyRevenues && this.props.merchantReducer.dailyRevenues.length > 0 && this.props.merchantReducer.dailyRevenues.map(posUser => {
          return <Grid item container direction="column" xs={2} sm={2} md={2} lg={2} xl={2}>
            <Card className="card-border2" style={{ backgroundColor: '#E4E9F8' }}>
              <CardContent style={{ textAlign: 'center' }}>
                <Typography variant="h6" component="div">
                  <b>{posUser.username}</b>
                  <span style={{ fontWeight: 'bold', color: 'green', display: 'block' }}><NumberFormat value={posUser.fiatPrice} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} /> {posUser.currency}</span>
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        })}
        {this.state.updateOrder && <ConfirmationDialog
          isOpen={this.state.openUpdateOrderStatusDialog}
          title={this.props.t("update_order_status")}
          message={this.props.t("update_order_status_message", { amountDifference: this.state.updateOrder.payedAmountInFiatMinusFiatPriceWithFiatFee + " " + this.state.updateOrder.currency })}
          onClose={() => this.setState({ openUpdateOrderStatusDialog: false })}
          onConfirm={() => this.updateOrderStatus()}
          closeButtonTitle={this.props.t("no")}
          confirmButtonTitle={this.props.t("yes")}
        />}
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.merchantReducer.user,
  merchantReducer: state.merchantReducer,
  adminReducer: state.adminReducer
})

const mapActionsToProps = { getMerchantPOSReports, getAdminPOSReports, getDailyRevenues, getPOSMerchants, getMerchantOrders }

export default connect(mapStateToProps, mapActionsToProps)(withTranslation()(POSReports))

