import React, { Component } from 'react';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
    Grid,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Fab,
    Autocomplete,
    Box,
    TextField
} from '@mui/material';
import {
    createSettlementOrder,
    getSettlementOrders,
    updateSettlementOrder,
    getNewSettlementOrders
} from '../../redux/settlement/actions';
import NumberFormat from 'react-number-format';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmationDialog from '../../components/common/ConfirmationDialog';

class CreateSettlementDialog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedSettlement: null,
            orderToRemove: null,
            openDeleteDialog: false
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.open && prevProps.open !== this.props.open) {
            this.props.getNewSettlementOrders({
                params: {
                    merchantId: this.props.settlementOrders[0].merchantId,
                    currency: this.props.settlementOrders[0].currency
                }
            });
        }
    }

    createSettlement = (e) => {
        e.preventDefault();

        let paymentOrderIds = [];
        this.props.settlementOrders.forEach(so => {
            paymentOrderIds.push(so.orderId);
        });

        let { selectedSettlement } = this.state;
        if (selectedSettlement) {
            this.props.updateSettlementOrder(selectedSettlement.settlementOrderId, { 'paymentOrderIdsToAdd': paymentOrderIds }).then(() => {
                this.setState({ selectedSettlement: null });
                this.props.onSave();
            });
        } else {
            this.props.createSettlementOrder({ 'paymentOrderIds': paymentOrderIds }).then(() => {
                this.setState({ selectedSettlement: null });
                this.props.onSave();
            });
        }
    }

    getTotal = () => {
        let total = 0;
        this.props.settlementOrders.forEach(so => {
            total += Number(so.fiatPrice);
        });
        return total;
    }

    getCryptoTotal = () => {
        let total = 0;
        this.props.settlementOrders.forEach(so => {
            total += Number(so.cryptoAmountOrdersDisplay);
        });
        return total;
    }

    render() {

        const { selectedSettlement } = this.state;

        return (
            <Dialog fullWidth={true} maxWidth="md" open={this.props.open} onClose={() => { this.setState({ selectedSettlement: null }); this.props.onClose() }}>
                <DialogTitle>{this.props.t(selectedSettlement ? "update_settlement" : "create_settlement")}</DialogTitle>
                <form onSubmit={this.createSettlement} autoComplete="off" spellCheck="false">
                    <DialogContent>
                        <Grid item container>
                            <Typography variant="body" gutterBottom>
                                {this.props.t("create_settlement_message")}
                            </Typography>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className="header-table-cell" align="right" component="th" scope="row" width="20%">{this.props.t("amount")}</TableCell>
                                            <TableCell className="header-table-cell" component="th" scope="row" width="15%">{this.props.t("currency")}</TableCell>
                                            <TableCell className="header-table-cell" align="right" component="th" scope="row" width="20%">{this.props.t("crypto_amount")}</TableCell>
                                            <TableCell className="header-table-cell" component="th" scope="row" width="15%">{this.props.t("currency")}</TableCell>
                                            <TableCell className="header-table-cell" component="th" scope="row" width="20%">{this.props.t("created")}</TableCell>
                                            <TableCell className="header-table-cell" component="th" scope="row" width="20%">{this.props.t("actions")}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {this.props.settlementOrders.length > 0 && <TableBody>
                                        {this.props.settlementOrders.sort((a, b) => moment(b.created).unix() - moment(a.created).unix()).map(so => {
                                            return <TableRow key={so.orderId}>
                                                <TableCell className="table-cell" align="right"><NumberFormat value={so.fiatPrice} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} /></TableCell>
                                                <TableCell className="table-cell">{so.currency}</TableCell>
                                                <TableCell className="table-cell" align="right"><NumberFormat value={so.cryptoAmountOrdersDisplay} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={8} fixedDecimalScale={true} /></TableCell>
                                                <TableCell className="table-cell">{so.cryptoAsset}</TableCell>
                                                <TableCell className="table-cell">{moment.tz(so.created, so.timeZone).format('DD.MM.YYYY. HH:mm:ss')}</TableCell>
                                                <TableCell>
                                                    {this.props.settlementOrders.length > 1 && <Fab className="table-fab" title={this.props.t("delete")} color="inherit" aria-label="delete" onClick={() => this.setState({ orderToRemove: so, openDeleteDialog: true })}>
                                                        <DeleteIcon className="table-fab-icon" />
                                                    </Fab>}
                                                </TableCell>
                                            </TableRow >
                                        })}
                                        <TableRow className="total">
                                            <TableCell>
                                                {this.props.t("total")}
                                                <NumberFormat style={{ float: 'right' }} value={this.getTotal()} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} />
                                            </TableCell>
                                            <TableCell align="left">{this.props.settlementOrders[0].currency}</TableCell>
                                            <TableCell align="right">
                                                <NumberFormat value={this.getCryptoTotal()} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={8} fixedDecimalScale={true} />
                                            </TableCell>
                                            <TableCell align="left">{this.props.settlementOrders[0].cryptoAsset}</TableCell>
                                            <TableCell align="center"></TableCell>
                                            <TableCell align="center"></TableCell>
                                        </TableRow>
                                    </TableBody>}
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item container>
                            <Typography variant="body" style={{ marginTop: '20px' }}>
                                {this.props.t("create_settlement_message_2")}
                            </Typography>
                        </Grid>
                        <Grid item container>
                            <Autocomplete
                                style={{ width: 300 }}
                                value={selectedSettlement}
                                id="settlement"
                                name="settlement"
                                options={this.props.settlementReducer.newSettlementOrders}
                                onChange={(e, settlement) => this.setState({ selectedSettlement: settlement })}
                                autoHighlight
                                getOptionLabel={option => option.settlementReferenceNo ? option.settlementReferenceNo : ""}
                                renderOption={(props, option) => (
                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                        {option.settlementReferenceNo ? option.settlementReferenceNo : ""}
                                    </Box>
                                )}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        //label={this.props.t("select_settlement")}
                                        placeholder={this.props.t("select_settlement")}
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'off', // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        {!selectedSettlement && <Typography variant="body2" style={{ marginTop: '20px', color: 'red' }}>
                            {this.props.t("create_settlement_message_3")}
                        </Typography>}
                    </DialogContent>
                    <DialogActions>
                        <Button color="inherit" onClick={() => { this.setState({ selectedSettlement: null }); this.props.onClose() }} variant="contained">
                            {this.props.t("close")}
                        </Button>
                        <Button type="submit" variant="contained" color="primary">
                            {this.props.t(selectedSettlement ? "update" : "create")}
                        </Button>
                    </DialogActions>
                </form>
                <ConfirmationDialog
                    isOpen={this.state.openDeleteDialog}
                    title={this.props.t("delete_order")}
                    message={this.props.t("delete_order_message")}
                    onClose={() => this.setState({ openDeleteDialog: false })}
                    onConfirm={() => { this.props.removeOrder(this.state.orderToRemove); this.setState({ openDeleteDialog: false }) }}
                    confirmButtonTitle={this.props.t("delete")}
                    closeButtonTitle={this.props.t("close")}
                />
            </Dialog >
        );
    }
}

const mapStateToProps = (state) => ({
    settlementReducer: state.settlementReducer
});

const mapActionsToProps = { createSettlementOrder, getSettlementOrders, updateSettlementOrder, getNewSettlementOrders };

export default connect(mapStateToProps, mapActionsToProps)(withTranslation()(CreateSettlementDialog));
