import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { changePassword, changeNotificationPassword, setSignatureSecret, get2FA, enable2FA, disable2FA, resetPaymentNonce } from '../../redux/merchant/actions';
import {
  Grid,
  Button,
  Fab,
  Typography,
  Divider,
  InputAdornment,
  IconButton,
  TextField
} from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import ConfirmationDialog from "../../components/common/ConfirmationDialog";
import { hasRole } from '../../security/Security';

class Settings extends React.Component {

  emptyPassword = {
    password: '',
    repeatedPassword: ''
  }

  emptyNotificationPassword = {
    notificationPassword: ''
  }

  emptySignatueSecret = {
    signatureSecret: ''
  }

  empty2FA = {
    code: ''
  }

  emptyValidation = {
    passwordError: false,
    passwordErrorText: '',
    repeatedPasswordError: false,
    repeatedPasswordErrorText: '',
    notificationPasswordError: false,
    notificationPasswordErrorText: '',
    signatureSecretError: false,
    signatureSecretErrorText: ''
  };

  constructor(props) {
    super(props);
    this.state = {
      passwordData: JSON.parse(JSON.stringify(this.emptyPassword)),
      showPassword: false,
      showRepeatedPassword: false,
      notificationPasswordData: JSON.parse(JSON.stringify(this.emptyNotificationPassword)),
      showNotificationPassword: false,
      signatureSecretData: JSON.parse(JSON.stringify(this.emptySignatueSecret)),
      showSignatueSecret: false,
      twoFAData: JSON.parse(JSON.stringify(this.empty2FA)),
      openDisable2FADialog: false,
      validation: JSON.parse(JSON.stringify(this.emptyValidation))
    };
  }

  componentDidMount() {
    this.props.get2FA();
  }

  validate(field, value) {
    let { validation } = this.state;
    let regex = /^.{6,255}$/;
    switch (field) {
      case "password":
        if (value.length > 0 && !regex.test(value)) {
          validation.passwordError = true;
          validation.passwordErrorText = this.props.t("validation_message", { field: this.props.t("password") })
        } else {
          validation.passwordError = false;
          validation.passwordErrorText = "";
        }
        this.setState({ validation: validation });
        break;
      case "repeatedPassword":
        if (value.length > 0 && !regex.test(value)) {
          validation.repeatedPasswordError = true;
          validation.repeatedPasswordErrorText = this.props.t("validation_message", { field: this.props.t("password") })
        } else {
          validation.repeatedPasswordError = false;
          validation.repeatedPasswordErrorText = "";
        }
        this.setState({ validation: validation });
        break;
      case "notificationPassword":
        if (value.length > 0 && !regex.test(value)) {
          validation.notificationPasswordError = true;
          validation.notificationPasswordErrorText = this.props.t("validation_message", { field: this.props.t("password") })
        } else {
          validation.notificationPasswordError = false;
          validation.notificationPasswordErrorText = "";
        }
        this.setState({ validation: validation });
        break;
      case "signatureSecret":
        regex = /^.{1,255}$/;
        if (value.length > 0 && !regex.test(value)) {
          validation.signatureSecretError = true;
          validation.signatureSecretErrorText = this.props.t("ss_validation_message", { field: this.props.t("secret") })
        } else {
          validation.signatureSecretError = false;
          validation.signatureSecretErrorText = "";
        }
        this.setState({ validation: validation });
        break;
      default:
        return;
    }
  }

  handleChangePassword = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    let { passwordData } = this.state;
    passwordData[name] = value.trim();
    this.validate(name, value);
    this.setState({ passwordData: passwordData });
  }

  changePassword = () => {
    const { passwordData } = this.state;
    this.props.changePassword(passwordData).then((code) => {
      if (code !== 400) {
        this.setState({ passwordData: JSON.parse(JSON.stringify(this.emptyPassword)), validation: JSON.parse(JSON.stringify(this.emptyValidation)) });
      }

    });
  }

  handleChangeNotificationPassword = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    let { notificationPasswordData } = this.state;
    notificationPasswordData[name] = value.trim();
    this.validate(name, value);
    this.setState({ notificationPasswordData: notificationPasswordData });
  }

  changeNotificationPassword = () => {
    const { notificationPasswordData } = this.state;
    this.props.changeNotificationPassword(notificationPasswordData).then(() => {
      this.setState({ notificationPasswordData: JSON.parse(JSON.stringify(this.emptyNotificationPassword)), validation: JSON.parse(JSON.stringify(this.emptyValidation)) });
    });
  }

  handleChangeSignatureSecret = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    let { signatureSecretData } = this.state;
    signatureSecretData[name] = value.trim();
    this.validate(name, value);
    this.setState({ signatureSecretData: signatureSecretData });
  }

  setSignatureSecret = () => {
    const { signatureSecretData } = this.state;
    this.props.setSignatureSecret(signatureSecretData).then(() => {
      this.setState({ signatureSecretData: JSON.parse(JSON.stringify(this.emptySignatueSecret)), validation: JSON.parse(JSON.stringify(this.emptyValidation)) });
    });
  }

  handleChangeCode = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    let { twoFAData } = this.state;
    twoFAData[name] = value.trim();
    //this.validate(name, value);
    this.setState({ twoFAData: twoFAData });
  }

  enable2FA = () => {
    const { twoFAData } = this.state;
    this.props.enable2FA(twoFAData).then(() => {
      this.setState({ twoFAData: JSON.parse(JSON.stringify(this.empty2FA)), validation: JSON.parse(JSON.stringify(this.emptyValidation)) });
      this.props.get2FA();
    });
  }

  disable2FA = () => {
    this.props.disable2FA().then(() => {
      this.setState({ openDisable2FADialog: false });
      this.props.get2FA();
    });
  }

  render() {

    const { passwordData, showPassword, showRepeatedPassword, notificationPasswordData, showNotificationPassword, signatureSecretData, showSignatueSecret, twoFAData, validation } = this.state;

    return (
      <Grid container direction="row" spacing={2}>
        <Grid item container>
          <Typography variant="h6" style={{ alignItems: 'center', display: 'flex' }}>
            <Fab
              className="table-fab"
              title={this.props.t("back")}
              size="small"
              color="primary"
              onClick={() => this.props.history.goBack()}>
              <ArrowBack />
            </Fab> <span style={{ marginLeft: 10 }}>{this.props.t("settings")}</span>
          </Typography>
        </Grid>
        <Grid item container direction="column" xs={12} sm={12} md={6} lg={4} xl={3}>
          <Typography variant="h6" component="div">
            <strong>{this.props.t("change_password")}</strong>
          </Typography>
          <Divider />
          <Grid item>
            <TextField
              type={showPassword ? 'text' : 'password'}
              id="password"
              name="password"
              label={this.props.t("password")}
              value={passwordData.password}
              onChange={this.handleChangePassword}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => this.setState({ showPassword: !this.state.showPassword })}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
                autoComplete: 'off',
                spellCheck: false
              }}
              required
              helperText={validation.passwordErrorText}
              error={validation.passwordError}
            />
          </Grid>
          <Grid item>
            <TextField
              type={showRepeatedPassword ? 'text' : 'password'}
              id="repeatedPassword"
              name="repeatedPassword"
              label={this.props.t("repeat_password")}
              value={passwordData.repeatedPassword}
              onChange={this.handleChangePassword}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => this.setState({ showRepeatedPassword: !this.state.showRepeatedPassword })}
                      edge="end"
                    >
                      {showRepeatedPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
                autoComplete: 'off',
                spellCheck: false
              }}
              required
              helperText={validation.repeatedPasswordErrorText}
              error={validation.repeatedPasswordError}
            />
            <Button disabled={
              !passwordData.password ||
              !passwordData.repeatedPassword ||
              validation.passwordError ||
              validation.repeatedPasswordError ||
              (passwordData.password !== passwordData.repeatedPassword)
            } style={{ width: '100px', float: 'right', marginTop: 10, marginBottom: 10 }} type="button" variant="contained" color="primary" onClick={this.changePassword}>
              {this.props.t("change")}
            </Button>
          </Grid>
          {hasRole(this.props.user, ["MERCHANT"]) && <>
            <Typography variant="h6" component="div" style={{ marginTop: hasRole(this.props.user, ["MERCHANT", "POS"]) ? 50 : "unset" }}>
              <strong>{this.props.t("notification_password")}</strong>
            </Typography>
            <Divider />
            <Grid item>
              <TextField
                type={showNotificationPassword ? 'text' : 'password'}
                id="notificationPassword"
                name="notificationPassword"
                label={this.props.t("password")}
                value={notificationPasswordData.notificationPassword}
                onChange={this.handleChangeNotificationPassword}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => this.setState({ showNotificationPassword: !this.state.showNotificationPassword })}
                        edge="end"
                      >
                        {showNotificationPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  autoComplete: 'off',
                  spellCheck: false
                }}
                required
                helperText={validation.notificationPasswordErrorText}
                error={validation.notificationPasswordError}
              />
              <Button disabled={!notificationPasswordData.notificationPassword || validation.notificationPasswordError} style={{ width: '100px', float: 'right', marginTop: 10, marginBottom: 10 }} type="button" variant="contained" color="primary" onClick={this.changeNotificationPassword}>
                {this.props.t("change")}
              </Button>
            </Grid>
            <Typography variant="h6" component="div" style={{ marginTop: 50 }}>
              <strong>{this.props.t("signature_secret")}</strong>
            </Typography>
            <Divider />
            <Grid item>
              <TextField
                type={showSignatueSecret ? 'text' : 'password'}
                id="signatureSecret"
                name="signatureSecret"
                label={this.props.t("secret")}
                value={signatureSecretData.signatureSecret}
                onChange={this.handleChangeSignatureSecret}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => this.setState({ showSignatueSecret: !this.state.showSignatueSecret })}
                        edge="end"
                      >
                        {showSignatueSecret ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  autoComplete: 'off',
                  spellCheck: false
                }}
                required
                helperText={validation.signatureSecretErrorText}
                error={validation.signatureSecretError}
              />
              <Button disabled={!signatureSecretData.signatureSecret || validation.signatureSecretError} style={{ width: '100px', float: 'right', marginTop: 10, marginBottom: 10 }} type="button" variant="contained" color="primary" onClick={this.setSignatureSecret}>
                {this.props.t("set")}
              </Button>
            </Grid>
            <Grid item>
              <Typography variant="h6" component="div" style={{ marginTop: 50 }}>
                <strong>{this.props.t("reset_payment_nonce")}</strong>
              </Typography>
              <Divider />
              <Button style={{ width: '100px', float: 'right', marginTop: 10, marginBottom: 10 }} type="button" variant="contained" color="primary" onClick={() => this.props.resetPaymentNonce()}>
                {this.props.t("reset")}
              </Button>
            </Grid>
          </>}
          <Typography variant="h6" component="div" style={{ marginTop: 50 }}>
            <strong>{this.props.t("2FA")}</strong>
          </Typography>
          <Divider />
          {!this.props.merchantReducer.twoFADataLoading && <>
            {this.props.merchantReducer.twoFAData && !this.props.merchantReducer.twoFAData.twoFA ? <Grid item style={{ textAlign: 'center' }}>
              <img alt="qrCode" width="200px" height="200px" src={"data:image/png;base64," + this.props.merchantReducer.twoFAData.qrCode} />
              <TextField
                label={this.props.t("otp_code")}
                id="code"
                name="code"
                onChange={this.handleChangeCode}
                value={twoFAData.code || ''}
                required
                InputProps={{
                  autoComplete: 'off',
                  spellCheck: false
                }}
              />
              <Button disabled={!twoFAData.code} style={{ width: '100px', float: 'right', marginTop: 10, marginBottom: 10 }} type="button" variant="contained" color="primary" onClick={this.enable2FA}>
                {this.props.t("enable")}
              </Button>
            </Grid> : <>
              <Button style={{ float: 'right', marginTop: 10, marginBottom: 10 }} type="button" variant="contained" color="inherit" onClick={() => this.setState({ openDisable2FADialog: true })}>
                {this.props.t("disable")}
              </Button>
              <ConfirmationDialog
                isOpen={this.state.openDisable2FADialog}
                title={this.props.t("disable_2FA_dialog_title")}
                message={this.props.t("disable_2FA_dialog_message")}
                onClose={() => this.setState({ openDisable2FADialog: false })}
                onConfirm={this.disable2FA}
                closeButtonTitle={this.props.t("no")}
                confirmButtonTitle={this.props.t("yes")}
              />
            </>
            }
          </>}
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  adminReducer: state.adminReducer,
  merchantReducer: state.merchantReducer,
  user: state.merchantReducer.user
})

const mapActionsToProps = { changePassword, changeNotificationPassword, setSignatureSecret, get2FA, enable2FA, disable2FA, resetPaymentNonce }

export default connect(mapStateToProps, mapActionsToProps)(withTranslation()(Settings))
