import types from '../payment/types';
import api from '../../api/paymentApi';
import merchantApi from '../../api/merchantApi';
import { snackActions } from '../snackbar/reducer';
import { EventSourcePolyfill } from 'event-source-polyfill';
import store from '../store';
import i18next from 'i18next';
import { hasRole } from '../../security/Security';

export const init = (data, user, merchantId, currency) => async (dispatch) => {
  dispatch({
    type: types.SET_INIT_LOADING
  });

  let action = null;
  if (user) {
    if (hasRole(user, ["ADMIN"])) {
      action = api.adminInit(data);
    } else {
      action = api.init(data);
    }
  } else {
    action = api.publicInit(data);
  }

  return await action.then(response => {
    response.data.cryptoAsset = data.cryptoAsset;
    response.data.fiatPrice = data.fiatPrice;
    response.data.referenceNo = data.referenceNo;
    response.data.merchantId = merchantId;
    response.data.currency = currency;
    dispatch({
      type: types.SET_INIT_DATA,
      payload: response.data
    });
    dispatch({
      type: types.SET_ERROR_MESSAGE_KYC,
      payload: ''
    });
    return response;
  }).catch(error => {
    if (error.response.data.code === 2001) {
      dispatch(snackActions.error(error.response.data.message));
    } else if (error.response.data.code === 10000) {
      dispatch({
        type: types.SET_ERROR_MESSAGE_KYC,
        payload: error.response.data.message
      });
    } else if (error.response.data.code === 3103 || error.response.data.code === 400) {
      /* dispatch({
        type: types.SET_INCORRECT_PAYMENT_DATA
      }); */
      dispatch(snackActions.error(
        hasRole(user, ["ADMIN"]) ? error.response.data && error.response.data.message : i18next.t("contant_support")
      ));
    } else {
      dispatch(snackActions.error(i18next.t("error_message")));
    }
    dispatch({
      type: types.SET_INIT_DATA,
      payload: null
    });
  });
}

export const resetInitData = () => (dispatch) => {
  dispatch({
    type: types.SET_INIT_DATA,
    payload: null
  });
  return Promise.resolve();
};

export const clearKYCErrorMessage = () => (dispatch) => {
  dispatch({
    type: types.SET_ERROR_MESSAGE_KYC,
    payload: ''
  });
  return Promise.resolve();
};

export const establishSseConnection = (url = `${process.env.REACT_APP_BASE_URL}/api/v1/sse`) => async (dispatch) => {
  const headers = {
    'Content-Type': 'text/event-stream',
    'Connection': 'keep-alive',
    'Cache-Control': 'no-cache',
    'X-Accel-Buffering': 'no'
  };
  let esp = new EventSourcePolyfill(url, {
    headers: headers,
    withCredentials: true,
    heartbeatTimeout: 24 * 60 * 60 * 1000 //24h
  });
  esp.addEventListener("error", (error) => {
    if (error.status === 401) {
      merchantApi.logout();
    }
  });
  esp.onmessage = (e) => {
    //console.log("onmessage", JSON.parse(e.data));
  }
  esp.addEventListener('heart-beat', message => {
    //console.log("heartbeat", message);
  });
  esp.addEventListener('order-status-notification', e => {
    const orderStatusNotification = JSON.parse(e.data);
    let posList = store.getState().paymentReducer.posList;
    let posIndex = posList.findIndex(p => p.orderId === orderStatusNotification.orderId);
    if (posIndex > -1) {
      posList[posIndex] = orderStatusNotification;
    } else {
      posList.push(orderStatusNotification);
    }
    dispatch({
      type: types.SET_POS_LIST,
      payload: posList
    });
  });
  esp.onerror = () => {
    dispatch({
      type: types.SET_INCORRECT_PAYMENT_DATA
    });
    esp.close();
  }
}

export const getPublicPaymentData = (orderId, config = {}) => async (dispatch) => {
  return await api.getPublicPaymentData(orderId, config).then(response => {
    dispatch({
      type: types.SET_INIT_DATA,
      payload: response.data
    });
  }).catch(error => {
    dispatch({
      type: types.SET_INCORRECT_PAYMENT_DATA
    });
    //dispatch(snackActions.error(i18next.t("error_message")));
  });
}

export const getMerchantPublicPaymentData = (merchantPaymentCode, config = {}) => async (dispatch) => {
  return await api.getMerchantPublicPaymentData(merchantPaymentCode, config).then(response => {
    dispatch({
      type: types.SET_MERCHANT_PUBLIC_PAYMENT_DATA,
      payload: response.data
    });
  }).catch(error => {
    dispatch({
      type: types.SET_INCORRECT_PAYMENT_DATA
    });
    //dispatch(snackActions.error(i18next.t("error_message")));
  });
}

export const setIncorrectPaymentData = () => async (dispatch) => {
  dispatch({
    type: types.SET_INCORRECT_PAYMENT_DATA,
    payload: true
  });
}