import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import ClearIcon from '@mui/icons-material/Clear';
import { TableCell } from '@mui/material';

class FilterCriteria extends Component {

    render() {
        return (
            <TableCell colSpan={this.props.colSpan && this.props.colSpan}>
                {this.props.currentFilter && <div className="base58-table-filter-criteria-wrapper">
                    {Object.values(this.props.currentFilter).some(value => value != null) && <div className="base58-table-filter-criteria-text">{this.props.t("results_for")} (</div>}
                    {Object.keys(this.props.currentFilter).map(key => {
                        if (this.props.currentFilter[key]) {
                            let value = null;
                            if (key === "sort") {
                                value = (this.props.t(this.props.columns.find(c => c.dbName === this.props.currentFilter[key].split(",")[0]).name)) + " " + (this.props.currentFilter[key].split(",")[1] === "asc" ? '&uarr;' : '&darr;');
                            } else {
                                value = this.props.currentFilter[key];
                            }
                            let text = this.props.t(key);
                            return <div key={key} className="base58-table-filter-criteria-text" dangerouslySetInnerHTML={{ __html: "<strong>" + text + ":</strong> <i> '" + value + "' </i>&nbsp " }} />
                        }
                        return null
                    })}
                    {Object.values(this.props.currentFilter).some(value => value != null) &&
                        <div className="base58-table-filter-criteria-text">) <ClearIcon className="base58-table-filter-criteria-clear-icon" onClick={() => this.props.onFilterClear()} /></div>}
                </div>}
            </TableCell>
        );
    }
}

export default (withTranslation()(FilterCriteria));