import axios from '../axiosConfig';

export function createExchangeOrderSetting(data) {
    return axios.post(`/api/v1/admin/exchange-service/order-settings`, data);
}

export function getExchangeOrderSettings(config = {}) {
    return axios.get(`/api/v1/admin/exchange-service/order-settings/filter`, config);
}

export function getExchangeOrderSetting(id, config = {}) {
    return axios.get(`/api/v1/admin/exchange-service/order-settings/${id}`, config);
}

export function updateExchangeOrderSetting(id, data) {
    return axios.put(`/api/v1/admin/exchange-service/order-settings/${id}`, data);
}

export function getExchanges(config = {}) {
    return axios.get(`/api/v1/admin/exchange-service/providers`, config)
}

export function getCurrencyPairs(exchange, config = {}) {
    return axios.get(`/api/v1/admin/exchange-service/providers/rest/${exchange}/info`, config)
}

export function activateExchangeOrderSetting(id) {
    return axios.patch(`/api/v1/admin/exchange-service/order-settings/activate/${id}`);
}

export function deactivateExchangeOrderSetting(id) {
    return axios.patch(`/api/v1/admin/exchange-service/order-settings/deactivate/${id}`);
}

export function getExchangeOrderSettingGroups(config = {}) {
    return axios.get(`/api/v1/admin/exchange-service/order-settings/groups`, config)
}

export function deleteExchangeOrderSetting(id, config = {}) {
    return axios.delete(`/api/v1/admin/exchange-service/order-settings/${id}`, config);
}

const actions = {
    createExchangeOrderSetting,
    getExchangeOrderSettings,
    getExchangeOrderSetting,
    updateExchangeOrderSetting,
    activateExchangeOrderSetting,
    deactivateExchangeOrderSetting,
    getExchanges,
    getCurrencyPairs,
    getExchangeOrderSettingGroups,
    deleteExchangeOrderSetting
}

export default actions;