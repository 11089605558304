import React from "react";
import { withTranslation } from 'react-i18next';
import moment from "moment";
import { connect } from 'react-redux';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Fab,
  Typography,
  Collapse,
  Box,
  TableHead,
  Button,
  Checkbox,
  TextField
} from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';
import Base58Table from "../../components/common/Base58Table";
import NumberFormat from 'react-number-format';
import { getSettlementOrders, updateSettlementOrdersPageSize, settleOrder, updateSettlementOrder } from '../../redux/settlement/actions';
import { getCurrentBalance } from '../../redux/merchant/actions';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import { hasRole } from '../../security/Security';
import ConfirmationDialog from "../../components/common/ConfirmationDialog";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { getSettlementReport, getAccountingReport } from '../../redux/reports/actions';

class Settlements extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      params: { sort: 'created,desc' },
      collapseIndex: -1,
      settlementOrders: [],
      settlementDialogOpen: false,
      bankReferenceNo: '',
      paymentOrderIdsToRemove: [],
      openDeleteDialog: false
    };
  }

  componentDidMount() {
    this.getSettlementOrders(0);
    hasRole(this.props.user, ["MERCHANT"]) && this.props.getCurrentBalance()
  }

  getSettlementOrders = (page, collapseIndex = -1) => {
    let { params } = this.state;
    params.page = page;
    params.size = this.props.settlementReducer.size;
    this.setState({ page: page, collapseIndex: collapseIndex });
    this.props.getSettlementOrders({ params: params }, hasRole(this.props.user, ["ADMIN"])).then(response => {
      this.setState({ settlementOrders: response.data.content, page: page, collapseIndex: collapseIndex });
    });
  }

  onPageChange = (event, page) => {
    this.setState({ page: page }, () => {
      this.getSettlementOrders(page);
    })
  }

  onRowsPerPageChange = (event) => {
    this.props.updateSettlementOrdersPageSize(event.target.value).then(() => {
      this.setState({ page: 0 });
      this.getSettlementOrders(0);
    })
  }

  status(status) {
    switch (status) {
      case 'NEW':
        return <span style={{ color: 'blue', fontWeight: 'bold' }}>{this.props.t(status)}</span>;
      case 'SETTLEMENT_COMPLETED':
        return <span style={{ color: 'green', fontWeight: 'bold' }}>{this.props.t(status)}</span>;
      default:
        return <span style={{ color: 'red', fontWeight: 'bold' }}>{this.props.t(status)}</span>;
    }
  }

  getTotal = (settlementOrders) => {
    let total = 0;
    settlementOrders.forEach(so => {
      total += Number(so.fiatPrice);
    });
    return total;
  }

  getCryptoTotal = (settlementOrders) => {
    let total = 0;
    settlementOrders.forEach(so => {
      total += Number(so.quota);
    });
    return total;
  }

  handleChangeBankReferenceNo = (e, index) => {
    let { settlementOrders } = this.state;
    settlementOrders[index].tempBankReferenceNo = e.target.value;
    this.setState({ settlementOrders: settlementOrders });
  }

  settleOrder = (index) => {
    let { settlementOrders } = this.state;
    let data = {};
    data.bankReferenceNo = settlementOrders[index].tempBankReferenceNo;
    data.settlementOrderId = settlementOrders[index].settlementOrderId;
    this.props.settleOrder(data).then(() => {
      this.getSettlementOrders(0);
    });
  }

  handleChange = (order) => {
    let { paymentOrderIdsToRemove } = this.state;
    let orderIndex = paymentOrderIdsToRemove.findIndex(id => id === order.orderId);
    if (orderIndex > -1) {
      paymentOrderIdsToRemove.splice(orderIndex, 1);
    } else {
      paymentOrderIdsToRemove.push(order.orderId);
    }
    this.setState({ paymentOrderIdsToRemove: paymentOrderIdsToRemove });
  }

  removeOrders = () => {
    let { settlementOrderId, paymentOrderIdsToRemove, page, collapseIndex } = this.state;
    let data = {};
    data.paymentOrderIdsToRemove = paymentOrderIdsToRemove;
    this.props.updateSettlementOrder(settlementOrderId, data).then(() => {
      this.setState({ openDeleteDialog: false }, () => this.getSettlementOrders(page, collapseIndex))
    });
  }

  getSettlementReport = (settlementOrderId) => {
    this.props.getSettlementReport(settlementOrderId, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/pdf',
        //'Access-Control-Expose-Headers': 'Content-Disposition',
        "Accept-Language": localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') : "en"
      },
      responseType: 'blob'
    }).then(response => {
      this.handleContentDisposition(response);
    });
  }

  getAccountingReport = (settlementOrderId) => {
    this.props.getAccountingReport({
      params: { settlementOrderId: settlementOrderId },
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/pdf',
        //'Access-Control-Expose-Headers': 'Content-Disposition',
        "Accept-Language": localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') : "en"
      },
      responseType: 'blob'
    }).then(response => {
      this.handleContentDisposition(response);
    });
  }

  handleContentDisposition(response) {
    const blob = new Blob([response.data]);
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    const contentDisposition = response.headers["content-disposition"];
    let fileName = contentDisposition.split("filename=")[1];
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  }

  render() {
    const { settlementOrders, page, paymentOrderIdsToRemove } = this.state;
    let { totalElements, size, isLoading } = this.props.settlementReducer;
    let { currentBalances } = this.props.merchantReducer;

    let data;
    if (settlementOrders && settlementOrders.length > 0) {
      data = settlementOrders.map((so, index) => {
        return <React.Fragment key={so.settlementOrderId}>
          <TableRow>
            <TableCell style={{ fontWeight: so.paymentOrders && so.paymentOrders.length > 0 && index === this.state.collapseIndex ? 'bold' : 'unset' }} className="table-cell">{so.merchantUsername}</TableCell>
            <TableCell style={{ fontWeight: so.paymentOrders && so.paymentOrders.length > 0 && index === this.state.collapseIndex ? 'bold' : 'unset' }} className="table-cell">{so.settlementReferenceNo}</TableCell>
            {hasRole(this.props.user, ["ADMIN"]) && <TableCell style={{ fontWeight: index === this.state.collapseIndex ? 'bold' : 'unset' }} className="table-cell">
              {!so.bankReferenceNo ?
                <Grid container style={{ display: 'flex', alignItems: 'center' }}>
                  <Grid item xs={10}>
                    <TextField
                      placeholder={this.props.t("bank_reference_no_placeholder")}
                      id={"bankReferenceNo-" + index}
                      name={"bankReferenceNo-" + index}
                      onChange={(e) => this.handleChangeBankReferenceNo(e, index)}
                      value={so.tempBankReferenceNo || ''}
                      inputProps={{
                        autoComplete: 'off',
                        spellCheck: false
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    {so.tempBankReferenceNo ? <Fab color="primary" style={{ marginLeft: '10px' }} className="table-fab" title={this.props.t("save")} aria-label="save" onClick={() => this.settleOrder(index)}>
                      <CheckCircleIcon className="table-fab-icon" />
                    </Fab> :
                      <Fab color="info" style={{ marginLeft: '10px' }} className="table-fab" title={this.props.t("settlement_info_message")}>
                        <InfoIcon color="info" />
                      </Fab>}
                  </Grid>
                </Grid>
                :
                so.bankReferenceNo}
            </TableCell>}
            {hasRole(this.props.user, ["MERCHANT"]) && <TableCell style={{ fontWeight: so.paymentOrders && so.paymentOrders.length > 0 && index === this.state.collapseIndex ? 'bold' : 'unset' }} className="table-cell">
              {so.bankReferenceNo}
            </TableCell>}
            <TableCell style={{ fontWeight: so.paymentOrders && so.paymentOrders.length > 0 && index === this.state.collapseIndex ? 'bold' : 'unset' }} className="table-cell">{this.status(so.status)}</TableCell>
            <TableCell className="table-cell">
              {so.paymentOrders && so.paymentOrders.length > 0 && <Button
                id={"order-history-list-" + index}
                onClick={() => this.setState({ collapseIndex: this.state.collapseIndex !== index ? index : -1, settlementOrderId: so.settlementOrderId, paymentOrderIdsToRemove: [] })}>
                {index === this.state.collapseIndex ? this.props.t("collapse") : this.props.t(so.status === "NEW" && hasRole(this.props.user, ["ADMIN"]) ? "expand_and_edit" : "expand")}
              </Button>}
            </TableCell>
            <TableCell style={{ fontWeight: so.paymentOrders && so.paymentOrders.length > 0 && index === this.state.collapseIndex ? 'bold' : 'unset' }} className="table-cell">{moment.tz(so.created, so.timeZone).format('DD.MM.YYYY. HH:mm:ss')}</TableCell>
            <TableCell style={{ fontWeight: so.paymentOrders && so.paymentOrders.length > 0 && index === this.state.collapseIndex ? 'bold' : 'unset' }} className="table-cell">{moment.tz(so.updated, so.timeZone).format('DD.MM.YYYY. HH:mm:ss')}</TableCell>
            <TableCell style={{ fontWeight: so.paymentOrders && so.paymentOrders.length > 0 && index === this.state.collapseIndex ? 'bold' : 'unset' }} className="table-cell">
              {so.paymentOrders && so.paymentOrders.length > 0 && <NumberFormat style={{ float: 'right' }} value={this.getTotal(so.paymentOrders)} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} suffix={" " + so.paymentOrders[0].currency} />}
            </TableCell>
            <TableCell className="table-cell">
              {so.paymentOrders && so.paymentOrders.length > 0 && <Fab style={{ marginRight: "10px" }} title={this.props.t("settlement_export")} className="table-fab" color="primary" aria-label="pos" onClick={() => this.getSettlementReport(so.settlementOrderId)}>
                <PictureAsPdfIcon className="table-fab-icon" />
              </Fab>}
              {so.paymentOrders && so.paymentOrders.length > 0 && hasRole(this.props.user, ["ADMIN"]) && <Fab title={this.props.t("accounting_export")} className="table-fab" color="inherit" aria-label="pos" onClick={() => this.getAccountingReport(so.settlementOrderId)}>
                <PictureAsPdfIcon className="table-fab-icon" />
              </Fab>}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0, backgroundColor: '#E4E9F8' }} colSpan={1} />
            <TableCell style={{ paddingBottom: 0, paddingTop: 0, backgroundColor: '#E4E9F8' }} colSpan={6}>
              <Collapse in={so.paymentOrders && so.paymentOrders.length > 0 && index === this.state.collapseIndex} timeout="auto" unmountOnExit style={{ paddingTop: 10, paddingBottom: 20 }}>
                <Box sx={{ margin: 1 }}>
                  <Grid item container>
                    <Typography variant="h6" gutterBottom>
                      {this.props.t("orders")}
                    </Typography>
                    {paymentOrderIdsToRemove.length > 0 && <Button style={{ marginLeft: 'auto', marginBottom: '10px' }} onClick={() => this.setState({ openDeleteDialog: true })} color="inherit">
                      {this.props.t("delete")}
                    </Button>}
                  </Grid>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell className="header-table-cell" align="left" component="th" scope="row" width="25%">{this.props.t("reference_no")}</TableCell>
                          <TableCell className="header-table-cell" align="right" component="th" scope="row" width="15%">{this.props.t("amount")}</TableCell>
                          <TableCell className="header-table-cell" component="th" scope="row" width="5%">{this.props.t("currency")}</TableCell>
                          {hasRole(this.props.user, ["ADMIN"]) && <TableCell className="header-table-cell" align="right" component="th" scope="row" width="15%">{this.props.t("crypto_amount")}</TableCell>}
                          {hasRole(this.props.user, ["ADMIN"]) && <TableCell className="header-table-cell" component="th" scope="row" width="10%">{this.props.t("currency")}</TableCell>}
                          <TableCell className="header-table-cell" component="th" scope="row" width="20%">{this.props.t("created")}</TableCell>
                          {so.status === "NEW" && hasRole(this.props.user, ["ADMIN"]) && <TableCell className="header-table-cell" component="th" scope="row" width="10%">{this.props.t("delete")}</TableCell>}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {so.paymentOrders && so.paymentOrders.length > 0 && so.paymentOrders.map(item => {
                          return <TableRow key={item.orderId}>
                            <TableCell className="table-cell" align="left">{item.referenceNo}</TableCell>
                            <TableCell className="table-cell" align="right"><NumberFormat value={item.fiatPrice} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} /></TableCell>
                            <TableCell className="table-cell">{item.currency}</TableCell>
                            {hasRole(this.props.user, ["ADMIN"]) && <TableCell className="table-cell" align="right"><NumberFormat value={item.quota} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={8} fixedDecimalScale={true} /></TableCell>}
                            {hasRole(this.props.user, ["ADMIN"]) && <TableCell className="table-cell">{item.cryptoAsset}</TableCell>}
                            <TableCell className="table-cell">{moment.tz(item.created, item.timeZone).format('DD.MM.YYYY. HH:mm:ss')}</TableCell>
                            {so.status === "NEW" && hasRole(this.props.user, ["ADMIN"]) && <TableCell>
                              <Checkbox
                                color="primary"
                                name="so"
                                checked={paymentOrderIdsToRemove.some(id => id === item.orderId)}
                                onChange={() => this.handleChange(item)}
                              />
                            </TableCell>}
                          </TableRow>
                        })}
                        {so.paymentOrders && so.paymentOrders.length > 0 && <TableRow className="total-2">
                          <TableCell align="left">
                            {this.props.t("total")}
                          </TableCell>
                          <TableCell>
                            <NumberFormat style={{ float: 'right' }} value={this.getTotal(so.paymentOrders)} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} />
                          </TableCell>
                          <TableCell align="left">{so.paymentOrders[0].currency}</TableCell>
                          {hasRole(this.props.user, ["ADMIN"]) && <TableCell align="right">
                            <NumberFormat value={this.getCryptoTotal(so.paymentOrders)} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={8} fixedDecimalScale={true} />
                          </TableCell>}
                          {hasRole(this.props.user, ["ADMIN"]) && <TableCell align="left">{so.paymentOrders[0].cryptoAsset}</TableCell>}
                          <TableCell align="center"></TableCell>
                          {so.status === "NEW" && hasRole(this.props.user, ["ADMIN"]) && <TableCell align="center"></TableCell>}
                        </TableRow>}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Collapse>
            </TableCell>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0, backgroundColor: '#E4E9F8' }} colSpan={hasRole(this.props.user, ["ADMIN"]) ? 2 : 2} />
          </TableRow>
        </React.Fragment>
      });
    } else {
      data = <TableRow>
        <TableCell align="center" colSpan={hasRole(this.props.user, ["ADMIN"]) ? 8 : 7}>
          {this.props.t("records_not_found")}
        </TableCell>
      </TableRow>
    }

    let columns = [
      {
        name: hasRole(this.props.user, ["ADMIN"]) ? "pos_merchant" : "child_merchant",
        dbName: 'merchantUsername',
        width: '10%',
        sort: hasRole(this.props.user, ["ADMIN"]) ? true : false,
        filter: hasRole(this.props.user, ["ADMIN"]) ? true : false
      },
      {
        name: "settlement_reference_no",
        dbName: 'settlementReferenceNo',
        width: '12%',
        sort: hasRole(this.props.user, ["ADMIN", "MERCHANT"]) ? true : false,
        filter: hasRole(this.props.user, ["ADMIN", "MERCHANT"]) ? true : false,
      },
      {
        name: "bank_reference_no",
        dbName: 'bankReferenceNo',
        width: '12%',
        sort: hasRole(this.props.user, ["ADMIN", "MERCHANT"]) ? true : false,
        filter: hasRole(this.props.user, ["ADMIN", "MERCHANT"]) ? true : false
      },
      {
        name: "status",
        dbName: 'status',
        type: "select",
        items: ["NEW", "SETTLEMENT_COMPLETED"],
        width: '10%',
        sort: hasRole(this.props.user, ["ADMIN", "MERCHANT"]) ? true : false,
        filter: hasRole(this.props.user, ["ADMIN", "MERCHANT"]) ? true : false
      },
      {
        name: "orders",
        dbName: 'fee',
        width: '6%',
        sort: false,
        filter: false
      },
      {
        name: "created",
        dbName: 'created',
        width: '8%',
        sort: true
      },
      {
        name: "updated",
        dbName: 'updated',
        width: '8%',
        sort: false
      },
      {
        name: "total",
        dbName: 'total',
        type: "big-decimal",
        thousandSeparator: ".",
        decimalSeparator: ",",
        decimalScale: 2,
        width: '8%',
        sort: /* hasRole(this.props.user, ["ADMIN", "MERCHANT"]) ? true : false */ false,
        filter: /* hasRole(this.props.user, ["ADMIN", "MERCHANT"]) ? true : false */ false
      },
      {
        name: "reports",
        dbName: 'reports',
        width: '6%',
        sort: false
      }
    ];

    return (
      <Grid container direction="row" spacing={2}>
        <Grid item container>
          <Typography variant="h6" style={{ alignItems: 'center', display: 'flex' }}>
            <Fab
              className="table-fab"
              title={this.props.t("back")}
              size="small"
              color="primary"
              onClick={() => this.props.history.goBack()}>
              <ArrowBack />
            </Fab> <span style={{ marginLeft: 10 }}>{this.props.t("settlements")}</span>
          </Typography>
        </Grid>
        {hasRole(this.props.user, ["MERCHANT"]) && <Grid item container>
          <Typography variant="h6" style={{ alignItems: 'center', display: 'flex' }}>
            <span style={{ marginLeft: 10 }}>{this.props.t("balance_settlements") + ":"}</span>
            {currentBalances && currentBalances.length > 0 && currentBalances.map(balance => {
              return <NumberFormat key={balance.currency} style={{ marginLeft: 10 }}
                value={balance.currentBalance}
                displayType={'text'}
                thousandSeparator={'.'}
                decimalSeparator={','}
                decimalScale={2}
                fixedDecimalScale={true}
                suffix={" " + balance.currency} />
            })}
          </Typography>
        </Grid>}
        <Grid item container>
          <Base58Table
            isLoading={isLoading}
            columns={columns}
            data={data}
            count={totalElements}
            rowsPerPage={size}
            page={page}
            onPageChange={this.onPageChange}
            onRowsPerPageChange={this.onRowsPerPageChange}
            onFilterChange={(params) => this.setState({ params: params }, () => this.getSettlementOrders(0))}
          />
        </Grid>
        <ConfirmationDialog
          isOpen={this.state.openDeleteDialog}
          title={this.props.t("delete_order_2")}
          message={this.props.t("delete_order_message_2")}
          onClose={() => this.setState({ openDeleteDialog: false })}
          onConfirm={() => this.removeOrders()}
          confirmButtonTitle={this.props.t("delete")}
          closeButtonTitle={this.props.t("close")}
        />
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  settlementReducer: state.settlementReducer,
  user: state.merchantReducer.user,
  merchantReducer: state.merchantReducer
})

const mapActionsToProps = { getSettlementOrders, updateSettlementOrdersPageSize, settleOrder, updateSettlementOrder, getSettlementReport, getAccountingReport, getCurrentBalance }

export default connect(mapStateToProps, mapActionsToProps)(withTranslation()(Settlements))

