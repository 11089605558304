import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import snackbarReducer from '../redux/snackbar/reducer';
import adminReducer from './admin/reducer';
import merchantReducer from './merchant/reducer';
import paymentReducer from './payment/reducer';
import exchangeSettingsReducer from './exchangeSettings/reducer';
import settlementReducer from './settlement/reducer';
import Cookies from 'js-cookie';
import { createCookieMiddleware } from 'redux-cookie';

const initialState = {};

const middleware = [thunk, createCookieMiddleware(Cookies)];

const reducers = combineReducers({
    snackbarReducer: snackbarReducer,
    adminReducer: adminReducer,
    merchantReducer: merchantReducer,
    paymentReducer: paymentReducer,
    exchangeSettingsReducer: exchangeSettingsReducer,
    settlementReducer: settlementReducer
});

const store = createStore(
    reducers,
    initialState,
    compose(
        applyMiddleware(...middleware),
        //window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    )
);

export default store;
