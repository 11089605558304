import React, { Component } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

class ConfirmationDialog extends Component {

    render() {
        return (
            <Dialog onClose={() => this.props.onClose()} aria-labelledby="simple-dialog-title" open={this.props.isOpen}>
                <DialogTitle >{this.props.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {this.props.message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="inherit" onClick={() => this.props.onClose()} variant="contained">
                        {this.props.closeButtonTitle}
                    </Button>
                    <Button type="submit" variant="contained" color="primary" onClick={() => this.props.onConfirm()}>
                        {this.props.confirmButtonTitle}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default ConfirmationDialog;
