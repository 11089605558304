import React from "react";
import moment from "moment";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getSystemConfigurationParameters, getSystemConfigurationParameterByKey, setSystemConfigurationParameter, getAssets, activateAsset } from '../../redux/admin/actions';
import {
  Grid,
  Button,
  Fab,
  Typography,
  TextField,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody
} from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

class SystemSettings extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      initialSystemConfigurationParameters: [],
      systemConfigurationParameters: [],
      editParamIndex: -1
    };
  }

  componentDidMount() {
    this.getSystemConfigurationParameters();
    this.props.getAssets();
  }

  getSystemConfigurationParameters() {
    this.props.getSystemConfigurationParameters().then(response => {
      this.setState({ initialSystemConfigurationParameters: JSON.parse(JSON.stringify(response.data)), systemConfigurationParameters: response.data, editParamIndex: -1 });
    });
  }

  handleChangeKey = (e, index) => {
    let { systemConfigurationParameters } = this.state;
    systemConfigurationParameters[index].key = e.target.value;
    this.setState({ systemConfigurationParameters: systemConfigurationParameters });
  }

  handleChangeValue = (e, index) => {
    let { systemConfigurationParameters } = this.state;
    systemConfigurationParameters[index].value = e.target.value;
    this.setState({ systemConfigurationParameters: systemConfigurationParameters });
  }

  addParameter = () => {
    let { systemConfigurationParameters } = this.state;
    let newParam = {
      key: '',
      value: ''
    }
    systemConfigurationParameters.push(newParam);
    this.setState({ systemConfigurationParameters: systemConfigurationParameters, editParamIndex: systemConfigurationParameters.length - 1 });
  }

  save = (index) => {
    let { systemConfigurationParameters } = this.state;
    this.props.setSystemConfigurationParameter(systemConfigurationParameters[index]).then(() => {
      this.getSystemConfigurationParameters();
    })
  }

  cancel = (index) => {
    let { initialSystemConfigurationParameters, systemConfigurationParameters } = this.state;
    if (initialSystemConfigurationParameters[index]) {
      systemConfigurationParameters[index] = JSON.parse(JSON.stringify(initialSystemConfigurationParameters[index]));
    } else {
      systemConfigurationParameters.splice(index, 1);
    }
    this.setState({ systemConfigurationParameters, editParamIndex: -1 });
  }

  activateAsset = (asset) => {
    this.props.activateAsset(asset.shortName, {params: {activate: !asset.active}});
  }

  render() {

    const { initialSystemConfigurationParameters, systemConfigurationParameters, editParamIndex } = this.state;
    const { assets } = this.props.adminReducer;

    let data;
    if (systemConfigurationParameters && systemConfigurationParameters.length > 0) {
      data = systemConfigurationParameters.map((param, index) => {
        return <TableRow key={index}>
          <TableCell className="table-cell">
            {index !== editParamIndex ? param.key :
              <TextField
                id={"key" + index}
                name={"key" + index}
                onChange={(e) => this.handleChangeKey(e, index)}
                value={param.key}
                required
                inputProps={{
                  autoComplete: 'off',
                  spellCheck: false
                }}
              />
            }
          </TableCell>
          <TableCell className="table-cell">
            {index !== editParamIndex ? param.value :
              <TextField
                id={"value" + index}
                name={"value" + index}
                onChange={(e) => this.handleChangeValue(e, index)}
                value={param.value}
                required
                inputProps={{
                  autoComplete: 'off',
                  spellCheck: false
                }}
              />
            }
          </TableCell>
          <TableCell className="table-cell">{param.created && moment(param.created).utc().format('DD.MM.YYYY. HH:mm:ss')}</TableCell>
          <TableCell className="table-cell">{param.updated && moment(param.updated).utc().format('DD.MM.YYYY. HH:mm:ss')}</TableCell>
          <TableCell className="table-cell">
            {index !== editParamIndex ? <Fab disabled={editParamIndex > -1} className="table-fab" title={this.props.t("edit")} onClick={() => this.setState({ editParamIndex: index })} style={{ marginRight: 10 }} color="primary">
              <EditIcon className="table-fab-icon" />
            </Fab> : <>
              {JSON.stringify(initialSystemConfigurationParameters) !== JSON.stringify(systemConfigurationParameters) &&
                <Fab disabled={!systemConfigurationParameters[index].key || !systemConfigurationParameters[index].value} color="primary" style={{ marginRight: '10px' }} className="table-fab" title={this.props.t("save")} aria-label="save" onClick={() => this.save(index)}>
                  <CheckCircleIcon className="table-fab-icon" />
                </Fab>}
              <Fab className="table-fab" title={this.props.t("cancel")} color="inherit" aria-label="cancel" onClick={() => this.cancel(index)}>
                <CancelIcon className="table-fab-icon" />
              </Fab>
            </>}
          </TableCell>
        </TableRow>
      });
    } else {
      data = <TableRow>
        <TableCell align="center" colSpan={5}>
          {this.props.t("records_not_found")}
        </TableCell>
      </TableRow>
    }

    return (
      <>
        <Grid container direction="row" spacing={2}>
          <Grid item container>
            <Typography variant="h6" style={{ alignItems: 'center', display: 'flex' }}>
              <Fab
                className="table-fab"
                title={this.props.t("back")}
                size="small"
                color="primary"
                onClick={() => this.props.history.goBack()}>
                <ArrowBack />
              </Fab> <span style={{ marginLeft: 10 }}>{this.props.t("system_settings")}</span>
            </Typography>
            <Button disabled={editParamIndex !== -1} style={{ marginLeft: 'auto' }} onClick={() => this.addParameter()} color="primary">
              {this.props.t("add_parameter")}
            </Button>
          </Grid>
          <Grid item container direction="column" xs={12} sm={12} md={12} lg={10} xl={6}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className="header-table-cell" component="th" scope="row" width="25%">{this.props.t("key")}</TableCell>
                    <TableCell className="header-table-cell" component="th" scope="row" width="25%">{this.props.t("value")}</TableCell>
                    <TableCell className="header-table-cell" component="th" scope="row" width="20%">{this.props.t("created")}</TableCell>
                    <TableCell className="header-table-cell" component="th" scope="row" width="20%">{this.props.t("updated")}</TableCell>
                    <TableCell className="header-table-cell" component="th" scope="row" width="10%">{this.props.t("actions")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <Typography variant="h6" style={{ alignItems: 'center', display: 'flex', marginTop: 20, marginBottom: 20 }}>
          {this.props.t("active_assets")}
        </Typography>
        <Grid container direction="row" spacing={2}>
          {assets && assets.length > 0 && assets.map(asset => {
            return <Grid key={asset.shortName} item container direction="column" xs={12} sm={6} md={6} lg={3} xl={2}>
              <Paper
                className="pos-menu"
                style={{
                  textAlign: 'center',
                  padding: 10,
                  opacity: !asset.active ? '0.4' : 'unset',
                  backgroundColor: !asset.active ? '#e0e0e0' : 'unset',
                  cursor: 'pointer'
                }}
                onClick={() => this.activateAsset(asset)}
              >
                <img alt="edit" src={"data:image/png;base64," + asset.image} style={{ width: "100px", height: "100px" }} />
                <Typography variant="h6">
                  {asset.name}
                </Typography>
              </Paper>
            </Grid>
          })}
        </Grid>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  adminReducer: state.adminReducer
})

const mapActionsToProps = { getSystemConfigurationParameters, getSystemConfigurationParameterByKey, setSystemConfigurationParameter, getAssets, activateAsset }

export default connect(mapStateToProps, mapActionsToProps)(withTranslation()(SystemSettings))
