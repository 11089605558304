import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { login } from '../redux/merchant/actions';
import {
  Button,
  TextField,
  Grid,
  Box
} from "@mui/material";
import Alert from '@mui/material/Alert';
import logo from '../assets/bb-logo.png';
import LanguageMenu from "../components/common/LanguageMenu";

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        username: '',
        password: '',
        twoFA: ''
      },
      validation: {
        usernameError: true,
        usernameErrorText: '',
        passwordError: true,
        passwordErrorText: ''
      }
    };
  }

  handleChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    let user = { ...this.state.user };
    user[name] = value;

    this.validate(name, value);
    this.setState({ user: user });
  }

  validate(field, value) {
    let { validation } = this.state;
    switch (field) {
      case "username":
        if (value.length === 0) {
          validation.usernameError = true;
          validation.usernameErrorText = this.props.t("required_field");
        } else {
          validation.usernameError = false;
          validation.usernameErrorText = "";
        }
        this.setState({ validation: validation });
        break;
      case "password":
        if (value.length === 0) {
          validation.passwordError = true;
          validation.passwordErrorText = this.props.t("required_field");
        } else {
          validation.passwordError = false;
          validation.passwordErrorText = "";
        }
        this.setState({ validation: validation });
        break;
      default:
        return;
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const { user, validation } = this.state;
    if (validation.usernameError || validation.passwordError) {
      return;
    }
    this.props.login(user);
  }

  render() {
    const { user, validation } = this.state
    const { loginError } = this.props.merchantReducer
    return (
      <Grid container spacing={0} direction="row" className="wallpaper">
        <Box className="login-card">
          <LanguageMenu style={{ position: 'absolute', top: "10px", right: "10px" }} />
          <form onSubmit={this.handleSubmit} className="" noValidate autoComplete="off" spellCheck="false">
            <Grid container direction="row" spacing={2}>
              <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12} textAlign="center" alignContent="center">
                <img width="300px" height="auto" src={logo} alt="logo" />
                <span style={{ fontSize: "20px", fontWeight: 'bold', color: '#155DA7' }}>{this.props.t("crypto_payment_gateway")}</span>
              </Grid>
              <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  autoFocus
                  className="login-form-input"
                  label={this.props.t("username")}
                  id="username"
                  name="username"
                  onChange={this.handleChange}
                  value={user.username}
                  helperText={validation.usernameErrorText}
                  required
                  error={validation.usernameError}
                />
              </Grid>
              <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  className="login-form-input"
                  type="password"
                  label={this.props.t("password")}
                  id="password"
                  name="password"
                  onChange={this.handleChange}
                  value={user.password}
                  helperText={validation.passwordErrorText}
                  required
                  error={validation.passwordError}
                />
              </Grid>
              <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  className="login-form-input"
                  label={this.props.t("otp_code")}
                  id="twoFA"
                  name="twoFA"
                  onChange={this.handleChange}
                  value={user.twoFA}
                />
              </Grid>
              <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12} alignContent="center" style={{ paddingTop: "0px" }}>
                {loginError && <Alert severity="error" sx={{ backgroundColor: "white", color: "#ef5350" }}>{this.props.t("login_error")}</Alert>}
              </Grid>
              <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                <Button
                  size="medium"
                  variant="contained"
                  color="primary"
                  type="submit"
                  className="button-block"
                >{this.props.t("login")}</Button>
              </Grid>
              <div style={{ position: 'absolute', bottom: "10px", right: "20px", fontSize: "12px" }}>
                v{process.env.REACT_APP_VERSION || 'NOT_SET'}
              </div>
            </Grid>
          </form>
        </Box>
      </Grid >
    );
  }
}

const mapStateToProps = (state) => ({
  merchantReducer: state.merchantReducer
});

const mapActionsToProps = {
  login
}

export default connect(mapStateToProps, mapActionsToProps)(withTranslation()(LoginForm));
