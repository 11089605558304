import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    getOrders as getAdminOrders,
    updateOrdersPageSize as updateAdminOrdersPageSize,
    getOrderHistory as getAdminOrderHistory,
    updateOrderHistoryPageSize as updateAdminOrderHistoryPageSize,
    getMerchantOrders as getAdminMerchantOrders,
    getTransactions as getAdminTransactions,
    updateTransactionsPageSize as updateAdminTransactionsPageSize,
    getMerchantTransactions as getMTransactions,
    getRevenue as getAdminRevenue,
    getPaymentData as getAdminPaymentData,
    updateOrderStatus,
    getMerchantEmails,
    createMerchantEmail,
    updateMerchantEmail,
    deleteMerchantEmail
} from '../../redux/admin/actions';
import {
    getOrders as getMerchantOrders,
    updateOrdersPageSize as updateMerchantOrdersPageSize,
    getOrderHistory as getMerchantOrderHistory,
    updateOrderHistoryPageSize as updateMerchantOrderHistoryPageSize,
    getTransactions as getMerchantTransactions,
    updateTransactionsPageSize as updateMerchantTransactionsPageSize,
    getRevenue as getMerchantRevenue,
    getPaymentData as getMerchantPaymentData,
    getEmails,
    createEmail,
    updateEmail,
    deleteEmail
} from '../../redux/merchant/actions';
import '../../components/sidebar/Sidebar.css';
import Revenue from '../../views/Revenue';
import Merchants from '../../views/merchant/Merchants';
import Orders from '../../views/order/Orders';
import OrderHistory from '../../views/order/OrderHistory';
import Tokens from '../../views/token/Tokens';
import Settings from '../../views/settings/Settings';
import Transactions from '../../views/transactions/Transactions';
import POS from '../../views/POS/POS';
import Users from '../../views/users/Users';
import { hasRole } from '../../security/Security';
import ExchangeOrderSettings from '../../views/exchangeSettings/ExchangeOrderSettings';
import POSReports from '../../views/POS/POSReports';
import Integration from '../../views/integration/Integration';
import { Alert } from '@mui/material';
import SystemSettings from '../../views/settings/SystemSettings';
import Settlements from '../../views/settlement/Settlements';
import HealthCheck from '../../views/healthcheck/HealthCheck';
import Notifications from '../../views/notifications/Notifications';

class Content extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <main className="content">
                {!this.props.user.twoFA && <Alert className="twoFA-alert" severity="error">{this.props.t("2fa_alert_message")}</Alert>}
                <Switch>
                    {hasRole(this.props.user, ["ADMIN"]) && <Route exact path='/revenue' render={(props) => <Revenue {...props}
                        reducer={this.props.adminReducer}
                        getRevenue={this.props.getAdminRevenue}
                    />} />}
                    {hasRole(this.props.user, ["MERCHANT"]) && <Route exact path='/revenue' render={(props) => <Revenue {...props}
                        reducer={this.props.merchantReducer}
                        getRevenue={this.props.getMerchantRevenue}
                    />} />}
                    {hasRole(this.props.user, ["ADMIN"]) && <Route exact path='/merchants' render={(props) => <Merchants {...props} />} />}
                    {hasRole(this.props.user, ["ADMIN"]) && <Route exact path='/orders' render={(props) => <Orders {...props}
                        reducer={this.props.adminReducer}
                        getOrders={this.props.getAdminOrders}
                        getOrderHistory={this.props.getAdminOrderHistory}
                        updateOrdersPageSize={this.props.updateAdminOrdersPageSize}
                        getPaymentData={this.props.getAdminPaymentData} />} />}
                    {hasRole(this.props.user, ["ADMIN"]) && <Route exact path='/orders/:id' render={(props) => <Orders {...props}
                        reducer={this.props.adminReducer}
                        getOrders={this.props.getAdminMerchantOrders}
                        getOrderHistory={this.props.getAdminOrderHistory}
                        updateOrdersPageSize={this.props.updateAdminOrdersPageSize}
                        getPaymentData={this.props.getAdminPaymentData} />} />}
                    {hasRole(this.props.user, ["ADMIN"]) && <Route exact path='/order-history/:id' render={(props) => <OrderHistory {...props}
                        reducer={this.props.adminReducer}
                        getOrderHistory={this.props.getAdminOrderHistory}
                        updateOrderHistoryPageSize={this.props.updateAdminOrderHistoryPageSize} />} />}
                    {hasRole(this.props.user, ["ADMIN"]) && <Route exact path={['/transactions', '/transactions/:id']} render={(props) => <Transactions {...props}
                        reducer={this.props.adminReducer}
                        getTransactions={this.props.getAdminTransactions}
                        updateTransactionsPageSize={this.props.updateAdminTransactionsPageSize}
                        getMTransactions={this.props.getMTransactions} />} />}
                    {hasRole(this.props.user, ["ADMIN"]) && <Route exact path='/exchange-order-settings' render={(props) => <ExchangeOrderSettings {...props} />} />}
                    {hasRole(this.props.user, ["MERCHANT", "POS"]) && <Route exact path='/orders' render={(props) => <Orders {...props}
                        reducer={this.props.merchantReducer}
                        getOrders={this.props.getMerchantOrders}
                        getOrderHistory={this.props.getMerchantOrderHistory}
                        updateOrdersPageSize={this.props.updateMerchantOrdersPageSize}
                        getPaymentData={this.props.getMerchantPaymentData} />} />}
                    {hasRole(this.props.user, ["MERCHANT", "POS"]) && <Route exact path='/order-history/:id' render={(props) => <OrderHistory {...props}
                        reducer={this.props.merchantReducer}
                        getOrderHistory={this.props.getMerchantOrderHistory}
                        updateOrderHistoryPageSize={this.props.updateMerchantOrderHistoryPageSize} />} />}
                    {hasRole(this.props.user, ["ADMIN", "MERCHANT"]) && <Route exact path='/settlements' render={(props) => <Settlements {...props} />} />}
                    {hasRole(this.props.user, ["MERCHANT"]) && <Route exact path='/tokens' render={(props) => <Tokens {...props} />} />}
                    {hasRole(this.props.user, ["ADMIN", "MERCHANT"]) && <Route exact path='/integration' render={(props) => <Integration {...props} />} />}
                    {hasRole(this.props.user, ["ADMIN"]) && <Route exact path='/health-check' render={(props) => <HealthCheck {...props} />} />}
                    {hasRole(this.props.user, ["ADMIN", "MERCHANT", "POS"]) && <Route exact path='/settings' render={(props) => <Settings {...props} />} />}
                    {hasRole(this.props.user, ["ADMIN"]) && <Route exact path='/system-settings' render={(props) => <SystemSettings {...props} />} />}
                    {hasRole(this.props.user, ["MERCHANT"]) && <Route exact path='/users' render={(props) => <Users {...props} />} />}
                    {hasRole(this.props.user, ["MERCHANT", "POS"]) && <Route exact path='/reports' render={(props) => <POSReports {...props}
                        reducer={this.props.merchantReducer}
                        getOrders={this.props.getMerchantOrders}
                        getOrderHistory={this.props.getMerchantOrderHistory}
                        updateOrdersPageSize={this.props.updateMerchantOrdersPageSize}
                        getPaymentData={this.props.getMerchantPaymentData} />} />}
                    {hasRole(this.props.user, ["ADMIN"]) && <Route exact path='/reports' render={(props) => <POSReports {...props}
                        reducer={this.props.adminReducer}
                        getOrders={this.props.getAdminOrders}
                        getOrderHistory={this.props.getAdminOrderHistory}
                        updateOrdersPageSize={this.props.updateAdminOrdersPageSize}
                        updateOrderStatus={this.props.updateOrderStatus}
                        getPaymentData={this.props.getAdminPaymentData}
                    />} />}
                    {hasRole(this.props.user, ["MERCHANT", "POS", "ADMIN"]) && <Route exact path='/pos' render={(props) => <POS {...props} />} />}
                    {hasRole(this.props.user, ["MERCHANT", "POS", "ADMIN"]) && <Route exact path='/pos/:status' render={(props) => <POS {...props} />} />}
                    {hasRole(this.props.user, ["MERCHANT"]) && <Route exact path='/notifications' render={(props) => <Notifications {...props}
                        reducer={this.props.merchantReducer}
                        getEmails={this.props.getEmails}
                        createEmail={this.props.createEmail}
                        updateEmail={this.props.updateEmail}
                        deleteEmail={this.props.deleteEmail}
                    />} />}
                    {hasRole(this.props.user, ["ADMIN"]) && <Route exact path='/notifications' render={(props) => <Notifications {...props}
                        reducer={this.props.adminReducer}
                        getEmails={this.props.getMerchantEmails}
                        createEmail={this.props.createMerchantEmail}
                        updateEmail={this.props.updateMerchantEmail}
                        deleteEmail={this.props.deleteMerchantEmail}
                    />} />}
                    {hasRole(this.props.user, ["ADMIN", "MERCHANT"]) && <Redirect to="/revenue" />}
                    {hasRole(this.props.user, ["POS"]) && <Redirect to="/pos" />}
                </Switch>
            </main>
        );
    }
}

const mapStateToProps = (state) => ({
    adminReducer: state.adminReducer,
    merchantReducer: state.merchantReducer,
    user: state.merchantReducer.user
});

const mapActionsToProps = {
    getAdminOrders,
    updateAdminOrdersPageSize,
    getMerchantOrders,
    updateMerchantOrdersPageSize,
    getAdminOrderHistory,
    updateAdminOrderHistoryPageSize,
    getMerchantOrderHistory,
    updateMerchantOrderHistoryPageSize,
    getAdminMerchantOrders,
    getAdminTransactions,
    updateAdminTransactionsPageSize,
    getMerchantTransactions,
    updateMerchantTransactionsPageSize,
    getMTransactions,
    getAdminRevenue,
    getMerchantRevenue,
    getAdminPaymentData,
    getMerchantPaymentData,
    updateOrderStatus,
    getEmails,
    createEmail,
    updateEmail,
    deleteEmail,
    getMerchantEmails,
    createMerchantEmail,
    updateMerchantEmail,
    deleteMerchantEmail
}

export default connect(mapStateToProps, mapActionsToProps)(withTranslation()(Content));
