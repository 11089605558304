import types from './types';

const initialState = {
    isLoading: false,
    exchangeOrderSettings: [],
    exchangeOrderSettingsTotalElements: 0,
    exchangeOrderSettingsSize: 10,
    exchanges: [],
    currencyPairs: [],
    exchangeOrderSettingsGroups: []
}

export default function exchangeOrderSettings(state = initialState, action) {
    switch (action.type) {
        case types.SET_EXCHANGE_ORDER_SETTINGS_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case types.SET_EXCHANGE_ORDER_SETTINGS:
            return {
                ...state,
                exchangeOrderSettings: action.payload.list ? action.payload.list : [],
                exchangeOrderSettingsTotalElements: action.payload.pagination ? action.payload.pagination.items : 0,
                isLoading: false
            }
        case types.SET_EXCHANGE_ORDER_SETTINGS_PAGE_SIZE:
            return {
                ...state,
                exchangeOrderSettingsSize: action.payload
            }
        case types.SET_EXCHANGES:
            return {
                ...state,
                exchanges: action.payload.list ? action.payload.list : [],
            }
        case types.SET_CURRENCY_PAIRS:
            return {
                ...state,
                currencyPairs: action.payload.list ? action.payload.list[0].pairs : [],
            }
        case types.SET_EXCHANGE_ORDER_SETTINGS_GROUPS:
            return {
                ...state,
                exchangeOrderSettingsGroups: action.payload.list ? action.payload.list : []
            }

        default:
            return state;
    }
}
