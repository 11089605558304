export const SET_SETTLEMENT_ORDERS_LOADING = 'SET_SETTLEMENT_ORDERS_LOADING';
export const SET_SETTLEMENT_ORDERS = 'SET_SETTLEMENT_ORDERS';
export const SET_SETTLEMENT_ORDERS_PAGE_SIZE = 'SET_SETTLEMENT_ORDERS_PAGE_SIZE';
export const SET_NEW_SETTLEMENT_ORDERS = 'SET_NEW_SETTLEMENT_ORDERS';

const types = {
    SET_SETTLEMENT_ORDERS_LOADING,
    SET_SETTLEMENT_ORDERS,
    SET_SETTLEMENT_ORDERS_PAGE_SIZE,
    SET_NEW_SETTLEMENT_ORDERS
};

export default types;