import axios from '../axiosConfig';

export function init(data) {
    return axios.post('/api/v1/merchant/payments/init', data);
}

export function adminInit(data) {
    return axios.post('/api/v1/admin/payments/init', data);
}

export function publicInit(data) {
    return axios.post('/api/v1/payments/public/init', data);
}

export function getPublicPaymentData(orderId, config = {}) {
    return axios.get(`/api/v1/payments/public/data/${orderId}`, config);
}

export function getMerchantPublicPaymentData(merchantPaymentCode, config = {}) {
    return axios.get(`/api/v1/payments/public/merchant/${merchantPaymentCode}`, config);
}

const actions = {
    init,
    adminInit,
    publicInit,
    getPublicPaymentData,
    getMerchantPublicPaymentData
}

export default actions;
