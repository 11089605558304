import React from "react";
import { withTranslation } from 'react-i18next';
import moment from "moment";
import { connect } from 'react-redux';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Fab,
  Typography,
  Collapse,
  Box,
  TableHead,
  Button
} from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { hasRole } from "../../security/Security";
import Base58Table from "../../components/common/Base58Table";

class Transactions extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      params: { sort: 'created,desc' },
      collapseIndex: -1
    };
  }

  async componentDidMount() {
    this.getTransactions(0);
  }

  getTransactions = (page) => {
    let { params } = this.state;

    params.page = page;
    params.size = this.props.reducer.transactionsSize;

    this.setState({ collapseIndex: -1 },
      () => this.props.match.params.id ?
        this.props.getMTransactions(this.props.match.params.id, { params: params }) :
        this.props.getTransactions({ params: params }));
  }

  onPageChange = (event, page) => {
    this.setState({ page: page }, () => {
      this.getTransactions(page);
    })
  }

  onRowsPerPageChange = (event) => {
    this.props.updateTransactionsPageSize(event.target.value).then(() => {
      this.setState({ page: 0 });
      this.getTransactions(0);
    })
  }

  render() {
    const { page } = this.state;
    let { transactions, transactionsTotalElements, transactionsSize, transactionsLoading } = this.props.reducer;

    // if (page === 0 && transactionsLoading) {
    //   return (
    //     <div className="loading-position">
    //       <CircularProgress className="spinner" color="primary" />
    //     </div>
    //   )
    // }

    let data;
    if (transactions && transactions.length > 0) {
      data = transactions.map((transaction, index) => {
        return <React.Fragment key={index}>
          <TableRow>
            <TableCell style={{ fontWeight: index === this.state.collapseIndex ? 'bold' : 'unset' }} className="table-cell">{transaction.orderId}</TableCell>
            <TableCell style={{ fontWeight: index === this.state.collapseIndex ? 'bold' : 'unset' }} className="table-cell">{transaction.txHash}</TableCell>
            <TableCell style={{ fontWeight: index === this.state.collapseIndex ? 'bold' : 'unset' }} className="table-cell">{transaction.block}</TableCell>
            <TableCell style={{ fontWeight: index === this.state.collapseIndex ? 'bold' : 'unset' }} className="table-cell">{transaction.confirmationNo}</TableCell>
            {hasRole(this.props.user, ["ADMIN"]) && <TableCell style={{ fontWeight: index === this.state.collapseIndex ? 'bold' : 'unset' }} className="table-cell">{transaction.riskScore}</TableCell>}
            {hasRole(this.props.user, ["ADMIN"]) && <TableCell style={{ fontWeight: index === this.state.collapseIndex ? 'bold' : 'unset' }} className="table-cell">{this.props.t(transaction.status)}</TableCell>}
            <TableCell style={{ fontWeight: index === this.state.collapseIndex ? 'bold' : 'unset' }} className="table-cell">
              <Button
                id={"order-history-list-" + index}
                onClick={() => this.setState({ collapseIndex: this.state.collapseIndex !== index ? index : -1 })}>
                {index === this.state.collapseIndex ? this.props.t("collapse") : this.props.t("expand")}
              </Button>
            </TableCell>
            <TableCell style={{ fontWeight: index === this.state.collapseIndex ? 'bold' : 'unset' }} className="table-cell">{moment.tz(transaction.created, transaction.timeZone).format('DD.MM.YYYY. HH:mm:ss')}</TableCell>
            <TableCell style={{ fontWeight: index === this.state.collapseIndex ? 'bold' : 'unset' }} className="table-cell">{moment.tz(transaction.updated, transaction.timeZone).format('DD.MM.YYYY. HH:mm:ss')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0, backgroundColor: '#E4E9F8' }} colSpan={hasRole(this.props.user, ["ADMIN"]) ? 9 : 7}>
              <Collapse in={index === this.state.collapseIndex} timeout="auto" unmountOnExit style={{ paddingTop: 10, paddingBottom: 20 }}>
                <Box sx={{ margin: 1 }}>
                  <Typography variant="h6" gutterBottom>
                    {this.props.t("addresses")}
                  </Typography>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell className="header-table-cell" component="th" scope="row" width="20%">{this.props.t("address")}</TableCell>
                          <TableCell className="header-table-cell" component="th" scope="row" width="20%">{this.props.t("is_input_address")}</TableCell>
                          <TableCell className="header-table-cell" component="th" scope="row" width="20%">{this.props.t("amount")}</TableCell>
                          {hasRole(this.props.user, ["ADMIN"]) && <TableCell className="header-table-cell" component="th" scope="row" width="20%">{this.props.t("risk_score")}</TableCell>}
                          {hasRole(this.props.user, ["ADMIN"]) && <TableCell className="header-table-cell" component="th" scope="row" width="20%">{this.props.t("status")}</TableCell>}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {transaction && transaction.addresses.length > 0 && transaction.addresses.map(address => {
                          return <TableRow key={address.address}>
                            <TableCell className="table-cell">{address.address}</TableCell>
                            <TableCell className="table-cell">{address.isInputAddress}</TableCell>
                            <TableCell className="table-cell">{address.amount}</TableCell>
                            {hasRole(this.props.user, ["ADMIN"]) && <TableCell className="table-cell">{transaction.riskScore}</TableCell>}
                            {hasRole(this.props.user, ["ADMIN"]) && <TableCell className="table-cell">{this.props.t(transaction.status)}</TableCell>}
                          </TableRow>
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        </React.Fragment>
      });
    } else {
      data = <TableRow>
        <TableCell align="center" colSpan={hasRole(this.props.user, ["ADMIN"]) ? 9 : 7}>
          {this.props.t("records_not_found")}
        </TableCell>
      </TableRow>
    }

    let columns = [
      {
        name: "order_id",
        dbName: 'orderId',
        width: '16%',
        sort: false,
        filter: true
      },
      {
        name: "tx_hash",
        dbName: 'txHash',
        width: '26%',
        sort: false,
        filter: false
      },
      {
        name: "block",
        dbName: 'block',
        width: '9%',
        sort: false,
        filter: false
      },
      {
        name: "confirmation_no",
        dbName: 'confirmationNo',
        width: '9%',
        sort: false,
        filter: false
      },
      { name: "risk_score", width: '9%' },
      { name: "status", width: '10%' },
      { name: "addresses", width: '5%' },
      { name: "created", dbName: 'created', width: '8%', sort: true },
      { name: "updated", dbName: 'updated', width: '8%', sort: false }
    ];

    return (
      <Grid container direction="row" spacing={2}>
        <Grid item container>
          <Typography variant="h6" style={{ alignItems: 'center', display: 'flex' }}>
            <Fab
              className="table-fab"
              title={this.props.t("back")}
              size="small"
              color="primary"
              onClick={() => this.props.history.goBack()}>
              <ArrowBack />
            </Fab> <span style={{ marginLeft: 10 }}>{this.props.t("transactions")} {this.props.location.state && " (" + this.props.location.state.merchantName + ")"}</span>
          </Typography>
        </Grid>
        <Grid item container>
          <Base58Table
            isLoading={transactionsLoading}
            columns={columns}
            data={data}
            count={transactionsTotalElements}
            rowsPerPage={transactionsSize}
            page={page}
            onPageChange={this.onPageChange}
            onRowsPerPageChange={this.onRowsPerPageChange}
            onFilterChange={(params) => this.setState({ params: params }, () => this.getTransactions(0))}
          />
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.merchantReducer.user
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(withTranslation()(Transactions))

