import React from "react";
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { connect } from 'react-redux';
import { getPOSUsers, updatePOSUsersPageSize, updatePOSUser } from '../../redux/merchant/actions';
import {
  Grid,
  Button,
  TableCell,
  TableRow,
  Fab,
  Typography
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ArrowBack from '@mui/icons-material/ArrowBack';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import SecurityIcon from '@mui/icons-material/Security';
import CreateOrUpdateMerchantDialog from '../merchant/CreateOrUpdateMerchantDialog';
import ConfirmationDialog from "../../components/common/ConfirmationDialog";
import Base58Table from "../../components/common/Base58Table";
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

class Users extends React.Component {

  emptyPOSUser = {
    id: null,
    name: '',
    username: '',
    password: '',
    defaultCurrency: ''
  }

  constructor(props) {
    super(props);
    this.state = {
      openDialog: false,
      openLockDialog: false,
      openDeleteDialog: false,
      page: 0,
      params: { sort: 'created,desc' },
      posUserToLock: this.emptyPOSUser,
      openDisable2FADialog: false
    };
  }

  componentDidMount() {
    this.getPOSUsers(0);
  }

  getPOSUsers = (page) => {
    let { params } = this.state;

    params.page = page;
    params.size = this.props.merchantReducer.posUsersSize;

    if (!params.sort) {
      params.sort = "created,desc";
    }

    if (params.hasOwnProperty('status')) {
      if (params.status != null) {
        const status = params.status === "true" ? "ACTIVE" : "DISABLED";
        params.status = status;
      }
    }

    this.props.getPOSUsers({ params: params }).then(() => {
      if (page !== 0 && this.props.merchantReducer.posUsers.length === 0) {
        this.onPageChange(null, page - 1);
      }
    });
  }

  onPageChange = (event, page) => {
    this.setState({ page: page }, () => {
      this.getPOSUsers(page);
    })
  }

  onRowsPerPageChange = (event) => {
    this.props.updatePOSUsersPageSize(event.target.value).then(() => {
      this.setState({ page: 0 });
      this.getPOSUsers(0);
    })
  }

  handleChangeFilter = (event) => {
    this.setState({ filter: { ...this.state.filter, [event.target.name]: event.target.value } });
  }

  render() {
    const { page } = this.state;
    const { posUsersLoading, posUsers, posUsersTotalElements, posUsersSize } = this.props.merchantReducer;

    // if (posUsersLoading) {
    //   return (
    //     <div className="loading-position">
    //       <CircularProgress className="spinner" color="primary" />
    //     </div>
    //   )
    // }

    let data;
    if (posUsers && posUsers.length > 0) {
      data = posUsers.map((posUser, index) => {
        return <TableRow key={posUser.id}>
          <TableCell className="table-cell">{posUser.id}</TableCell>
          <TableCell className="table-cell">{posUser.name}</TableCell>
          <TableCell className="table-cell">{posUser.username}</TableCell>
          <TableCell className="table-cell">{posUser.storeName}</TableCell>
          <TableCell className="table-cell">{posUser.defaultCurrency}</TableCell>
          <TableCell className="table-cell">{posUser.status === 'ACTIVE' ? <CheckIcon style={{ color: 'green' }} /> : <ClearIcon style={{ color: 'red' }} />}</TableCell>
          <TableCell className="table-cell">{posUser.twoFA ? <CheckIcon style={{ color: 'green' }} /> : <ClearIcon style={{ color: 'red' }} />}</TableCell>
          <TableCell className="table-cell">{moment.tz(posUser.created, posUser.timeZone).format('DD.MM.YYYY. HH:mm:ss')}</TableCell>
          <TableCell className="table-cell">{moment.tz(posUser.updated, posUser.timeZone).format('DD.MM.YYYY. HH:mm:ss')}</TableCell>
          <TableCell className="table-cell">
            <Fab className="table-fab" title={posUser.status === "DISABLED" ? this.props.t("activate") : this.props.t("disable")}
              id={"merchants-tbl-actions-lock-" + index} style={{ marginRight: 10 }} color={posUser.status === "DISABLED" ? "inherit" : "secondary"} onClick={() => this.setState({ openLockDialog: true, posUserToLock: posUser })}>
              {posUser.status === "DISABLED" ? <LockIcon className="table-fab-icon" /> : <LockOpenIcon className="table-fab-icon" />}
            </Fab>
            <Fab className="table-fab" title={this.props.t("edit")} onClick={() => this.setState({ posUserToEdit: posUser.id, openDialog: true })} style={{ marginRight: 10 }} color="primary">
              <EditIcon className="table-fab-icon" />
            </Fab>
            {posUser.twoFA && <Fab className="table-fab" title={this.props.t("disable_2FA_dialog_title")} onClick={() => this.setState({ posUserToLock: posUser, openDisable2FADialog: true })} style={{ marginRight: 10 }} color="primary">
              <SecurityIcon className="table-fab-icon" />
            </Fab>}
          </TableCell>
        </TableRow>
      });
    } else {
      data = <TableRow>
        <TableCell align="center" colSpan={8}>
          {this.props.t("records_not_found")}
        </TableCell>
      </TableRow>
    }

    let columns = [
      {
        name: "id",
        dbName: 'id',
        width: '10%',
        sort: true,
        filter: false
      },
      {
        name: "name",
        dbName: 'name',
        width: '10%',
        sort: true,
        filter: true
      },
      {
        name: "username",
        dbName: 'username',
        width: '10%',
        sort: true,
        filter: true
      },
      {
        name: "store_name",
        dbName: 'storeName',
        width: '10%',
        sort: true,
        filter: false
      },
      {
        name: "default_currency",
        dbName: 'defaultCurrency',
        type: "select",
        items: ["EUR", "USD", "HRK"],
        width: '10%',
        sort: true,
        filter: false
      },
      {
        name: "active",
        dbName: 'status',
        type: "boolean-select",
        width: '10%',
        sort: true,
        filter: true
      },
      {
        name: "2FA",
        dbName: 'twoFa',
        type: "boolean-select",
        width: '10%',
        sort: true,
        filter: false
      },
      {
        name: "created",
        dbName: 'created',
        width: '10%',
        sort: true,
        filter: false
      },
      {
        name: "updated",
        dbName: 'updated',
        width: '10%',
        sort: true,
        filter: false
      },
      {
        name: "actions",
        dbName: 'actions',
        width: '10%'
      }
    ];

    return (
      <Grid container direction="row" spacing={2}>
        <Grid item container>
          <Typography variant="h6" style={{ alignItems: 'center', display: 'flex' }}>
            <Fab
              className="table-fab"
              title={this.props.t("back")}
              size="small"
              color="primary"
              onClick={() => this.props.history.goBack()}>
              <ArrowBack />
            </Fab> <span style={{ marginLeft: 10 }}>{this.props.t("pos_users")}</span>
          </Typography>
          <Button style={{ marginLeft: 'auto' }} onClick={() => this.setState({ posUserToEdit: null, openDialog: true })} color="primary">
            {this.props.t("create_pos_user")}
          </Button>
        </Grid>
        <Grid item container>
          <Base58Table
            isLoading={posUsersLoading}
            columns={columns}
            data={data}
            count={posUsersTotalElements}
            rowsPerPage={posUsersSize}
            page={page}
            onPageChange={this.onPageChange}
            onRowsPerPageChange={this.onRowsPerPageChange}
            onFilterChange={(params) => this.setState({ params: params }, () => this.getPOSUsers(0))}
          />
        </Grid>
        <ConfirmationDialog
          isOpen={this.state.openLockDialog}
          title={this.state.posUserToLock.status === "ACTIVE" ? this.props.t("disable_pos_user") : this.props.t("activate_pos_user")}
          message={this.props.t("disable_activate_pos_user_message", { action: this.state.posUserToLock.status === "ACTIVE" ? this.props.t("disable_2") : this.props.t("activate_2"), user: this.state.posUserToLock.name })}
          onClose={() => this.setState({ openLockDialog: false })}
          onConfirm={() => this.props.updatePOSUser(this.state.posUserToLock.id, { status: this.state.posUserToLock.status === "ACTIVE" ? "DISABLED" : "ACTIVE" }).then(() => {
            this.setState({ openLockDialog: false }, () => this.getPOSUsers(this.state.page));
          })}
          closeButtonTitle={this.props.t("close")}
          confirmButtonTitle={this.state.posUserToLock.status === "ACTIVE" ? this.props.t("disable") : this.props.t("activate")}
        />
        <CreateOrUpdateMerchantDialog
          open={this.state.openDialog}
          id={this.state.posUserToEdit}
          onClose={() => this.setState({ openDialog: false, posUserToEdit: null })}
          onSave={() => this.setState({ openDialog: false, posUserToEdit: null }, () => this.getPOSUsers(0))}
        />
        <ConfirmationDialog
          isOpen={this.state.openDisable2FADialog}
          title={this.props.t("disable_2FA_dialog_title")}
          message={this.props.t("disable_2FA_dialog_message")}
          onClose={() => this.setState({ openDisable2FADialog: false })}
          onConfirm={() => this.props.updatePOSUser(this.state.posUserToLock.id, { twoFA: false }).then(() => {
            this.setState({ openDisable2FADialog: false }, () => this.getPOSUsers(this.state.page));
          })}
          closeButtonTitle={this.props.t("no")}
          confirmButtonTitle={this.props.t("yes")}
        />
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  merchantReducer: state.merchantReducer
})

const mapActionsToProps = { getPOSUsers, updatePOSUsersPageSize, updatePOSUser }

export default connect(mapStateToProps, mapActionsToProps)(withTranslation()(Users))
