import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
    Grid,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    InputLabel,
    Select,
    MenuItem,
    FormControl,
    Checkbox,
    FormControlLabel,
    Fab,
    Typography,
    Divider
} from '@mui/material';
import {
    createMerchant,
    getMerchant,
    updateMerchant,
} from '../../redux/admin/actions';
import {
    createPOSUser,
    getPOSUser,
    updatePOSUser
} from '../../redux/merchant/actions';
import { hasRole } from '../../security/Security';
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import ClearIcon from '@mui/icons-material/Clear';
import TimezoneSelect, { allTimezones } from 'react-timezone-select'

const selectStyles = {
    menu: base => ({
        ...base,
        zIndex: 100
    })
};

class CreateOrUpdateMerchantDialog extends Component {

    defaultTimeZone = {
        abbrev: "GMT",
        altName: "British Standard Time",
        label: "(GMT+0:00) UTC",
        offset: 0,
        value: "Etc/GMT"
    }

    emptyData = {
        name: "",
        password: "",
        username: "",
        vat: "",
        storeName: "",
        street: "",
        streetNo: "",
        city: "",
        returnChange: false,
        immediateExchange: false,
        paymentCode: "",
        paymentDescription: null,
        logo: null,
        exchangeFeeGroup: null,
        timeZone: ""
    };

    emptyValidation = {
        nameError: false,
        nameErrorText: '',
        storeNameError: false,
        storeNameErrorText: '',
        vatError: false,
        vatErrorText: '',
        streetError: false,
        streetErrorText: '',
        streetNoError: false,
        streetNoErrorText: '',
        cityError: false,
        cityErrorText: '',
        usernameError: false,
        usernameErrorText: '',
        passwordError: false,
        passwordErrorText: '',
        paymentCodeError: false,
        paymentCodeErrorText: '',
        paymentDescriptionError: false,
        paymentDescriptionCodeErrorText: '',
        exchangeFeeGroupError: false,
        exchangeFeeGroupErrorText: ''
    };

    constructor(props) {
        super(props);
        this.state = {
            data: JSON.parse(JSON.stringify(this.emptyData)),
            initialData: JSON.parse(JSON.stringify(this.emptyData)),
            id: null,
            validation: JSON.parse(JSON.stringify(this.emptyValidation)),
            file: null,
            imagePreviewUrl: '',
            validLogo: true,
            selectedTimeZone: this.defaultTimeZone,
            timezones: allTimezones
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.id && prevProps.id !== this.props.id) {
            if (hasRole(this.props.user, ["ADMIN"])) {
                this.props.getMerchant(this.props.id).then(response => {
                    let data = response.data;
                    data.password = "";
                    this.setState({
                        data: data,
                        imagePreviewUrl: data.logo,
                        initialData: JSON.parse(JSON.stringify(data)),
                        selectedTimeZone: data.timeZone !== null && data.timeZone === "Etc/GMT" ? this.defaultTimeZone : Object.entries(allTimezones).find(zone => zone[0] === data.timeZone)[0]
                    });
                });
            }
            if (hasRole(this.props.user, ["MERCHANT"])) {
                this.props.getPOSUser(this.props.id).then(response => {
                    let data = response.data;
                    data.password = "";
                    this.setState({
                        data: data,
                        initialData: JSON.parse(JSON.stringify(data))
                    });
                });
            }
        }
    }

    handleChange = (event) => {
        const target = event.target;
        let value = null;
        if (target.type === 'checkbox') {
            value = target.checked
        } else if (target.type === 'number') {
            value = Number(target.value);
        } else {
            value = target.value;
        }
        const name = target.name;
        let { data } = this.state;
        data[name] = value;
        this.validate(name, value);
        this.setState({ data: data });
    }

    validate(field, value) {
        let { validation } = this.state;
        let regex = /^.{6,255}$/;
        switch (field) {
            case "name":
                if (value.length === 0) {
                    validation.nameError = true;
                    validation.nameErrorText = this.props.t("required_field");
                } else if (!regex.test(value)) {
                    validation.nameError = true;
                    validation.nameErrorText = this.props.t("validation_message", { field: this.props.t("name") })
                } else {
                    validation.nameError = false;
                    validation.nameErrorText = "";
                }
                this.setState({ validation: validation });
                break;
            case "storeName":
                if (value.length === 0) {
                    validation.storeNameError = true;
                    validation.storeNameErrorText = this.props.t("required_field");
                } else if (value.length > 255) {
                    validation.storeNameError = true;
                    validation.storeNameErrorText = this.props.t("ss_validation_message", { field: this.props.t("store_name") })
                } else {
                    validation.storeNameError = false;
                    validation.storeNameErrorText = "";
                }
                this.setState({ validation: validation });
                break;
            case "vat":
                if (value.length === 0) {
                    validation.vatError = true;
                    validation.vatErrorText = this.props.t("required_field");
                } else if (value.length > 255) {
                    validation.vatError = true;
                    validation.vatErrorText = this.props.t("ss_validation_message", { field: this.props.t("vat_id") })
                } else {
                    validation.vatError = false;
                    validation.vatErrorText = "";
                }
                this.setState({ validation: validation });
                break;
            case "street":
                if (value.length === 0) {
                    validation.streetError = true;
                    validation.streetErrorText = this.props.t("required_field");
                } else if (value.length > 255) {
                    validation.streetError = true;
                    validation.streetErrorText = this.props.t("ss_validation_message", { field: this.props.t("street") })
                } else {
                    validation.streetError = false;
                    validation.streetErrorText = "";
                }
                this.setState({ validation: validation });
                break;
            case "streetNo":
                if (value.length === 0) {
                    validation.streetNoError = true;
                    validation.streetNoErrorText = this.props.t("required_field");
                } else if (value.length > 255) {
                    validation.streetNoError = true;
                    validation.streetNoErrorText = this.props.t("ss_validation_message", { field: this.props.t("street_no") })
                } else {
                    validation.streetNoError = false;
                    validation.streetNoErrorText = "";
                }
                this.setState({ validation: validation });
                break;
            case "city":
                if (value.length === 0) {
                    validation.cityError = true;
                    validation.cityErrorText = this.props.t("required_field");
                } else if (value.length > 255) {
                    validation.cityError = true;
                    validation.cityErrorText = this.props.t("ss_validation_message", { field: this.props.t("city") })
                } else {
                    validation.cityError = false;
                    validation.cityErrorText = "";
                }
                this.setState({ validation: validation });
                break;
            case "username":
                if (value.length === 0) {
                    validation.usernameError = true;
                    validation.usernameErrorText = this.props.t("required_field");
                } else if (!regex.test(value)) {
                    validation.usernameError = true;
                    validation.usernameErrorText = this.props.t("validation_message", { field: this.props.t("username") })
                } else {
                    validation.usernameError = false;
                    validation.usernameErrorText = "";
                }
                this.setState({ validation: validation });
                break;
            case "password":
                if (!this.props.id && value.length === 0) {
                    validation.passwordError = true;
                    validation.passwordErrorText = this.props.t("required_field");
                } else if (value.length > 0 && !regex.test(value)) {
                    validation.passwordError = true;
                    validation.passwordErrorText = this.props.t("validation_message", { field: this.props.t("password") })
                } else {
                    validation.passwordError = false;
                    validation.passwordErrorText = "";
                }
                this.setState({ validation: validation });
                break;
            case "paymentCode":
                regex = /^[A-Z]+([0-9]+)?$/;
                if (value.length > 0 && value.length <= 255 && !regex.test(value)) {
                    validation.paymentCodeError = true;
                    validation.paymentCodeErrorText = this.props.t("payment_code_validation_message")
                } else if (value.length > 255) {
                    validation.paymentCodeError = true;
                    validation.paymentCodeErrorText = this.props.t("ss_validation_message", { field: this.props.t("payment_code") })
                } else {
                    validation.paymentCodeError = false;
                    validation.paymentCodeErrorText = "";
                }
                this.setState({ validation: validation });
                break;
            case "paymentDescription":
                if (value.length > 255) {
                    validation.paymentDescriptionError = true;
                    validation.paymentDescriptionErrorText = this.props.t("ss_validation_message", { field: this.props.t("payment_description") })
                } else {
                    validation.paymentDescriptionError = false;
                    validation.paymentDescriptionErrorText = "";
                }
                this.setState({ validation: validation });
                break;
            default:
                return;
        }
    }

    createOrUpdateMerchant = (e) => {
        e.preventDefault();
        const { data } = this.state;

        if (this.props.id && !data.password) {
            data.password = null;
        }

        if (data.paymentDescription) {
            data.paymentDescription = data.paymentDescription.trim();
        }

        if (data.exchangeFeeGroup === '-') {
            data.exchangeFeeGroup = null
        }

        if (data.timeZone === "") {
            data.timeZone = this.state.selectedTimeZone.value
        }

        let action = null;
        if (hasRole(this.props.user, ["ADMIN"])) {
            action = this.props.id ?
                this.props.updateMerchant(this.props.id, data) :
                this.props.createMerchant(data);
        }
        if (hasRole(this.props.user, ["MERCHANT"])) {
            action = this.props.id ?
                this.props.updatePOSUser(this.props.id, data) :
                this.props.createPOSUser(data);
        }
        action.then(() => {
            this.clear();
            this.props.onSave();
        });
    }

    onClose = () => {
        this.clear();
        this.props.onClose();
    }

    clear = () => {
        this.setState({
            data: JSON.parse(JSON.stringify(this.emptyData)),
            initialData: JSON.parse(JSON.stringify(this.emptyData)),
            id: null,
            validation: JSON.parse(JSON.stringify(this.emptyValidation)),
            imagePreviewUrl: '',
            file: null,
            validLogo: true,
            selectedTimeZone: this.defaultTimeZone
        });
    }

    disableButton = () => {
        const { initialData, data, validation } = this.state;

        if (JSON.stringify(initialData) === JSON.stringify(data)) {
            return true;
        }

        if (!this.props.id && !data.password) {
            return true;
        }

        if (hasRole(this.props.user, ["ADMIN"])) {

            if (data.paymentCode && (!data.paymentDescription || data.paymentDescription.trim().length === 0)) {
                return true;
            }

            if (!data.paymentCode) {
                data.paymentCode = null;
            }

            if (!data.paymentDescription) {
                data.paymentDescription = null;
            }

            if (validation.nameError ||
                validation.storeNameError ||
                validation.vatError ||
                validation.streetError ||
                validation.streetNoError ||
                validation.cityError ||
                validation.usernameError ||
                validation.passwordError ||
                validation.paymentCodeError ||
                validation.paymentDescriptionError ||
                validation.exchangeFeeGroupError) {
                return true;
            }

            if (data.name &&
                data.storeName &&
                data.vat &&
                data.street &&
                data.streetNo &&
                data.city &&
                data.username &&
                data.defaultCurrency) {
                return false;
            }

            return true;
        }
        if (hasRole(this.props.user, ["MERCHANT"])) {

            if (validation.nameError || validation.usernameError || validation.passwordError || validation.storeNameError) {
                return true;
            }

            if (data.name &&
                data.storeName &&
                data.username) {
                return false;
            }
            return true;
        }
    }

    handleImageChange = (e) => {
        let reader = new FileReader();
        let file = e.target.files[0];

        if (!file) {
            return;
        }

        if (file.size > 500000) {
            this.setState({ validLogo: false });
            return;
        }

        reader.onloadend = () => {

            let data = { ...this.state.data };
            data.logo = reader.result;

            this.setState({
                file: file,
                imagePreviewUrl: reader.result,
                data: data,
                validLogo: true
            });
        }
        reader.readAsDataURL(file)
    }

    clearLogo = () => {
        let { data } = this.state;
        data.logo = null;
        this.setState({
            file: null,
            imagePreviewUrl: "",
            data: data,
            validLogo: true
        });
    }

    setTimeZone = (timeZone) => {
        let { data } = this.state
        data.timeZone = timeZone.value
        this.setState({ selectedTimeZone: timeZone, data: data })
    }

    render() {
        const { data, validation, imagePreviewUrl, validLogo } = this.state;
        const { exchangeOrderSettingsGroups } = this.props.exchangeSettingsReducer;
        let groupList = []
        let systemObject = '-'
        exchangeOrderSettingsGroups.map(data => { return groupList.push(data) })
        groupList.push(systemObject)
        groupList.sort((a, b) => (a > b) ? 1 : -1)

        return (
            <Dialog fullWidth open={this.props.open} onClose={() => this.onClose()}>
                {hasRole(this.props.user, ["ADMIN"]) && <DialogTitle>{this.props.id ? this.props.t("edit_merchant") : this.props.t("create_merchant")}</DialogTitle>}
                {hasRole(this.props.user, ["MERCHANT"]) && <DialogTitle>{this.props.id ? this.props.t("edit_pos_user") : this.props.t("create_pos_user")}</DialogTitle>}
                <form onSubmit={this.createOrUpdateMerchant} autoComplete="off" spellCheck="false">
                    <DialogContent>
                        <Grid container direction="row" spacing={2}>
                            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Grid container direction="row" spacing={2}>
                                    {hasRole(this.props.user, ["ADMIN"]) && <Grid item container direction="column" xs={4} sm={4} md={4} lg={4} xl={4} alignItems="center" justifyContent="center" style={{ paddingTop: imagePreviewUrl ? '0px' : '16px' }}>
                                        {imagePreviewUrl && <ClearIcon style={{ position: 'relative', left: '80px', top: '20px', cursor: 'pointer' }} onClick={this.clearLogo} />}
                                        <input
                                            style={{ display: 'none' }}
                                            accept="image/*"
                                            id="contained-button-file"
                                            type="file"
                                            onChange={this.handleImageChange}
                                        />
                                        <label htmlFor="contained-button-file">
                                            {imagePreviewUrl ?
                                                <img
                                                    style={{ width: "144px", height: "auto", cursor: 'pointer' }}
                                                    src={imagePreviewUrl} alt="logo" />
                                                :
                                                <Fab
                                                    style={{ width: "144px", height: "144px" }}
                                                    component="span"
                                                    size="large"
                                                    color="primary">
                                                    <AddPhotoAlternateIcon style={{ fontSize: 60 }} />
                                                </Fab>
                                            }
                                        </label>
                                        {!validLogo && <span style={{ color: '#d32f2f', marginLeft: 14, marginRight: 14, marginTop: 4, fontSize: '0.75rem' }}>{this.props.t("invalid_logo_message")}</span>}
                                    </Grid>}
                                    <Grid item container direction="column"
                                        xs={hasRole(this.props.user, ["ADMIN"]) ? 8 : 12}
                                        sm={hasRole(this.props.user, ["ADMIN"]) ? 8 : 12}
                                        md={hasRole(this.props.user, ["ADMIN"]) ? 8 : 12}
                                        g={hasRole(this.props.user, ["ADMIN"]) ? 8 : 12}
                                        xl={hasRole(this.props.user, ["ADMIN"]) ? 8 : 12}>
                                        <Grid item>
                                            <TextField
                                                label={this.props.t("name")}
                                                id="name1"
                                                name="name"
                                                onChange={this.handleChange}
                                                value={data.name || ''}
                                                required
                                                helperText={validation.nameErrorText}
                                                error={validation.nameError}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                label={this.props.t("store_name")}
                                                id="storeName"
                                                name="storeName"
                                                onChange={this.handleChange}
                                                value={data.storeName || ''}
                                                required
                                                helperText={validation.storeNameErrorText}
                                                error={validation.storeNameError}
                                            />
                                        </Grid>
                                        {hasRole(this.props.user, ["ADMIN"]) &&
                                            <Grid item>
                                                <TextField
                                                    label={this.props.t("vat_id")}
                                                    id="vat"
                                                    name="vat"
                                                    onChange={this.handleChange}
                                                    value={data.vat || ''}
                                                    required
                                                    helperText={validation.vatErrorText}
                                                    error={validation.vatError}
                                                />
                                            </Grid>}
                                    </Grid>
                                </Grid>
                                {hasRole(this.props.user, ["ADMIN"]) && <>
                                    <Grid item>
                                        <TextField
                                            label={this.props.t("street")}
                                            id="street"
                                            name="street"
                                            onChange={this.handleChange}
                                            value={data.street || ''}
                                            required
                                            helperText={validation.streetErrorText}
                                            error={validation.streetError}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            label={this.props.t("street_no")}
                                            id="streetNo"
                                            name="streetNo"
                                            onChange={this.handleChange}
                                            value={data.streetNo || ''}
                                            required
                                            helperText={validation.streetNoErrorText}
                                            error={validation.streetNoError}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            label={this.props.t("city")}
                                            id="city"
                                            name="city"
                                            onChange={this.handleChange}
                                            value={data.city || ''}
                                            required
                                            helperText={validation.cityErrorText}
                                            error={validation.cityError}
                                        />
                                    </Grid>
                                </>}
                                <Grid item>
                                    <TextField
                                        label={this.props.t("username")}
                                        id="username1"
                                        name="username"
                                        onChange={this.handleChange}
                                        value={data.username || ''}
                                        required
                                        helperText={validation.usernameErrorText}
                                        error={validation.usernameError}
                                        inputProps={{
                                            autoComplete: 'new-password', // disable autocomplete and autofill
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        type="password"
                                        label={this.props.t("password")}
                                        id="password"
                                        name="password"
                                        onChange={this.handleChange}
                                        value={data.password || ''}
                                        required={this.props.id ? false : true}
                                        helperText={validation.passwordErrorText}
                                        error={validation.passwordError}
                                        inputProps={{
                                            autoComplete: 'new-password', // disable autocomplete and autofill
                                        }}
                                    />
                                </Grid>
                                {hasRole(this.props.user, ["ADMIN"]) && <Grid item container>
                                    <FormControl>
                                        <InputLabel id="defaultCurrency">{this.props.t("default_currency")} *</InputLabel>
                                        <Select
                                            required
                                            labelId="defaultCurrency"
                                            label="defaultCurrency"
                                            name="defaultCurrency"
                                            id="defaultCurrency"
                                            value={data.defaultCurrency || ''}
                                            onChange={this.handleChange}
                                        >
                                            <MenuItem value="HRK">HRK</MenuItem>
                                            <MenuItem value="EUR">EUR</MenuItem>
                                            <MenuItem value="USD">USD</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>}
                                {hasRole(this.props.user, ["ADMIN"]) && <Grid item>
                                    <FormControl>
                                        <InputLabel id="exchangeFeeGroup">{this.props.t("exchange_fee_group")}</InputLabel>
                                        <Select
                                            labelId="exchangeFeeGroup"
                                            label={this.props.t("exchange_fee_group")}
                                            name="exchangeFeeGroup"
                                            id="exchangeFeeGroup"
                                            value={data.exchangeFeeGroup || ''}
                                            onChange={this.handleChange}
                                        >
                                            {groupList && groupList.map(group => { return <MenuItem key={group} value={group}>{group}</MenuItem> })}
                                        </Select>
                                    </FormControl>
                                </Grid>}
                                {hasRole(this.props.user, ["ADMIN"]) && <Grid item>
                                    <FormControl>
                                        <TimezoneSelect
                                            styles={selectStyles}
                                            value={this.state.selectedTimeZone}
                                            onChange={this.setTimeZone}
                                            labelStyle="original"
                                            placeholder={this.props.t("select_timezone")}
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                        />
                                    </FormControl>
                                </Grid>}
                                {hasRole(this.props.user, ["ADMIN"]) && <Grid item container>
                                    <FormControlLabel
                                        label={this.props.t("return_change")}
                                        control={
                                            <Checkbox
                                                color="primary"
                                                name="returnChange"
                                                checked={data.returnChange}
                                                onChange={this.handleChange}
                                            />
                                        }
                                    />
                                </Grid>}
                                {hasRole(this.props.user, ["ADMIN"]) && <Grid item container>
                                    <FormControlLabel
                                        label={this.props.t("immediate_exchange")}
                                        control={
                                            <Checkbox
                                                color="primary"
                                                name="immediateExchange"
                                                checked={data.immediateExchange}
                                                onChange={this.handleChange}
                                            />
                                        }
                                    />
                                </Grid>}
                                {hasRole(this.props.user, ["ADMIN"]) && <Grid container direction="row" spacing={2} style={{ marginTop: 10 }} alignItems="center" justifyContent="center">
                                    <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12} >
                                        <Typography variant="body1" gutterBottom>
                                            <b>{this.props.t("public_payment_data")}</b>
                                        </Typography>
                                        <Divider />
                                    </Grid>
                                    <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Grid item>
                                            <TextField
                                                label={this.props.t("payment_code")}
                                                id="paymentCode"
                                                name="paymentCode"
                                                onChange={this.handleChange}
                                                value={data.paymentCode || ''}
                                                helperText={validation.paymentCodeErrorText}
                                                error={validation.paymentCodeError}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                disabled={!data.paymentCode || validation.paymentCodeError}
                                                label={this.props.t("payment_description")}
                                                id="paymentDescription"
                                                name="paymentDescription"
                                                onChange={this.handleChange}
                                                value={data.paymentDescription || ''}
                                                helperText={validation.paymentDescriptionErrorText}
                                                error={validation.paymentDescriptionError}
                                                multiline
                                                rows={3}
                                            />
                                        </Grid>
                                    </Grid>
                                    {data.paymentCode && !validation.paymentCodeError && (!data.paymentDescription || data.paymentDescription.trim().length === 0) && <span style={{ color: '#d32f2f', marginLeft: 14, marginRight: 14, marginTop: 4, fontSize: '0.75rem' }}>{this.props.t("public_payment_data_message")}</span>}
                                </Grid>}
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button color="inherit" onClick={() => this.onClose()} variant="contained">
                            {this.props.t("close")}
                        </Button>
                        <Button disabled={this.disableButton()} type="submit" variant="contained" color="primary">
                            {this.props.id ? this.props.t("update") : this.props.t("create")}
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.merchantReducer.user,
    exchangeSettingsReducer: state.exchangeSettingsReducer
});

const mapActionsToProps = { createMerchant, getMerchant, updateMerchant, createPOSUser, getPOSUser, updatePOSUser };

export default connect(mapStateToProps, mapActionsToProps)(withTranslation()(CreateOrUpdateMerchantDialog));
