import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getHealthChecks, getDaemonsStatus } from '../../redux/admin/actions';
import {
    Grid,
    TableCell,
    TableRow,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableBody,
    Typography,
    CircularProgress
} from '@mui/material';
import Lightbulb from '@mui/icons-material/Lightbulb';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

class HealthCheck extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        this.props.getHealthChecks();
        this.props.getDaemonsStatus();
    }

    render() {

        if (this.props.adminReducer.healthCheckLoading || this.props.adminReducer.daemonsStatusLoading) {
            return (
                <div className="loading-position">
                    <CircularProgress className="spinner" color="primary" />
                </div>
            )
        }

        let servicesList;
        let servicesNotFound;
        if (this.props.adminReducer.healthChecks && this.props.adminReducer.healthChecks.length > 0) {
            servicesList = this.props.adminReducer.healthChecks.map(service => {
                return <TableRow key={service.name}>
                    <TableCell>{service.name}</TableCell>
                    <TableCell>{service.version}</TableCell>
                    <TableCell>{service.status === "OK" ? <Lightbulb style={{ color: 'green' }} /> : <Lightbulb style={{ color: 'red' }} />}</TableCell>
                </TableRow>
            });
        } else {
            servicesNotFound = <TableRow>
                <TableCell align="center" colSpan={10}>
                    {this.props.t("records_not_found")}
                </TableCell>
            </TableRow>
        }

        let daemonsList;
        let daemonsNotFound;
        if (this.props.adminReducer.daemonsStatus && this.props.adminReducer.daemonsStatus.length > 0) {
            daemonsList = this.props.adminReducer.daemonsStatus.map(daemon => {
                return <TableRow key={daemon.asset}>
                    <TableCell>{daemon.asset}</TableCell>
                    <TableCell>{daemon.balance}</TableCell>
                    <TableCell>{daemon.daemonStatus.version && daemon.daemonStatus.version}</TableCell>
                    <TableCell>{daemon.daemonStatus.blockHeight && daemon.daemonStatus.blockHeight}</TableCell>
                    <TableCell>{daemon.daemonStatus.explorerBlockHeight && daemon.daemonStatus.explorerBlockHeight}</TableCell>
                    <TableCell>{daemon.daemonStatus.testnet && daemon.daemonStatus.testnet ? <CheckIcon style={{ color: 'green' }} /> : <ClearIcon style={{ color: 'red' }} />}</TableCell>
                    <TableCell>{daemon.daemonStatus.active && daemon.daemonStatus.active ? <CheckIcon style={{ color: 'green' }} /> : <ClearIcon style={{ color: 'red' }} />}</TableCell>
                    <TableCell>{daemon.daemonStatus.outOfSync && daemon.daemonStatus.outOfSync ? <ClearIcon style={{ color: 'red' }} /> : <CheckIcon style={{ color: 'green' }} />}</TableCell>
                </TableRow>
            });
        } else {
            daemonsNotFound = <TableRow>
                <TableCell align="center" colSpan={10}>
                    {this.props.t("records_not_found")}
                </TableCell>
            </TableRow>
        }

        return (
            <>
                <Grid container direction="row" spacing={2}>
                    <Grid item container direction="column" xs={8} sm={8} md={8} lg={8} xl={4}>
                        <Grid item container>
                            <Typography variant="h6" gutterBottom>
                                {this.props.t("health_check")}
                            </Typography>
                        </Grid>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell component="th" scope="row" width="40%" style={{ backgroundColor: "#155DA7", color: "white" }}>{this.props.t("service")}</TableCell>
                                        <TableCell component="th" scope="row" width="40%" style={{ backgroundColor: "#155DA7", color: "white" }}>{this.props.t("version")}</TableCell>
                                        <TableCell component="th" scope="row" width="20%" style={{ backgroundColor: "#155DA7", color: "white" }}>{this.props.t("status")}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {servicesList}
                                    {servicesNotFound}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
                <Grid container direction="row" spacing={2} style={{ marginTop: 20 }}>
                    <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={8}>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell component="th" scope="row" width="10%" style={{ backgroundColor: "#155DA7", color: "white" }}>{this.props.t("asset")}</TableCell>
                                        <TableCell component="th" scope="row" width="10%" style={{ backgroundColor: "#155DA7", color: "white" }}>{this.props.t("balance")}</TableCell>
                                        <TableCell component="th" scope="row" width="15%" style={{ backgroundColor: "#155DA7", color: "white" }}>{this.props.t("version")}</TableCell>
                                        <TableCell component="th" scope="row" width="10%" style={{ backgroundColor: "#155DA7", color: "white" }}>{this.props.t("block_height")}</TableCell>
                                        <TableCell component="th" scope="row" width="10%" style={{ backgroundColor: "#155DA7", color: "white" }}>{this.props.t("explorer_block_height")}</TableCell>
                                        <TableCell component="th" scope="row" width="7%" style={{ backgroundColor: "#155DA7", color: "white" }}>{this.props.t("testnet")}</TableCell>
                                        <TableCell component="th" scope="row" width="7%" style={{ backgroundColor: "#155DA7", color: "white" }}>{this.props.t("active")}</TableCell>
                                        <TableCell component="th" scope="row" width="7%" style={{ backgroundColor: "#155DA7", color: "white" }}>{this.props.t("sync")}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {daemonsList}
                                    {daemonsNotFound}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    adminReducer: state.adminReducer
})

const mapActionsToProps = { getHealthChecks, getDaemonsStatus }

export default connect(mapStateToProps, mapActionsToProps)(withTranslation()(HealthCheck))
