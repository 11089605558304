import types from '../admin/types';
import api from '../../api/adminApi';
import { snackActions } from '../snackbar/reducer';
import i18next from 'i18next';
import { SET_INIT_DATA } from '../payment/types';

export const createMerchant = (data) => async (dispatch) => {
  await api.createMerchant(data).then(response => {
    dispatch(snackActions.success(i18next.t("create_merchant_message")));
  }).catch(error => {
    if (error.response.data.code === 3001) {
      dispatch(snackActions.warning(i18next.t("username_exists")));
    } else if (error.response.data.code === 3002) {
      dispatch(snackActions.warning(i18next.t("name_exists")));
    } else if (error.response.data.code === 3012) {
      dispatch(snackActions.warning(i18next.t("payment_code_exists")));
    } else if (error.response.data.code === 3010) {
      dispatch(snackActions.warning(i18next.t("password_repeated_error")));
    } else if (error.response && error.response.data.message && !error.response.data.errors) {
      dispatch(snackActions.error(error.response.data.message));
    }
    else {
      dispatch(snackActions.warning(
        error.response.data.errors.map((item, index) => {
          return <li key={index}>{item}</li>
        })
      ));
    }
    return Promise.reject();
  });
}

export const getMerchants = (config = {}) => async (dispatch) => {
  dispatch({
    type: types.SET_MERCHANTS_LOADING
  });
  await api.getMerchants(config).then(response => {
    dispatch({
      type: types.SET_MERCHANTS,
      payload: response.data
    });
  }).catch(error => {
    dispatch({
      type: types.SET_MERCHANTS,
      payload: []
    });
    dispatch(snackActions.error(i18next.t("error_message")));
  });
  return Promise.resolve();
}

export const updateMerchantsPageSize = (value) => (dispatch) => {
  dispatch({
    type: types.SET_MERCHANTS_PAGE_SIZE,
    payload: Number(value)
  });
  return Promise.resolve();
};

export const getMerchant = (id, config = {}) => async (dispatch) => {
  dispatch({
    type: types.SET_MERCHANT_LOADING
  });
  return await api.getMerchant(id, config).then(response => {
    return response;
  }).catch(error => {
    dispatch(snackActions.error(i18next.t("error_message")));
  });
}

export const updateMerchant = (id, data) => async (dispatch) => {
  await api.updateMerchant(id, data).then(response => {
    dispatch(snackActions.success(i18next.t("update_merchant_message")));
  }).catch(error => {
    if (error.response.data.code === 3005) {
      dispatch(snackActions.warning(i18next.t("username_exists")));
    } else if (error.response.data.code === 3006) {
      dispatch(snackActions.warning(i18next.t("name_exists")));
    } else if (error.response.data.code === 3016) {
      dispatch(snackActions.warning(i18next.t("payment_code_exists")));
    } else if (error.response.data.code === 3010) {
      dispatch(snackActions.warning(i18next.t("password_repeated_error")));
    } else if (error.response && error.response.data.message && !error.response.data.errors) {
      dispatch(snackActions.error(error.response.data.message));
    }
    else {
      dispatch(snackActions.warning(
        error.response.data.errors.map((item, index) => {
          return <li key={index}>{item}</li>
        })
      ));
    }
    return Promise.reject();
  });
}

export const deleteMerchant = (id) => async (dispatch) => {
  await api.deleteMerchant(id).then(() => {
    dispatch(snackActions.success("Merchant deleted successfully"));
  }).catch(error => {
    if (error.response.data.code === 9003) {
      dispatch(snackActions.warning("Merchant cannot be deleted because it contains products"));
    } else {
      dispatch(snackActions.error(i18next.t("error_message")));
    }
  })
  return Promise.resolve();
}

export const getOrders = (config = {}) => async (dispatch) => {
  dispatch({
    type: types.SET_ORDERS_LOADING
  });
  await api.getOrders(config).then(response => {
    dispatch({
      type: types.SET_ORDERS,
      payload: response.data
    });
  }).catch(error => {
    dispatch({
      type: types.SET_ORDERS,
      payload: []
    });
    if (error.response.data.code !== 1000) {
      dispatch(snackActions.error(i18next.t("error_message")));
    }
  });
}

export const getMerchantOrders = (merchantId, config = {}) => async (dispatch) => {
  dispatch({
    type: types.SET_ORDERS_LOADING
  });
  await api.getMerchantOrders(merchantId, config).then(response => {
    dispatch({
      type: types.SET_ORDERS,
      payload: response.data
    });
  }).catch(error => {
    dispatch({
      type: types.SET_ORDERS,
      payload: []
    });
  });
}

export const updateOrdersPageSize = (value) => (dispatch) => {
  dispatch({
    type: types.SET_ORDERS_PAGE_SIZE,
    payload: Number(value)
  })
  return Promise.resolve();
};

export const getOrderHistory = (orderId, config = {}) => async (dispatch) => {
  dispatch({
    type: types.SET_ORDER_HISTORY_LOADING
  });
  await api.getOrderHistory(orderId, config).then(response => {
    dispatch({
      type: types.SET_ORDER_HISTORY,
      payload: response.data
    });
  }).catch(error => {
    dispatch({
      type: types.SET_ORDER_HISTORY,
      payload: []
    });
    dispatch(snackActions.error(i18next.t("error_message")));
  });
  return Promise.resolve();
}

export const updateOrderHistoryPageSize = (value) => (dispatch) => {
  dispatch({
    type: types.SET_ORDER_HISTORY_PAGE_SIZE,
    payload: Number(value)
  });
  return Promise.resolve();
};

export const getTransactions = (config = {}) => async (dispatch) => {
  dispatch({
    type: types.SET_TRANSACTIONS_LOADING
  });
  await api.getTransactions(config).then(response => {
    dispatch({
      type: types.SET_TRANSACTIONS,
      payload: response.data
    });
  }).catch(error => {
    dispatch({
      type: types.SET_TRANSACTIONS,
      payload: []
    });
    if (error.response.status !== 400) {
      dispatch(snackActions.error(i18next.t("error_message")));
    }
  });
}

export const updateTransactionsPageSize = (value) => (dispatch) => {
  dispatch({
    type: types.SET_TRANSACTIONS_PAGE_SIZE,
    payload: Number(value)
  });
  return Promise.resolve();
};

export const getMerchantTransactions = (merchantId, config = {}) => async (dispatch) => {
  dispatch({
    type: types.SET_TRANSACTIONS_LOADING
  });
  await api.getMerchantTransactions(merchantId, config).then(response => {
    dispatch({
      type: types.SET_TRANSACTIONS,
      payload: response.data
    });
  }).catch(error => {
    dispatch({
      type: types.SET_TRANSACTIONS,
      payload: []
    });
    if (error.response.status !== 400) {
      dispatch(snackActions.error(i18next.t("error_message")));
    }
  });
}

export const getRevenue = (config = {}) => async (dispatch) => {
  await api.getRevenue(config).then(response => {
    dispatch({
      type: types.SET_REVENUE,
      payload: response.data
    });
  }).catch(error => {
    dispatch({
      type: types.SET_REVENUE,
      payload: []
    });
    dispatch(snackActions.error(i18next.t("error_message")));
  });
}

export const getSystemConfigurationParameters = (config = {}) => async (dispatch) => {
  return await api.getSystemConfigurationParameters(config).then(response => {
    return response;
  }).catch(error => {
    dispatch({
      type: types.SET_SYSTEM_CONFIGURATION_PARAMETERS,
      payload: []
    });
    dispatch(snackActions.error(i18next.t("error_message")));
  });
}

export const getSystemConfigurationParameterByKey = (key, config = {}) => async (dispatch) => {
  await api.getSystemConfigurationParameterByKey(key, config).then(response => {
    dispatch({
      type: types.SYSTEM_CONFIGURATION_PARAMETER_BY_KEY,
      payload: response.data
    });
  }).catch(error => {
    dispatch({
      type: types.SYSTEM_CONFIGURATION_PARAMETER_BY_KEY,
      payload: []
    });
    dispatch(snackActions.error(i18next.t("error_message")));
  });
}

export const setSystemConfigurationParameter = (data) => async (dispatch) => {
  return await api.setSystemConfigurationParameter(data).then(response => {
    dispatch(snackActions.success("System configuration parameter set successfully"));
    return response;
  }).catch(error => {
    dispatch(snackActions.error(i18next.t("error_message")));
  });
}

export const getPOSMerchants = (config = {}) => async (dispatch) => {
  return await api.getPOSMerchants(config).then(response => {
    dispatch({
      type: types.SET_POS_MERCHANTS,
      payload: response.data
    });
    return response;
  }).catch(error => {
    dispatch({
      type: types.SET_POS_MERCHANTS,
      payload: []
    });
    dispatch(snackActions.error(i18next.t("error_message")));
  });
}

export const getMerchantReceiptData = (config = {}) => async (dispatch) => {
  await api.getMerchantReceiptData(config).then(response => {
    dispatch({
      type: types.SET_RECEIPT_DATA,
      payload: response.data
    });
  }).catch(error => {
    dispatch({
      type: types.SET_RECEIPT_DATA,
      payload: null
    });
    dispatch(snackActions.error(i18next.t("error_message")));
  });
}

export const getHealthChecks = (config = {}) => async (dispatch) => {
  dispatch({
    type: types.SET_HEALTH_CHECK_LOADING
  });
  await api.getHealthChecks(config).then(response => {
    dispatch({
      type: types.SET_HEALTH_CHECK,
      payload: response.data
    });
  }).catch(error => {
    dispatch({
      type: types.SET_HEALTH_CHECK,
      payload: []
    });
    dispatch(snackActions.error(i18next.t("error_message")));
  });
}

export const getPaymentData = (id, config = {}) => async (dispatch) => {
  return await api.getPaymentData(id, config).then(response => {
    dispatch({
      type: SET_INIT_DATA,
      payload: response.data
    });
  }).catch(error => {

    dispatch(snackActions.error(i18next.t("error_message")));
  });
}

export const getDaemonsStatus = (config = {}) => async (dispatch) => {
  dispatch({
    type: types.SET_DAEMONS_STATUS_LOADING
  });
  await api.getDaemonsStatus(config).then(response => {
    dispatch({
      type: types.SET_DAEMONS_STATUS,
      payload: response.data
    });
  }).catch(error => {
    dispatch({
      type: types.SET_DAEMONS_STATUS,
      payload: []
    });
    /* dispatch(snackActions.error(i18next.t("error_message"))); */
  });
}

export const getAssets = (user, config = {}) => async (dispatch) => {
  await api.getAssets(user, config).then(response => {
    dispatch({
      type: types.SET_ASSETS,
      payload: response.data
    });
  }).catch(error => {
    dispatch({
      type: types.SET_ASSETS,
      payload: []
    });
    dispatch(snackActions.error(i18next.t("error_message")));
  });
}

export const activateAsset = (asset, config) => async (dispatch) => {
  await api.activateAsset(asset, config).then(response => {
    if (config.params.activate) {
      dispatch(snackActions.success(i18next.t("asset_activation_message", { asset: asset })));
    } else {
      dispatch(snackActions.success(i18next.t("asset_deactivation_message", { asset: asset })));
    }
    dispatch(getAssets());
  }).catch(error => {
    dispatch(snackActions.error(i18next.t("error_message")));
  });
}

export const updateOrderStatus = (id, data) => async (dispatch) => {
  return await api.updateOrderStatus(id, data).then(response => {
    dispatch(snackActions.success(i18next.t("update_order_status_message_ok")));
  }).catch(error => {
    dispatch(snackActions.error(i18next.t("error_message")));
    return Promise.reject();
  });
}

export const getMerchantEmails = (config = {}) => async (dispatch) => {
  dispatch({
    type: types.SET_EMAILS_LOADING
  });
  return await api.getMerchantEmails(config).then(response => {
    dispatch({
      type: types.SET_EMAILS,
      payload: response.data
    });
    return response;
  }).catch(error => {
    dispatch({
      type: types.SET_EMAILS,
      payload: []
    });
    dispatch(snackActions.error(i18next.t("error_message")));
  });
}

export const createMerchantEmail = (data) => async (dispatch) => {
  return await api.createMerchantEmail(data).then(response => {
    dispatch(snackActions.success(i18next.t("create_email_message")));
    return response.status;
  }).catch(error => {
    if (error.response.data.code === 3108) {
      dispatch(snackActions.warning(i18next.t("email_already_exists")));
    } else {
      dispatch(snackActions.error(i18next.t("error_message")));
    }
    return error.response.status;
  });
}

export const updateMerchantEmail = (id, data) => async (dispatch) => {
  return await api.updateMerchantEmail(id, data).then(response => {
    dispatch(snackActions.success(i18next.t("update_email_message")));
    return response.status;
  }).catch(error => {
    if (error.response.data.code === 3109) {
      dispatch(snackActions.warning(i18next.t("email_already_exists")));
    } else {
      dispatch(snackActions.error(i18next.t("error_message")));
    }
    return error.response.status;
  });
}

export const deleteMerchantEmail = (id) => async (dispatch) => {
  return await api.deleteMerchantEmail(id).then(response => {
    dispatch(snackActions.success(i18next.t("delete_email_message")));
    return response.status;
  }).catch(error => {
    dispatch(snackActions.error(i18next.t("error_message")));
  });
}