import React, { Component } from "react";
import { Route, withRouter, matchPath, Switch, Redirect } from "react-router-dom";
import Login from "./views/Login";
import { connect } from 'react-redux';
import CssBaseline from '@mui/material/CssBaseline';
import Sidebar from './components/sidebar/Sidebar';
import Navbar from './components/navbar/Navbar';
import Content from './components/content/Content';
import { getSidebarItems } from './components/sidebar/SidebarUtil';
import { getProfile } from './redux/merchant/actions';
import POS from "./views/POS/POS";
import NotFound from "./components/common/NotFound";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false
    }
  }

  
  componentDidMount() {
    if (!matchPath(this.props.location.pathname, "/:merchantPaymentCode/:referenceNo/:currency/:amount/:orderId?")) {
      if (!this.props.merchantReducer.user) {
        this.props.getProfile();
      }
    }
  }

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen })
  };

  render() {
    return (
      <>
        {!this.props.merchantReducer.userLoading && <div className="root">
          <CssBaseline />
          {this.props.merchantReducer.user ? <>
            <Navbar handleDrawerToggle={this.handleDrawerToggle} />
            <Sidebar items={getSidebarItems()} handleDrawerToggle={this.handleDrawerToggle} mobileOpen={this.state.mobileOpen} />
            <Content />
          </> :
            <>
              <Switch>
                <Redirect from="/:url*(/+)" to={this.props.location.pathname.slice(0, -1)} />
                <Route exact path='/:merchantPaymentCode/:referenceNo/:currency/:amount/:orderId?' render={(props) => <POS {...props} />} />
                <Route path="/:merchantPaymentCode/:referenceNo/:currency/:amount/:orderId?/*" component={NotFound} />
                <Route exact path="/" render={(props) => <Login {...props} />}></Route>
              </Switch>
            </>
          }
        </div>}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  merchantReducer: state.merchantReducer
});

export default connect(mapStateToProps, { getProfile })(withRouter(App));
