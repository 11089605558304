import React from "react";
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getMerchants, updateMerchantsPageSize, updateMerchant, deleteMerchant } from '../../redux/admin/actions';
import { getExchangeOrderSettingGroups } from '../../redux/exchangeSettings/actions';
import {
  Grid,
  Button,
  TableCell,
  TableRow,
  Fab,
  Typography,
  Avatar
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import BookmarkBorder from '@mui/icons-material/BookmarkBorder';
import CreditScore from '@mui/icons-material/CreditScore';
/*import DeleteIcon from '@mui/icons-material/Delete';*/
import ArrowBack from '@mui/icons-material/ArrowBack';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import SecurityIcon from '@mui/icons-material/Security';
import ConfirmationDialog from '../../components/common/ConfirmationDialog';
import CreateOrUpdateMerchantDialog from './CreateOrUpdateMerchantDialog';
import Base58Table from "../../components/common/Base58Table";
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import NumberFormat from "react-number-format";

class Merchants extends React.Component {

  emptyMerchant = {
    id: null,
    name: '',
    username: '',
    password: '',
    defaultCurrency: ''
  }

  constructor(props) {
    super(props);
    this.state = {
      merchants: [],
      merchantToDelete: this.emptyMerchant,
      merchantToLock: this.emptyMerchant,
      openDialog: false,
      openLockDialog: false,
      openDeleteDialog: false,
      page: 0,
      params: {},
      openDisable2FADialog: false
    };
  }

  componentDidMount() {
    this.getMerchants(0);
    this.props.getExchangeOrderSettingGroups()
  }

  getMerchants = (page) => {
    let { params } = this.state;

    params.page = page;
    params.size = this.props.adminReducer.size;

    if (params.hasOwnProperty('status')) {
      if (params.status != null) {
        let status = null
        if (params.status === "true" || params.status === "ACTIVE") {
          status = "ACTIVE"
        } else {
          status = "DISABLED"
        }
        /* let status = params.status === "true" ? "ACTIVE" : "DISABLED"; */

        params.status = status;
      }
    }

    this.props.getMerchants({ params: params }).then(() => {
      if (page !== 0 && this.props.adminReducer.merchants.length === 0) {
        this.onPageChange(null, page - 1);
      }
    });
  }

  onPageChange = (event, page) => {
    this.setState({ page: page }, () => {
      this.getMerchants(page);
    })
  }

  onRowsPerPageChange = (event) => {
    this.props.updateMerchantsPageSize(event.target.value).then(() => {
      this.setState({ page: 0 });
      this.getMerchants(0);
    })
  }

  handleChangeFilter = (event) => {
    this.setState({ filter: { ...this.state.filter, [event.target.name]: event.target.value } });
  }

  render() {
    const { merchantToDelete, page } = this.state;
    const { merchants, totalElements, isLoading, size } = this.props.adminReducer;

    // if (isLoading) {
    //   return (
    //     <div className="loading-position">
    //       <CircularProgress className="spinner" color="primary" />
    //     </div>
    //   )
    // }

    let data;
    if (merchants && merchants.length > 0) {
      data = merchants.map((merchant, index) => {
        return <TableRow key={merchant.id}>
          <TableCell className="table-cell"><Avatar alt="" src={merchant.logo} /></TableCell>
          <TableCell className="table-cell">{merchant.name}</TableCell>
          <TableCell className="table-cell">{merchant.username}</TableCell>
          <TableCell className="table-cell">{merchant.storeName}</TableCell>
          <TableCell className="table-cell" align="right">
            {merchant.balances && merchant.balances.length > 0 && merchant.balances.map((balance, index) => {
              return <NumberFormat key={index} style={{ display: 'block' }} value={balance.currentBalance} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} suffix={" " + balance.currency} />
            })}

          </TableCell>
          <TableCell className="table-cell">{merchant.defaultCurrency}</TableCell>
          <TableCell className="table-cell">{merchant.returnChange ? <CheckIcon style={{ color: 'green' }} /> : <ClearIcon style={{ color: 'red' }} />}</TableCell>
          <TableCell className="table-cell">{merchant.immediateExchange ? <CheckIcon style={{ color: 'green' }} /> : <ClearIcon style={{ color: 'red' }} />}</TableCell>
          <TableCell className="table-cell">{merchant.status === 'ACTIVE' ? <CheckIcon style={{ color: 'green' }} /> : <ClearIcon style={{ color: 'red' }} />}</TableCell>
          <TableCell className="table-cell">{merchant.paymentCode ? <CheckIcon style={{ color: 'green' }} /> : <ClearIcon style={{ color: 'red' }} />}</TableCell>
          <TableCell className="table-cell">{merchant.exchangeFeeGroup}</TableCell>
          <TableCell className="table-cell">{moment.tz(merchant.created, merchant.timeZone).format('DD.MM.YYYY. HH:mm:ss')}</TableCell>
          <TableCell className="table-cell">{moment.tz(merchant.updated, merchant.timeZone).format('DD.MM.YYYY. HH:mm:ss')}</TableCell>
          <TableCell className="table-cell">{merchant.timeZone}</TableCell>
          <TableCell className="table-cell">
            <Fab className="table-fab" title={this.props.t("edit")} onClick={() => this.setState({ merchantToEdit: merchant.id, openDialog: true })} style={{ marginRight: 10 }} color="primary">
              <EditIcon className="table-fab-icon" />
            </Fab>
            <Fab className="table-fab" title={this.props.t("orders")} component={Link} to={{ pathname: `/orders/${merchant.id}`, state: { merchantName: merchant.name } }} style={{ marginRight: 10 }} color="primary">
              <BookmarkBorder className="table-fab-icon" />
            </Fab>
            <Fab className="table-fab" title={this.props.t("transactions")} component={Link} to={{ pathname: `/transactions/${merchant.id}`, state: { merchantName: merchant.name } }} style={{ marginRight: 10 }} color="primary">
              <CreditScore className="table-fab-icon" />
            </Fab>
            <Fab className="table-fab" title={merchant.status === "DISABLED" ? this.props.t("activate") : this.props.t("disable")}
              id={"merchants-tbl-actions-lock-" + index} style={{ marginRight: 10 }} color={merchant.status === "DISABLED" ? "inherit" : "secondary"} onClick={() => this.setState({ openLockDialog: true, merchantToLock: merchant })}>
              {merchant.status === "DISABLED" ? <LockIcon className="table-fab-icon" /> : <LockOpenIcon className="table-fab-icon" />}
            </Fab>
            {merchant.twoFA && <Fab className="table-fab" title={this.props.t("disable_2FA_dialog_title")} onClick={() => this.setState({ merchantToLock: merchant, openDisable2FADialog: true })} style={{ marginRight: 10 }} color="primary">
              <SecurityIcon className="table-fab-icon" />
            </Fab>}
            {/* <Fab className="table-fab" title={this.props.t("delete")} id={"merchants-tbl-actions-del-btn-" + index} color="inherit" aria-label="delete" onClick={() => this.setState({ openDeleteDialog: true, merchantToDelete: merchant })}>
              <DeleteIcon className="table-fab-icon" />
            </Fab> */}
          </TableCell>
        </TableRow>
      });
    } else {
      data = <TableRow>
        <TableCell align="center" colSpan={13}>
          {this.props.t("records_not_found")}
        </TableCell>
      </TableRow>
    }

    let columns = [
      {
        name: "logo",
        dbName: 'logo',
        width: '2%',
        sort: false,
        filter: false
      },
      {
        name: "name",
        dbName: 'name',
        width: '8%',
        sort: false,
        filter: true
      },
      {
        name: "username",
        dbName: 'username',
        width: '8%',
        sort: false,
        filter: true
      },
      {
        name: "store_name",
        dbName: 'storeName',
        width: '8%',
        sort: false,
        filter: true
      },
      {
        name: "balance_settlements",
        width: '6%'
      },
      {
        name: "default_currency",
        dbName: 'defaultCurrency',
        type: "select",
        items: ["EUR", "USD", "HRK"],
        width: '5%',
        sort: false,
        filter: true
      },
      {
        name: "return_change",
        dbName: 'returnChange',
        type: "boolean-select",
        width: '6%',
        sort: false,
        filter: true
      },
      {
        name: "immediate_exchange",
        dbName: 'immediateExchange',
        type: "boolean-select",
        width: '7%',
        sort: false,
        filter: true
      },
      {
        name: "active",
        dbName: 'status',
        type: "boolean-select",
        width: '5%',
        sort: false,
        filter: true
      },
      {
        name: "public_payment",
        dbName: 'publicPayment',
        type: "boolean-select",
        width: '5%',
        sort: false,
        filter: true
      },
      {
        name: "exchange_fee_group",
        dbName: 'exchangeFeeGroup',
        width: '8%',
        type: "select",
        items: this.props.exchangeSettingsReducer.exchangeOrderSettingsGroups,
        sort: false,
        filter: true
      },
      {
        name: "created",
        dbName: 'created',
        width: '8%',
        sort: true,
        filter: false
      },
      {
        name: "updated",
        dbName: 'updated',
        width: '8%',
        sort: true,
        filter: false
      },
      {
        name: "zone",
        width: '7%',
        sort: false,
        filter: false
      },
      {
        name: "actions",
        dbName: 'actions',
        width: '9%'
      }
    ];

    return (
      <Grid container direction="row" spacing={2}>
        <Grid item container>
          <Typography variant="h6" style={{ alignItems: 'center', display: 'flex' }}>
            <Fab
              className="table-fab"
              title={this.props.t("back")}
              size="small"
              color="primary"
              onClick={() => this.props.history.goBack()}>
              <ArrowBack />
            </Fab> <span style={{ marginLeft: 10 }}>{this.props.t("merchants")}</span>
          </Typography>
          <Button style={{ marginLeft: 'auto' }} onClick={() => this.setState({ merchantToEdit: null, openDialog: true })} color="primary">
            {this.props.t("create_merchant")}
          </Button>
        </Grid>
        <Grid item container>
          <Base58Table
            isLoading={isLoading}
            columns={columns}
            data={data}
            count={totalElements}
            rowsPerPage={size}
            page={page}
            onPageChange={this.onPageChange}
            onRowsPerPageChange={this.onRowsPerPageChange}
            onFilterChange={(params) => this.setState({ params: params, page: 0 }, () => this.getMerchants(0))}
          />
        </Grid>
        <CreateOrUpdateMerchantDialog
          open={this.state.openDialog}
          id={this.state.merchantToEdit}
          onClose={() => this.setState({ openDialog: false, merchantToEdit: null })}
          onSave={() => this.setState({ openDialog: false, merchantToEdit: null }, () => this.getMerchants(0))}
        />
        <ConfirmationDialog
          isOpen={this.state.openLockDialog}
          title={this.state.merchantToLock.status === "ACTIVE" ? this.props.t("disable_merchant") : this.props.t("activate_merchant")}
          message={this.props.t("disable_activate_merchant_message", { action: this.state.merchantToLock.status === "ACTIVE" ? this.props.t("disable_2") : this.props.t("activate_2"), merchant: this.state.merchantToLock.name })}
          onClose={() => this.setState({ openLockDialog: false })}
          onConfirm={() => this.props.updateMerchant(this.state.merchantToLock.id, { status: this.state.merchantToLock.status === "ACTIVE" ? "DISABLED" : "ACTIVE" }).then(() => {
            this.setState({ openLockDialog: false }, () => this.getMerchants(this.state.page));
          })}
          closeButtonTitle={this.props.t("close")}
          confirmButtonTitle={this.state.merchantToLock.status === "ACTIVE" ? this.props.t("disable") : this.props.t("activate")}
        />
        <ConfirmationDialog
          isOpen={this.state.openDeleteDialog}
          title={this.props.t("delete_merchant")}
          message={this.props.t("delete_merchant_message", { merchant: this.state.merchantToDelete.name })}
          onClose={() => this.setState({ openDeleteDialog: false })}
          onConfirm={() => this.props.deleteMerchant(merchantToDelete.id).then(() => {
            this.setState({ openDeleteDialog: false }, () => this.getMerchants(this.state.page));
          })}
          closeButtonTitle={this.props.t("close")}
          confirmButtonTitle={this.props.t("delete")}
        />
        <ConfirmationDialog
          isOpen={this.state.openDisable2FADialog}
          title={this.props.t("disable_2FA_dialog_title")}
          message={this.props.t("disable_2FA_dialog_message")}
          onClose={() => this.setState({ openDisable2FADialog: false })}
          onConfirm={() => this.props.updateMerchant(this.state.merchantToLock.id, { twoFA: false }).then(() => {
            this.setState({ openDisable2FADialog: false }, () => this.getMerchants(this.state.page));
          })}
          closeButtonTitle={this.props.t("no")}
          confirmButtonTitle={this.props.t("yes")}
        />
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  adminReducer: state.adminReducer,
  exchangeSettingsReducer: state.exchangeSettingsReducer
})

const mapActionsToProps = { getMerchants, updateMerchantsPageSize, updateMerchant, deleteMerchant, getExchangeOrderSettingGroups }

export default connect(mapStateToProps, mapActionsToProps)(withTranslation()(Merchants))
