import React from "react";
import { withTranslation } from 'react-i18next';
import {
    IconButton,
    Menu,
    MenuItem
} from "@mui/material";
import gb from '../../assets/gb.svg';
import hr from '../../assets/hr.svg';

const languages = ["gb", "hr"];

class LanguageMenu extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            languageAnchorEl: null
        }
    }

    handleLanguageMenu = (event) => {
        this.setState({ languageAnchorEl: event.currentTarget });
    };

    handleMenuClose = () => {
        this.setState({ languageAnchorEl: null });
    };

    render() {
        return (
            <div style={this.props.style}>
                <IconButton
                    size="large"
                    aria-label="languages"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={this.handleLanguageMenu}
                    color="inherit"
                >
                    <img
                        loading="lazy"
                        width="24"
                        height="18"
                        src={this.props.i18n.language === "gb" ? gb : hr}
                        alt="flag"
                        title={this.props.t(this.props.i18n.language)}
                    />
                </IconButton>
                <Menu
                    style={{ marginTop: '40px', marginLeft: '3.5px' }}
                    id="menu-appbar"
                    anchorEl={this.state.languageAnchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(this.state.languageAnchorEl)}
                    onClose={this.handleMenuClose}
                >
                    {languages.map(l => {
                        return <MenuItem
                            key={l}
                            onClick={() => {
                                this.props.i18n.changeLanguage(l);
                                this.handleMenuClose();
                            }}>
                            <img
                                loading="lazy"
                                width="24"
                                height="18"
                                src={l === "gb" ? gb : hr}
                                alt="flag"
                                title={this.props.t(l)}
                            />
                        </MenuItem>
                    })}
                </Menu>
            </div>
        );
    }
}

export default withTranslation()(LanguageMenu);

