import types from './types';

const initialState = {
    merchants: [],
    totalElements: 0,
    size: 10,
    isLoading: false,
    orders: [],
    orderHistory: [],

    transactionsLoading: false,
    transactions: [],
    transactionsSize: 10,
    transactionsTotalElements: 0,

    revenue: [],

    systemConfigurationParameters: [],

    posMerchants: [],

    receiptData: null,

    healthCheckLoading: false,
    healthChecks: [],

    daemonsStatusLoading: false,
    daemonsStatus: [],

    assets: [],

    emailsLoading: false,
    emails: []
}

export default function admin(state = initialState, action) {
    switch (action.type) {
        case types.SET_MERCHANTS_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case types.SET_MERCHANTS:
            return {
                ...state,
                merchants: action.payload.content,
                totalElements: action.payload.totalElements ? action.payload.totalElements : 0,
                isLoading: false
            }
        case types.SET_MERCHANTS_PAGE_SIZE:
            return {
                ...state,
                size: action.payload
            }
        case types.SET_ORDERS_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case types.SET_ORDERS:
            return {
                ...state,
                orders: action.payload.content ? action.payload.content : [],
                totalElements: action.payload.totalElements ? action.payload.totalElements : 0,
                isLoading: false
            }
        case types.SET_ORDERS_PAGE_SIZE:
            return {
                ...state,
                size: action.payload
            }
        case types.SET_ORDER_HISTORY_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case types.SET_ORDER_HISTORY:
            return {
                ...state,
                orderHistory: action.payload.content ? action.payload.content : [],
                isLoading: false
            }
        case types.SET_ORDER_HISTORY_PAGE_SIZE:
            return {
                ...state,
                size: action.payload
            }
        case types.SET_TRANSACTIONS_LOADING:
            return {
                ...state,
                transactionsLoading: true
            }
        case types.SET_TRANSACTIONS:
            return {
                ...state,
                transactions: action.payload.content ? action.payload.content : [],
                transactionsTotalElements: action.payload.totalElements ? action.payload.totalElements : 0,
                transactionsLoading: false
            }
        case types.SET_TRANSACTIONS_PAGE_SIZE:
            return {
                ...state,
                transactionsSize: action.payload
            }
        case types.SET_REVENUE:
            return {
                ...state,
                revenue: action.payload
            }
        case types.SET_SYSTEM_CONFIGURATION_PARAMETERS:
            return {
                ...state,
                systemConfigurationParameters: action.payload
            }
        case types.SET_POS_MERCHANTS:
            return {
                ...state,
                posMerchants: action.payload
            }
        case types.SET_RECEIPT_DATA:
            return {
                ...state,
                receiptData: action.payload
            }
        case types.SET_HEALTH_CHECK_LOADING:
            return {
                ...state,
                healthCheckLoading: true
            }
        case types.SET_HEALTH_CHECK:
            return {
                ...state,
                healthCheckLoading: false,
                healthChecks: action.payload.data
            }
        case types.SET_DAEMONS_STATUS_LOADING:
            return {
                ...state,
                daemonsStatusLoading: true
            }
        case types.SET_DAEMONS_STATUS:
            return {
                ...state,
                daemonsStatusLoading: false,
                daemonsStatus: action.payload.data
            }
        case types.SET_ASSETS:
            return {
                ...state,
                assets: action.payload
            }
        case types.SET_EMAILS_LOADING:
            return {
                ...state,
                emailsLoading: true
            }
        case types.SET_EMAILS:
            return {
                ...state,
                emails: action.payload ? action.payload : [],
                emailsLoading: false
            }
        default:
            return state;
    }
}