import { createTheme } from '@mui/material/styles';

let theme = createTheme({
    typography: {
        fontFamily: [
            'Poppins', 'sans-serif'
        ].join(',')
    },
    palette: {
        primary: {
            main: '#155DA7',
            contrastText: '#fff'
        },
        secondary: {
            main: '#E4E9F8',
            contrastText: '#000000'
        }
    },
});

theme = createTheme(theme, {
    components: {
        MuiButton: {
            defaultProps: {
                variant: 'contained',
                size: 'small'
            },
            styleOverrides: {
                colorInherit: {
                    backgroundColor: theme.palette.error.main,
                    color: theme.palette.primary.contrastText,
                    '&:hover': {
                        backgroundColor: "#a13815",
                        color: theme.palette.primary.contrastText,
                    }
                }
            },
        },
        MuiFilledInput: {
            defaultProps: {
                margin: 'dense',
            },
        },
        MuiFormControl: {
            defaultProps: {
                margin: 'dense',
                size: 'small',
                fullWidth: true
            },
        },
        MuiFormHelperText: {
            defaultProps: {
                margin: 'dense',
            },
        },
        MuiIconButton: {
            defaultProps: {
                size: 'small',
            },
        },
        MuiInputBase: {
            defaultProps: {
                margin: 'dense',
            },
        },
        MuiInputLabel: {
            defaultProps: {
                margin: 'dense',
            },
        },
        MuiOutlinedInput: {
            defaultProps: {
                size: 'small',
                margin: 'dense',
                variant: 'outlined'
            },
        },
        MuiFab: {
            defaultProps: {
                size: 'small',
            },
            styleOverrides: {
                colorInherit: {
                    backgroundColor: theme.palette.error.main,
                    color: theme.palette.primary.contrastText,
                    '&:hover': {
                        backgroundColor: "#a13815",
                        color: theme.palette.primary.contrastText,
                    }
                },
                root: {
                    "&.table-fab": {
                        maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'
                    }
                }
            },
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    "&.table-fab-icon": {
                        maxWidth: '20px', maxHeight: '20px', minWidth: '20px', minHeight: '20px'
                    }
                }
            },
        },
        MuiTable: {
            defaultProps: {
                size: 'small',
            },
        },
        MuiTextField: {
            defaultProps: {
                margin: 'dense',
                variant: 'outlined',
                size: 'small',
                fullWidth: true
            }
        },
        MuiTableCell: {
            styleOverrides: {
                head: {
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText
                }
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    "&.card-border": {
                        border: '1px solid black !important'
                    }
                }
            }
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText
                },
                subheader: {
                    color: theme.palette.primary.contrastText
                }
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText
                },
            },
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    padding: '20px !important'
                },
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    padding: '15px 25px 25px 25px',
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.secondary.main,
                    '&:hover': {
                        backgroundColor: theme.palette.secondary.main,
                        color: theme.palette.primary.contrastText,
                    },
                    '&.Mui-selected': {
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                    },
                }
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    "&.pos-menu": {
                        border: '1px solid black !important'
                    },
                    backgroundColor: 'none',
                    border: 'none !important'
                }
            }
        }
    },
});

export default theme;
