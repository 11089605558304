import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import {
    TableRow,
    Paper,
    TableContainer,
    Table,
    TableBody,
    TableFooter,
    TablePagination,
    LinearProgress,
    Box
} from '@mui/material';
import SortingAndFiltering from './SortingAndFiltering';
import FilterCriteria from './FilterCriteria';
import './Base58Table.css';

class Base58Table extends Component {

    constructor(props) {
        super(props);
        this.state = {
            filter: null,
            initialFilter: null,
            currentFilter: null,
            pagination: this.props.pagination != null ? this.props.pagination : true,
            map: null
        }
    }

    componentDidMount() {
        let filter = {};
        let map = {};
        this.props.columns.forEach(column => {
            if (column.filter) {
                filter[column.dbName] = null;
                map[column.dbName] = column.name;
            }
        });
        if (this.props.columns.some(column => column.sort)) {
            filter['sort'] = null;
            map['sort'] = null;
        }
        this.setState({ filter: filter, initialFilter: JSON.parse(JSON.stringify(filter)), map: map });
    }

    onFilterChange = (filter) => {
        const { map } = this.state;
        let currentFilter = {};
        Object.entries(filter).forEach(([key, value]) => {
            if (map[key]) {
                currentFilter[map[key]] = value;
            }
        });
        currentFilter['sort'] = filter.sort ? filter.sort : null;
        this.setState({ currentFilter: currentFilter }, () => this.props.onFilterChange(JSON.parse(JSON.stringify(filter))));
    }

    render() {

        const { filter, currentFilter, initialFilter, pagination } = this.state;

        return (
            <TableContainer component={Paper}>
                <Table>
                    {filter && <SortingAndFiltering columns={this.props.columns}
                        filter={filter}
                        currentFilter={currentFilter}
                        onSortChange={(filter) => this.setState({ filter: filter }, () => this.onFilterChange(filter))}
                        onFilterChange={(e, filter) => this.setState({ filter: filter }, () => e.type === 'click' && this.onFilterChange(filter))}
                        onFilterSearch={(filter) => this.setState({ filter: filter }, () => this.onFilterChange(filter))}
                        onFilterClear={(filter) => this.setState({ filter: filter }, () => this.onFilterChange(filter))}
                        placeholder={this.props.t("search...")}
                    />}
                    <TableBody>
                        {this.props.data}
                    </TableBody>
                    {pagination && <TableFooter>
                        <TableRow>
                            <FilterCriteria
                                colSpan={this.props.columns.length - 4}
                                currentFilter={currentFilter}
                                onFilterClear={() => this.setState({
                                    filter: initialFilter,
                                    currentFilter: initialFilter
                                }, () => this.onFilterChange(initialFilter))}
                                columns={this.props.columns}
                            />
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 15, 20]}
                                colSpan={4}
                                count={this.props.count}
                                rowsPerPage={this.props.rowsPerPage}
                                page={this.props.page}
                                onPageChange={this.props.onPageChange}
                                onRowsPerPageChange={this.props.onRowsPerPageChange}
                                labelRowsPerPage={this.props.t("rows_per_page")}
                                showFirstButton
                                showLastButton
                                backIconButtonProps={{
                                    'area-label': this.props.t("go_to_previous_page"),
                                    'title': this.props.t("go_to_previous_page")
                                }}
                                nextIconButtonProps={{
                                    'area-label': this.props.t("go_to_next_page"),
                                    'title': this.props.t("go_to_next_page")
                                }}
                            />
                        </TableRow>
                    </TableFooter>}
                </Table>
                {this.props.isLoading && <Box>
                    <LinearProgress />
                </Box>}
            </TableContainer>
        );
    }
}

export default (withTranslation()(Base58Table));
