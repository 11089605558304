import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  Grid,
  TextField,
  Box,
  Typography,
  Fab,
  TableRow,
  TableCell
} from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';
import moment from "moment";
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateAdapter from '@mui/lab/AdapterMoment';
import DesktopDateRangePicker from '@mui/lab/DesktopDateRangePicker';
import Base58Table from "../components/common/Base58Table";
import NumberFormat from "react-number-format";
import { hasRole } from "../security/Security";

class Revenue extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      dateRange: [null, null]
    };
  }

  componentDidMount() {
    const startOfMonth = moment().startOf('month');
    const endOfMonth = moment().endOf('month');
    this.setState({ dateRange: [startOfMonth, endOfMonth] });
    this.props.getRevenue({ params: { from: startOfMonth.format('YYYY-MM-DD'), to: endOfMonth.format('YYYY-MM-DD') } });
  }

  handleChange = (e) => {
    this.setState({ dateRange: e });
    if (moment(e[0], "YYYY-MM-DD").isValid() && moment(e[1], "YYYY-MM-DD").isValid()) {
      this.props.getRevenue({ params: { from: e[0] ? moment(e[0]).format("YYYY-MM-DD") : "1970-01-01", to: e[1] ? moment(e[1]).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD") } });
    }
  }

  sum = (revenue, column, currency) => {
    revenue = revenue.filter(r => r.currency === currency);
    let total = 0;
    revenue.forEach(item => {
      total += item[column];
    })
    return total;
  }

  render() {

    let data;
    if (this.props.reducer.revenue && this.props.reducer.revenue.length > 0) {
      data = <>{this.props.reducer.revenue.map((item, index) => {
        return <TableRow key={index}>
          <TableCell className="table-cell">{item.username}</TableCell>
          <TableCell align="right"><NumberFormat value={item.fiatPrice} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} /></TableCell>
          <TableCell className="table-cell">{item.currency}</TableCell>
          {hasRole(this.props.user, ["ADMIN"]) && <>
            <TableCell align="right"><NumberFormat value={item.payedCryptoAmount} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={8} fixedDecimalScale={true} /></TableCell>
            <TableCell className="table-cell">{item.cryptoAsset}</TableCell>
            <TableCell><NumberFormat value={item.merchantAmount} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={8} fixedDecimalScale={true} /></TableCell>
            <TableCell><NumberFormat value={item.returnedCryptoAmount} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={item.returnedCryptoAmount ? 6 : 0} fixedDecimalScale={true} /></TableCell>
            <TableCell><NumberFormat value={item.fee} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={6} fixedDecimalScale={true} /></TableCell>
          </>}
        </TableRow>
      })}
        {[...new Set(this.props.reducer.revenue.map(r => r.currency))].map(currency => {
          return this.props.reducer.revenue.some(r => r.currency === currency) && <TableRow key={currency}>
            <TableCell className="table-cell" style={{ backgroundColor: '#E4E9F8', fontWeight: 'bold' }}>{this.props.t("total_" + currency)}</TableCell>
            <TableCell align="right" style={{ backgroundColor: '#E4E9F8', fontWeight: 'bold' }}><NumberFormat value={this.sum(this.props.reducer.revenue, 'fiatPrice', currency)} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} /></TableCell>
            <TableCell className="table-cell" style={{ backgroundColor: '#E4E9F8', fontWeight: 'bold' }}>{currency}</TableCell>
            {hasRole(this.props.user, ["ADMIN"]) && <>
              <TableCell align="right" style={{ backgroundColor: '#E4E9F8', fontWeight: 'bold' }}><NumberFormat value={this.sum(this.props.reducer.revenue, 'payedCryptoAmount', currency)} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={8} fixedDecimalScale={true} /></TableCell>
              <TableCell className="table-cell" style={{ backgroundColor: '#E4E9F8', fontWeight: 'bold' }}>{this.props.reducer.revenue[0].cryptoAsset}</TableCell>
              <TableCell style={{ backgroundColor: '#E4E9F8', fontWeight: 'bold' }}><NumberFormat value={this.sum(this.props.reducer.revenue, 'merchantAmount', currency)} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={8} fixedDecimalScale={true} /></TableCell>
              <TableCell style={{ backgroundColor: '#E4E9F8', fontWeight: 'bold' }}><NumberFormat value={this.sum(this.props.reducer.revenue, 'returnedCryptoAmount', currency)} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={this.sum(this.props.reducer.revenue, 'returnedCryptoAmount', currency) ? 6 : 0} fixedDecimalScale={true} /></TableCell>
              <TableCell style={{ backgroundColor: '#E4E9F8', fontWeight: 'bold' }}><NumberFormat value={this.sum(this.props.reducer.revenue, 'fee', currency)} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={6} fixedDecimalScale={true} /></TableCell>
            </>}
          </TableRow>
        })}
      </>
    } else {
      data = <TableRow>
        <TableCell align="center" colSpan={hasRole(this.props.user, ["ADMIN"]) ? 8 : 3}>
          {this.props.t("records_not_found")}
        </TableCell>
      </TableRow>
    }

    let columns = [
      {
        name: "username",
        dbName: 'username',
        width: hasRole(this.props.user, ["ADMIN"]) ? '15%' : '40%'
      },
      {
        name: "price",
        dbName: 'fiatPrice',
        width: hasRole(this.props.user, ["ADMIN"]) ? '10%' : '30%'
      },
      {
        name: "currency",
        dbName: 'currency',
        width: hasRole(this.props.user, ["ADMIN"]) ? '10%' : '30%'
      }
    ];

    if (hasRole(this.props.user, ["ADMIN"])) {
      let adminColumns = [
        {
          name: "payed_crypto_amount",
          dbName: 'payedCryptoAmount',
          width: '10%'
        },
        {
          name: "asset",
          dbName: 'cryptoAsset',
          width: '10%'
        },
        {
          name: "merchant_amount",
          dbName: 'merchantAmount',
          width: '15%'
        },
        {
          name: "returned_crypto_amount",
          dbName: 'returnedCryptoAmount',
          width: '15%'
        },
        {
          name: "fee",
          dbName: 'fee',
          width: '15%'
        }
      ];
      columns = columns.concat(adminColumns);
    }

    return (
      <Grid container direction="row" spacing={2}>
        <Grid item container>
          <Typography variant="h6" style={{ alignItems: 'center', display: 'flex' }}>
            <Fab
              className="table-fab"
              title={this.props.t("back")}
              size="small"
              color="primary"
              onClick={() => this.props.history.goBack()}>
              <ArrowBack />
            </Fab> <span style={{ marginLeft: 10 }}>{this.props.t("revenue")}</span>
          </Typography>
        </Grid>
        <Grid item container>
          <LocalizationProvider dateAdapter={DateAdapter}>
            <DesktopDateRangePicker
              startText={this.props.t("start_date")}
              endText={this.props.t("end_date")}
              value={this.state.dateRange}
              onChange={this.handleChange}
              inputFormat="DD.MM.YYYY."
              mask="__.__.____."
              renderInput={(startProps, endProps) => (
                <React.Fragment>
                  <TextField {...startProps} className="date-range-picker" />
                  <Box sx={{ mx: 2 }} />
                  <TextField {...endProps} className="date-range-picker" />
                </React.Fragment>
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item container direction="column" xs={12} sm={12} md={12} lg={hasRole(this.props.user, ["ADMIN"]) ? 12 : 4} xl={hasRole(this.props.user, ["ADMIN"]) ? 12 : 4}>
          <Base58Table
            columns={columns}
            data={data}
            pagination={false}
          />
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.merchantReducer.user
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(withTranslation()(Revenue))
