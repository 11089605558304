import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import translationEN from './en/translations.json';
import translationHR from './hr/translations.json';

const resources = {
    gb: {
        translation: translationEN
    },
    hr: {
        translation: translationHR
    }
};

i18n.use(LanguageDetector).use(initReactI18next).init({
    debug: false,
    resources,
    lng: localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') : "gb",
    fallbackLng: "gb",
    keySeparator: false,
    interpolation: {
        escapeValue: false
    }
});

export default i18n;
