import types from '../merchant/types';
import api from '../../api/merchantApi';
import { snackActions } from '../../redux/snackbar/reducer';
import i18next from 'i18next';
import { SET_INIT_DATA } from '../payment/types';

export const login = (data) => async (dispatch) => {
  dispatch({
    type: types.SET_USER_LOADING
  });
  await api.login(data).then(response => {
    if (response) {
      dispatch(getProfile());
    }
  }).catch(() => {
    dispatch({
      type: types.SET_LOGIN_ERROR,
      payload: "Invalid Username or Password."
    });
  });
}

export const getProfile = () => async (dispatch) => {
  dispatch({
    type: types.SET_USER_LOADING
  });
  await api.getProfile().then(response => {
    dispatch({
      type: types.SET_USER,
      payload: response.data
    });
  }).catch(error => {
    dispatch({
      type: types.SET_USER,
      payload: null
    });
  });
}

export const logout = (data, history) => async (dispatch) => {
  await api.logout(data).then(response => {
    dispatch(getProfile());
  }).catch(error => {
    window.location.href = "/";
  });
}

export const getOrders = (config = {}) => async (dispatch) => {
  dispatch({
    type: types.SET_ORDERS_LOADING
  });
  await api.getOrders(config).then(response => {
    dispatch({
      type: types.SET_ORDERS,
      payload: response.data
    });
  }).catch(error => {
    dispatch({
      type: types.SET_ORDERS,
      payload: []
    });
    if (error.response.data.code === 1000) {
      dispatch(snackActions.warning(i18next.t("wrong_format")));
    } else {
      dispatch(snackActions.error(i18next.t("error_message")));
    }
  });
}

export const updateOrdersPageSize = (value) => (dispatch) => {
  dispatch({
    type: types.SET_ORDERS_PAGE_SIZE,
    payload: Number(value)
  })
  return Promise.resolve();
};

export const getOrderHistory = (orderId, config = {}) => async (dispatch) => {
  dispatch({
    type: types.SET_ORDER_HISTORY_LOADING
  });
  await api.getOrderHistory(orderId, config).then(response => {
    dispatch({
      type: types.SET_ORDER_HISTORY,
      payload: response.data
    });
  }).catch(error => {
    dispatch({
      type: types.SET_ORDER_HISTORY,
      payload: []
    });
    dispatch(snackActions.error(i18next.t("error_message")));
  });
  return Promise.resolve();
}

export const updateOrderHistoryPageSize = (value) => (dispatch) => {
  dispatch({
    type: types.SET_ORDER_HISTORY_PAGE_SIZE,
    payload: Number(value)
  });
  return Promise.resolve();
};

export const changePassword = (data) => async (dispatch) => {
  return await api.changePassword(data).then((response) => {
    dispatch(snackActions.success(i18next.t("change_password_message")));
    return response
  }).catch(error => {
    if (error.response && error.response.data.message && !error.response.data.errors) {
      dispatch(snackActions.error(error.response.data.message));
    }
    else {
      dispatch(snackActions.warning(
        error.response.data.errors.map((item, index) => {
          return <li key={index}>{item}</li>
        })
      ));
    }
    return error.response.data.code;
  });
}

export const changeNotificationPassword = (data) => async (dispatch) => {
  await api.notificationPassword(data).then(() => {
    dispatch(snackActions.success(i18next.t("change_notification_password_message")));
  }).catch(error => {
    if (error.response && error.response.data.message && !error.response.data.errors) {
      dispatch(snackActions.error(error.response.data.message));
    }
    else {
      dispatch(snackActions.warning(
        error.response.data.errors.map((item, index) => {
          return <li key={index}>{item}</li>
        })
      ));
    }
  });
  return Promise.resolve();
}

export const createToken = (data) => async (dispatch) => {
  await api.createToken(data).then(() => {
    dispatch(snackActions.success(i18next.t("create_token_message")));
    dispatch(getTokens());
  }).catch(error => {
    if (error.response.data.code === 4002) {
      dispatch(snackActions.warning(i18next.t("tokens_limit_reached")));
    } else {
      dispatch(snackActions.error(i18next.t("error_message")));
    }
  });
}

export const getTokens = (config = {}) => async (dispatch) => {
  dispatch({
    type: types.SET_TOKENS_LOADING
  });
  await api.getTokens(config).then(response => {
    dispatch({
      type: types.SET_TOKENS,
      payload: response.data
    });
  }).catch(error => {
    dispatch({
      type: types.SET_TOKENS,
      payload: []
    });
    dispatch(snackActions.error(i18next.t("error_message")));
  });
  return Promise.resolve();
}

export const updateTokensPageSize = (value) => (dispatch) => {
  dispatch({
    type: types.SET_TOKENS_PAGE_SIZE,
    payload: Number(value)
  });
  return Promise.resolve();
};

export const getToken = (id, config = {}) => async (dispatch) => {
  dispatch({
    type: types.SET_TOKEN_LOADING
  });
  return await api.getToken(id, config).then(response => {
    return response;
  }).catch(error => {
    dispatch(snackActions.error(i18next.t("error_message")));
  });
}

export const updateToken = (id, data) => async (dispatch) => {
  await api.updateToken(id, data).then(response => {
    dispatch(snackActions.success(i18next.t("update_token_message")));
  }).catch(error => {
    dispatch(snackActions.error(i18next.t("error_message")));
  });
}

export const deleteToken = (id) => async (dispatch) => {
  await api.deleteToken(id).then(() => {
    dispatch(snackActions.success(i18next.t("delete_token_message_ok")));
    dispatch(getTokens());
  }).catch(error => {
    dispatch(snackActions.error(i18next.t("error_message")));
  });
  return Promise.resolve();
}

export const getTransactions = (config = {}) => async (dispatch) => {
  dispatch({
    type: types.SET_TRANSACTIONS_LOADING
  });
  await api.getTransactions(config).then(response => {
    dispatch({
      type: types.SET_TRANSACTIONS,
      payload: response.data
    });
  }).catch(error => {
    dispatch({
      type: types.SET_TRANSACTIONS,
      payload: []
    });
    dispatch(snackActions.error(i18next.t("error_message")));
  });
}

export const updateTransactionsPageSize = (value) => (dispatch) => {
  dispatch({
    type: types.SET_TRANSACTIONS_PAGE_SIZE,
    payload: Number(value)
  });
  return Promise.resolve();
};

export const createPOSUser = (data) => async (dispatch) => {
  await api.createPOSUser(data).then(() => {
    dispatch(snackActions.success(i18next.t("create_pos_user_message")));
  }).catch(error => {
    if (error.response.data.code === 6001) {
      dispatch(snackActions.warning(i18next.t("username_exists")));
    } else if (error.response.data.code === 6002) {
      dispatch(snackActions.warning(i18next.t("name_exists")));
    } else if (error.response && error.response.data.message && !error.response.data.errors) {
      dispatch(snackActions.error(error.response.data.message));
    }
    else {
      dispatch(snackActions.warning(
        error.response.data.errors.map((item, index) => {
          return <li key={index}>{item}</li>
        })
      ));
    }
    return Promise.reject();
  });
}

export const getPOSUsers = (config = {}) => async (dispatch) => {
  dispatch({
    type: types.SET_POS_USERS_LOADING
  });
  await api.getPOSUsers(config).then(response => {
    dispatch({
      type: types.SET_POS_USERS,
      payload: response.data
    });
  }).catch(error => {
    dispatch({
      type: types.SET_POS_USERS,
      payload: []
    });
    dispatch(snackActions.error(i18next.t("error_message")));
  });
  return Promise.resolve();
}

export const updatePOSUsersPageSize = (value) => (dispatch) => {
  dispatch({
    type: types.SET_POS_USERS_PAGE_SIZE,
    payload: Number(value)
  });
  return Promise.resolve();
};

export const getPOSUser = (id, config = {}) => async (dispatch) => {
  // dispatch({
  //   type: types.SET_POS_USER_LOADING
  // });
  return await api.getPOSUser(id, config).then(response => {
    return response;
  }).catch(error => {
    dispatch(snackActions.error(i18next.t("error_message")));
  });
}

export const updatePOSUser = (id, data) => async (dispatch) => {
  await api.updatePOSUser(id, data).then(response => {
    dispatch(snackActions.success(i18next.t("update_pos_user_message")));
  }).catch(error => {
    if (error.response.data.code === 6001) {
      dispatch(snackActions.warning(i18next.t("username_exists")));
    } else if (error.response.data.code === 6002) {
      dispatch(snackActions.warning(i18next.t("name_exists")));
    } else if (error.response && error.response.data.message && !error.response.data.errors) {
      dispatch(snackActions.error(error.response.data.message));
    }
    else {
      dispatch(snackActions.warning(
        error.response.data.errors.map((item, index) => {
          return <li key={index}>{item}</li>
        })
      ));
    }
    return Promise.reject();
  });
}

export const getReceiptData = (config = {}) => async (dispatch) => {
  await api.getReceiptData(config).then(response => {
    dispatch({
      type: types.SET_RECEIPT_DATA,
      payload: response.data
    });
  }).catch(error => {
    dispatch({
      type: types.SET_RECEIPT_DATA,
      payload: null
    });
    dispatch(snackActions.error(i18next.t("error_message")));
  });
}

export const getRevenue = (config = {}) => async (dispatch) => {
  await api.getRevenue(config).then(response => {
    dispatch({
      type: types.SET_REVENUE,
      payload: response.data
    });
  }).catch(error => {
    dispatch({
      type: types.SET_REVENUE,
      payload: []
    });
    dispatch(snackActions.error(i18next.t("error_message")));
  });
}

export const getDailyRevenues = (config = {}) => async (dispatch) => {
  await api.getRevenue(config).then(response => {
    dispatch({
      type: types.SET_DAILY_REVENUES,
      payload: response.data
    });
  }).catch(error => {
    dispatch({
      type: types.SET_DAILY_REVENUES,
      payload: []
    });
    dispatch(snackActions.error(i18next.t("error_message")));
  });
}

export const setSignatureSecret = (data) => async (dispatch) => {
  await api.setSignatureSecret(data).then(() => {
    dispatch(snackActions.success(i18next.t("set_signature_secret_message")));
  }).catch(error => {
    dispatch(snackActions.error(i18next.t("error_message")));
  });
  return Promise.resolve();
}

export const get2FA = (config = {}) => async (dispatch) => {
  dispatch({
    type: types.SET_2FA_DATA_LOADING
  });
  await api.get2FA(config).then(response => {
    dispatch({
      type: types.SET_2FA_DATA,
      payload: response.data
    });
  }).catch(error => {
    dispatch({
      type: types.SET_2FA_DATA,
      payload: null
    });
    //dispatch(snackActions.error("Get 2FA Data Error"));
  });
}

export const enable2FA = (data) => async (dispatch) => {
  await api.enable2FA(data).then(() => {
    //dispatch(snackActions.success("2FA enabled successfully"));
  }).catch(error => {
    dispatch(snackActions.error(error.response.data && error.response.data.message));
  });
  return Promise.resolve();
}

export const disable2FA = (data) => async (dispatch) => {
  await api.disable2FA(data).then(() => {
    //dispatch(snackActions.success("2FA disabled successfully"));
  }).catch(error => {
    dispatch(snackActions.error(i18next.t("error_message")));
  });
  return Promise.resolve();
}

export const resetPaymentNonce = (data) => async (dispatch) => {
  await api.resetPaymentNonce(data).then(() => {
    dispatch(snackActions.success(i18next.t("reset_payment_nonce_message")));
  }).catch(error => {
    dispatch(snackActions.error(i18next.t("error_message")));
  });
  return Promise.resolve();
}

export const getSettlementOrders = (config = {}) => async (dispatch) => {
  return await api.getSettlementOrders(config).then(response => {
    dispatch({
      type: types.SET_SETTLEMENT_ORDERS,
      payload: response.data
    });
    return response;

  }).catch(error => {
    dispatch({
      type: types.SET_SETTLEMENT_ORDERS,
      payload: []
    });
    dispatch(snackActions.error(i18next.t("error_message")));
  });
}

export const updateSettlementOrdersPageSize = (value) => (dispatch) => {
  dispatch({
    type: types.SET_SETTLEMENT_ORDERS_PAGE_SIZE,
    payload: Number(value)
  })
  return Promise.resolve();
};

export const getPaymentData = (id, config = {}) => async (dispatch) => {
  return await api.getPaymentData(id, config).then(response => {
    dispatch({
      type: SET_INIT_DATA,
      payload: response.data
    });
  }).catch(error => {

    dispatch(snackActions.error(i18next.t("error_message")));
  });
}

export const getEmails = (config = {}) => async (dispatch) => {
  dispatch({
    type: types.SET_EMAILS_LOADING
  });
  return await api.getEmails(config).then(response => {
    dispatch({
      type: types.SET_EMAILS,
      payload: response.data
    });
    return response;
  }).catch(error => {
    dispatch({
      type: types.SET_EMAILS,
      payload: []
    });
    dispatch(snackActions.error(i18next.t("error_message")));
  });
}

export const createEmail = (data) => async (dispatch) => {
  return await api.createEmail(data).then(response => {
    dispatch(snackActions.success(i18next.t("create_email_message")));
    return response.status;
  }).catch(error => {
    if (error.response.data.code === 3108) {
      dispatch(snackActions.warning(i18next.t("email_already_exists")));
    } else {
      dispatch(snackActions.error(i18next.t("error_message")));
    }
    return error.response.status;
  });
}

export const updateEmail = (id, data) => async (dispatch) => {
  return await api.updateEmail(id, data).then(response => {
    dispatch(snackActions.success(i18next.t("update_email_message")));
    return response.status;
  }).catch(error => {
    if (error.response.data.code === 3109) {
      dispatch(snackActions.warning(i18next.t("email_already_exists")));
    } else {
      dispatch(snackActions.error(i18next.t("error_message")));
    }
    return error.response.status;
  });
}

export const deleteEmail = (id) => async (dispatch) => {
  return await api.deleteEmail(id).then(response => {
    dispatch(snackActions.success(i18next.t("delete_email_message")));
    return response.status;
  }).catch(error => {
    dispatch(snackActions.error(i18next.t("error_message")));
  });
}

export const getCurrentBalance = (config = {}) => async (dispatch) => {
  await api.getCurrentBalance(config).then(response => {
    dispatch({
      type: types.SET_CURRENT_BALANCE,
      payload: response.data
    });
  }).catch(error => {
    dispatch({
      type: types.SET_CURRENT_BALANCE,
      payload: null
    });
    dispatch(snackActions.error(i18next.t("error_message")));
  });
}