import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    InputAdornment,
    TextField
} from '@mui/material';

class NumberTextField extends Component {

    render() {
        return (
            <TextField
                type={this.props.type}
                id={this.props.id}
                name={this.props.name}
                label={this.props.label}
                onChange={(e) => this.props.onChange(e)}
                value={this.props.value || ''}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <strong>{this.props.currency ? this.props.currency : this.props.merchantReducer.user.defaultCurrency}</strong>
                        </InputAdornment>
                    )
                }}
                required={this.props.required}
            />
        );
    }
}

const mapStateToProps = (state) => ({
    merchantReducer: state.merchantReducer
})

export default connect(mapStateToProps, {})(NumberTextField)
