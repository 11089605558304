import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import {
    TableCell,
    TableRow,
    Fab,
    Button,
    Grid,
    Typography
} from '@mui/material';
import { connect } from 'react-redux';
import {
    getExchangeOrderSettings,
    updateExchangeOrderSettingsPageSize,
    activateExchangeOrderSetting,
    deactivateExchangeOrderSetting,
    deleteExchangeOrderSetting,
    getExchangeOrderSettingGroups
} from '../../redux/exchangeSettings/actions';
import { Delete, Check, Clear, Edit, ArrowBack, Lock, LockOpen } from '@mui/icons-material';
import CreateOrUpdateExchangeOrderSettingDialog from '../../views/exchangeSettings/CreateOrUpdateExchangeOrderSettingDialog';
import ConfirmationDialog from '../../components/common/ConfirmationDialog';
import Base58Table from "../../components/common/Base58Table";
import NumberFormat from 'react-number-format';

class ExchangeOrderSettings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            params: {},
            openModal: false,
            settingToEdit: null,
            openLockDialog: false,
            openDeleteModal: false
        };
    }

    async componentDidMount() {
        this.getExchangeOrderSettings(0);
        this.props.getExchangeOrderSettingGroups();
    }

    getExchangeOrderSettings = (page) => {
        let { params } = this.state;
        params.page = page;
        params.size = this.props.exchangeSettingsReducer.exchangeOrderSettingsSize;
        this.props.getExchangeOrderSettingGroups()
        this.props.getExchangeOrderSettings({ params: params });
    }

    onPageChange = (event, page) => {
        this.setState({ page: page }, () => {
            this.getExchangeOrderSettings(page);
        })
    }

    onRowsPerPageChange = (event) => {
        this.props.updateExchangeOrderSettingsPageSize(event.target.value).then(() => {
            this.setState({ page: 0 });
            this.getExchangeOrderSettings(0);
        })
    }

    render() {
        const { page } = this.state;
        const { isLoading, exchangeOrderSettings, exchangeOrderSettingsTotalElements, exchangeOrderSettingsSize, exchangeOrderSettingsGroups } = this.props.exchangeSettingsReducer;
        // if (isLoading) {
        //     return (
        //         <div className="loading-position">
        //             <CircularProgress className="spinner" color="primary" />
        //         </div>
        //     )
        // }

        let data;
        if (exchangeOrderSettings && exchangeOrderSettings.length > 0) {
            data = exchangeOrderSettings.map((exchangeOrderSetting, index) => {
                return <TableRow key={exchangeOrderSetting.id}>
                    <TableCell>{exchangeOrderSetting.name}</TableCell>
                    <TableCell>{exchangeOrderSetting.group}</TableCell>
                    <TableCell>{exchangeOrderSetting.exchange}</TableCell>
                    <TableCell>{exchangeOrderSetting.currencyPair}</TableCell>
                    <TableCell align="right"><NumberFormat value={exchangeOrderSetting.percentFee} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={" %"} /></TableCell>
                    <TableCell align="right"><NumberFormat value={exchangeOrderSetting.minAmount} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={" " + exchangeOrderSetting.currencyPair.split("/")[1]} /></TableCell>
                    <TableCell align="right"><NumberFormat value={exchangeOrderSetting.maxAmount} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={" " + exchangeOrderSetting.currencyPair.split("/")[1]} /></TableCell>
                    <TableCell>{exchangeOrderSetting.active ? <Check style={{ color: 'green' }} /> : <Clear style={{ color: 'red' }} />}</TableCell>
                    <TableCell>
                        <Fab title={this.props.t("edit")} className="table-fab" onClick={() => this.setState({ settingToEdit: exchangeOrderSetting.id, openModal: true })} style={{ marginRight: 10 }} color="primary" aria-label="edit">
                            <Edit className="table-fab-icon" />
                        </Fab>
                        <Fab className="table-fab" title={!exchangeOrderSetting.active ? this.props.t("activate") : this.props.t("disable")}
                            id={"exchange-order-settings-tbl-actions-lock-" + index} style={{ marginRight: 10 }}
                            color={!exchangeOrderSetting.active ? "inherit" : "secondary"}
                            onClick={() => this.setState({ openLockDialog: true, exchangeOrderSettingToLock: exchangeOrderSetting })}>
                            {!exchangeOrderSetting.active ? <Lock className="table-fab-icon" /> : <LockOpen className="table-fab-icon" />}
                        </Fab>
                        {!exchangeOrderSetting.active && <Fab title={this.props.t("delete")} className="table-fab" onClick={() => this.setState({ exchangeOrderSettingDelete: exchangeOrderSetting, openDeleteModal: true })} style={{ marginRight: 10 }} color="inherit" aria-label="delete">
                            <Delete className="table-fab-icon" />
                        </Fab>}
                    </TableCell>
                </TableRow>
            });
        } else {
            data = <TableRow>
                <TableCell align="center" colSpan={10}>
                    {this.props.t("records_not_found")}
                </TableCell>
            </TableRow>
        }

        let columns = [
            {
                name: "name",
                dbName: 'name',
                width: '16%',
                sort: false,
                filter: false
            },
            {
                name: "group",
                dbName: 'group',
                width: '10%',
                type: "select",
                items: exchangeOrderSettingsGroups,
                sort: false,
                filter: true
            },
            {
                name: "exchange",
                dbName: 'exchange',
                width: '14%',
                sort: false,
                filter: false
            },
            {
                name: "currency_pair",
                dbName: 'currencyPair',
                width: '10%',
                sort: false,
                filter: false
            },
            {
                name: "fee_percent",
                dbName: 'percentFee',
                width: '10%',
                sort: false,
                filter: false
            },
            {
                name: "min_amount",
                dbName: 'min_amount',
                width: '12%',
                sort: false,
                filter: false
            },
            {
                name: "max_amount",
                dbName: 'max_amount',
                width: '12%',
                sort: false,
                filter: false
            },
            {
                name: "active",
                width: '6%'
            },
            {
                name: "actions",
                width: '10%'
            }
        ];

        return (
            <Grid container direction="row" spacing={2}>
                <Grid item container>
                    <Typography variant="h6" style={{ alignItems: 'center', display: 'flex' }}>
                        <Fab
                            className="table-fab"
                            title={this.props.t("back")}
                            size="small"
                            color="primary"
                            onClick={() => this.props.history.goBack()}>
                            <ArrowBack />
                        </Fab> <span style={{ marginLeft: 10 }}>{this.props.t("exchange_order_settings")}</span>
                    </Typography>
                    <Button style={{ marginLeft: 'auto' }} onClick={() => this.setState({ openModal: true })} color="primary">
                        {this.props.t("new_exchange_order_setting")}
                    </Button>
                </Grid>
                <Grid item container>
                    <Base58Table
                        isLoading={isLoading}
                        columns={columns}
                        data={data}
                        count={exchangeOrderSettingsTotalElements}
                        rowsPerPage={exchangeOrderSettingsSize}
                        page={page}
                        onPageChange={this.onPageChange}
                        onRowsPerPageChange={this.onRowsPerPageChange}
                        onFilterChange={(params) => this.setState({ params: params }, () => this.getExchangeOrderSettings(0))}
                    />
                </Grid>
                <CreateOrUpdateExchangeOrderSettingDialog
                    open={this.state.openModal}
                    uuid={this.state.settingToEdit}
                    onClose={() => this.setState({ openModal: false, settingToEdit: null })}
                    onSave={() => this.setState({ openModal: false, settingToEdit: null }, () => this.getExchangeOrderSettings(0))}
                />
                {this.state.exchangeOrderSettingToLock && <ConfirmationDialog
                    isOpen={this.state.openLockDialog}
                    title={this.state.exchangeOrderSettingToLock.active ? this.props.t("disable_exchange_order_setting") : this.props.t("activate_exchange_order_setting")}
                    message={this.props.t("disable_activate_exchange_order_setting_message", { action: this.state.exchangeOrderSettingToLock.active ? this.props.t("disable_2") : this.props.t("activate_2"), exchangeOrderSetting: this.state.exchangeOrderSettingToLock.name })}
                    onClose={() => this.setState({ openLockDialog: false })}
                    onConfirm={() => {
                        let action = this.state.exchangeOrderSettingToLock.active ?
                            this.props.deactivateExchangeOrderSetting(this.state.exchangeOrderSettingToLock.id) :
                            this.props.activateExchangeOrderSetting(this.state.exchangeOrderSettingToLock.id);
                        action.then(() => {
                            this.setState({ openLockDialog: false }, () => this.getExchangeOrderSettings(this.state.page));
                        })
                    }}
                    confirmButtonTitle={this.state.exchangeOrderSettingToLock.active ? this.props.t("disable") : this.props.t("activate")}
                    closeButtonTitle={this.props.t("close")}
                />}
                {this.state.exchangeOrderSettingDelete && <ConfirmationDialog
                    isOpen={this.state.openDeleteModal}
                    title={this.props.t("delete_exchange_order_setting")}
                    message={this.props.t("delete_exchange_order_setting_message", { exchangeOrderSetting: this.state.exchangeOrderSettingDelete.name })}
                    onClose={() => this.setState({ openDeleteModal: false })}
                    onConfirm={() => {
                        this.props.deleteExchangeOrderSetting(this.state.exchangeOrderSettingDelete.id).then(() => {
                            this.setState({ openDeleteModal: false }, () => this.getExchangeOrderSettings(this.state.page));
                        })
                    }}
                    confirmButtonTitle={this.props.t("delete")}
                    closeButtonTitle={this.props.t("close")}
                />}
            </Grid>
        );
    }
}

const mapStateToProps = (state) => ({
    exchangeSettingsReducer: state.exchangeSettingsReducer
});

const mapActionsToProps = { getExchangeOrderSettings, updateExchangeOrderSettingsPageSize, activateExchangeOrderSetting, deactivateExchangeOrderSetting, deleteExchangeOrderSetting, getExchangeOrderSettingGroups };

export default connect(mapStateToProps, mapActionsToProps)(withTranslation()(ExchangeOrderSettings));
