export const SET_INIT_LOADING = 'SET_INIT_LOADING';
export const SET_INIT_DATA = 'SET_INIT_DATA';
export const SET_ERROR_MESSAGE_KYC = 'SET_ERROR_MESSAGE_KYC';
export const SET_POS_LIST = 'SET_POS_LIST';
export const SET_INCORRECT_PAYMENT_DATA = 'SET_INCORRECT_PAYMENT_DATA';
export const SET_MERCHANT_PUBLIC_PAYMENT_DATA = 'SET_MERCHANT_PUBLIC_PAYMENT_DATA';

const types = {
    SET_INIT_LOADING,
    SET_INIT_DATA,
    SET_ERROR_MESSAGE_KYC,
    SET_POS_LIST,
    SET_INCORRECT_PAYMENT_DATA,
    SET_MERCHANT_PUBLIC_PAYMENT_DATA
};

export default types;