export const CREATE_MERCHANT = 'CREATE_MERCHANT';
export const SET_MERCHANTS_LOADING = 'SET_MERCHANTS_LOADING';
export const SET_MERCHANTS = 'SET_MERCHANTS';
export const SET_MERCHANTS_PAGE_SIZE = 'SET_MERCHANTS_PAGE_SIZE';
export const SET_MERCHANT_LOADING = 'SET_MERCHANT_LOADING';

export const SET_ORDER_HISTORY_LOADING = 'SET_ORDER_HISTORY_LOADING';
export const SET_ORDER_HISTORY = 'SET_ORDER_HISTORY';
export const SET_ORDER_HISTORY_PAGE_SIZE = 'SET_MERCHANTS_PAGE_SIZE';

export const SET_ORDERS_LOADING = 'SET_ORDERS_LOADING';
export const SET_ORDERS = 'SET_ORDERS';
export const SET_ORDERS_PAGE_SIZE = 'SET_ORDERS_PAGE_SIZE';

export const SET_TRANSACTIONS_LOADING = 'SET_TRANSACTIONS_LOADING';
export const SET_TRANSACTIONS = 'SET_TRANSACTIONS';
export const SET_TRANSACTIONS_PAGE_SIZE = 'SET_TRANSACTIONS_PAGE_SIZE';

export const SET_REVENUE = 'SET_REVENUE';

export const SET_SYSTEM_CONFIGURATION_PARAMETERS = 'SET_SYSTEM_CONFIGURATION_PARAMETERS';
export const SYSTEM_CONFIGURATION_PARAMETER_BY_KEY = 'SYSTEM_CONFIGURATION_PARAMETER_BY_KEY';

export const SET_POS_MERCHANTS = 'SET_POS_MERCHANTS';

export const SET_RECEIPT_DATA = 'SET_RECEIPT_DATA';

export const SET_HEALTH_CHECK_LOADING = 'SET_HEALTH_CHECK_LOADING';
export const SET_HEALTH_CHECK = 'SET_HEALTH_CHECK';

export const SET_DAEMONS_STATUS_LOADING = 'SET_DAEMONS_STATUS_LOADING';
export const SET_DAEMONS_STATUS = 'SET_DAEMONS_STATUS';

export const SET_ASSETS = 'SET_ASSETS';

export const SET_EMAILS_LOADING = 'SET_EMAILS_LOADING';
export const SET_EMAILS = 'SET_EMAILS';

const types = {
    CREATE_MERCHANT,
    SET_MERCHANTS_LOADING,
    SET_MERCHANTS,
    SET_MERCHANTS_PAGE_SIZE,
    SET_MERCHANT_LOADING,
    SET_ORDERS_LOADING,
    SET_ORDERS,
    SET_ORDERS_PAGE_SIZE,
    SET_ORDER_HISTORY_LOADING,
    SET_ORDER_HISTORY,
    SET_ORDER_HISTORY_PAGE_SIZE,
    SET_TRANSACTIONS_LOADING,
    SET_TRANSACTIONS,
    SET_TRANSACTIONS_PAGE_SIZE,
    SET_REVENUE,
    SET_SYSTEM_CONFIGURATION_PARAMETERS,
    SYSTEM_CONFIGURATION_PARAMETER_BY_KEY,
    SET_POS_MERCHANTS,
    SET_RECEIPT_DATA,
    SET_HEALTH_CHECK_LOADING,
    SET_HEALTH_CHECK,
    SET_DAEMONS_STATUS_LOADING,
    SET_DAEMONS_STATUS,
    SET_ASSETS,
    SET_EMAILS_LOADING,
    SET_EMAILS
};

export default types;