import React from "react";
import { withTranslation } from 'react-i18next';
import moment from "moment";
import { connect } from 'react-redux';
import './POSReceipt.css';
import logo from '../../assets/bb-logo-greyscale.png';
import { getReceiptData } from '../../redux/merchant/actions';
import { getMerchantReceiptData } from '../../redux/admin/actions';
import {
  Grid
} from '@mui/material';
import { hasRole } from '../../security/Security';

class POSReceipt extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      merchantId: null
    };
  }

  componentDidMount() {
    if (hasRole(this.props.user, ["MERCHANT", "POS"])) {
      this.props.getReceiptData();
    }
  }

  componentDidUpdate(prevProps) {
    if (hasRole(this.props.user, ["ADMIN"]) && this.props.merchantId && prevProps.merchantId !== this.props.merchantId) {
      this.props.getMerchantReceiptData(this.props.merchantId);
    }
  }

  render() {

    let initData = this.props.initData;
    let receiptData = hasRole(this.props.user, ["ADMIN"]) ? this.props.merchantReceiptData : this.props.receiptData;
    let userData = this.props.user

    return (
      <>
        {initData && receiptData && <div className="receipt">
          <div className="name-and-vat">
            {receiptData.name} {this.props.t("vat_id")}:{receiptData.vat}
          </div>
          <div className="store-name">
            {receiptData.storeName}
          </div>
          <div className="address-and-city">
            {receiptData.street} {receiptData.streetNo}, {receiptData.city}
          </div>
          <Grid container direction="row" spacing={2}>
            <Grid item container direction="column" xs={6} sm={6} md={6} lg={6} xl={6}>
              <span className="reference-no">
                {this.props.t("reference_no")}:
              </span>
            </Grid>
            <Grid item container direction="column" xs={6} sm={6} md={6} lg={6} xl={6} style={{ textAlign: 'right' }}>
              <span className="reference-no">
                {initData.referenceNo}
              </span>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={2}>
            <Grid item container direction="column" xs={6} sm={6} md={6} lg={6} xl={6}>
              <span className="date">
                {this.props.t("date")}:{moment.tz(new Date(), hasRole(this.props.user, ["ADMIN"]) ? receiptData.timeZone : userData.timeZone).format('DD.MM.YYYY.')}
              </span>
            </Grid>
            <Grid item container direction="column" xs={6} sm={6} md={6} lg={6} xl={6} style={{ textAlign: 'right' }}>
              <span className="time">
                {this.props.t("time")}:{moment.tz(new Date(), hasRole(this.props.user, ["ADMIN"]) ? receiptData.timeZone : userData.timeZone).format('HH:mm:ss')}
              </span>
            </Grid>
          </Grid>

          <Grid container direction="row" spacing={2}>
            <Grid item container direction="column" xs={8} sm={8} md={8} lg={8} xl={8}>
              <span className="payment-method">
                {this.props.t("payment_method")}:
              </span>
            </Grid>
            <Grid item container direction="column" xs={4} sm={4} md={4} lg={4} xl={4} style={{ textAlign: 'right' }}>
              <span className="payment-method">
                {this.props.t("other")}
              </span>
            </Grid>
          </Grid>

          <Grid container direction="row" spacing={2}>
            <Grid item container direction="column" xs={6} sm={6} md={6} lg={6} xl={6}>
              <span className="employee">
                {this.props.t("employee")}:
              </span>
            </Grid>
            <Grid item container direction="column" xs={6} sm={6} md={6} lg={6} xl={6} style={{ textAlign: 'right' }}>
              <span className="employee">
                {this.props.user.username}
              </span>
            </Grid>
          </Grid>

          <div className="total">
            {this.props.t("total")}: {initData.fiatPrice} {this.props.user.defaultCurrency}
          </div>

          <div className="qr-code">
            <img alt="qrCode" width="150px" height="150px" src={"data:image/png;base64," + initData.qrCode} />
          </div>

          <div className="address">
            {initData.cryptoAddress}
          </div>

          <div className="quota-price">
            {initData.quotaPrice + " " + initData.cryptoAsset}
          </div>

          <div className="powered-by-wrapper">
            <span className="powered-by">{this.props.t("powered_by")}</span>
          </div>
          <div className="logo-wrapper">
            <img className="logo" src={logo} alt="" />
          </div>
          <div>
            _
          </div>
        </div>}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  initData: state.paymentReducer.initData,
  receiptData: state.merchantReducer.receiptData,
  merchantReceiptData: state.adminReducer.receiptData,
  user: state.merchantReducer.user
});

const mapActionsToProps = { getReceiptData, getMerchantReceiptData };

export default connect(mapStateToProps, mapActionsToProps)(withTranslation()(POSReceipt))
