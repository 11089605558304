import axios from '../axiosConfig';

export function settleOrder(data) {
    return axios.post('/api/v1/admin/settlement/settle', data);
}

export function createSettlementOrder(data) {
    return axios.post('/api/v1/admin/settlement/settlement-order', data);
}

export function getSettlementOrders(config = {}) {
    return axios.get(`/api/v1/admin/settlement/settlement-orders`, config);
}

export function getSettlementByOrderId(settlementOrderId, config = {}) {
    return axios.get(`/api/v1/admin/settlement/settlement-order/${settlementOrderId}`, config);
}

export function getSettlementByBankReferenceNo(bankReferenceNo, config = {}) {
    return axios.get(`/api/v1/admin/settlement/settlement-orders-by-bankReferenceNo/${bankReferenceNo}`, config);
}

export function getSettlementBySettlementReferenceNo(settlementReferenceNo, config = {}) {
    return axios.get(`/api/v1/admin/settlement/settlement-orders-by-settlementReferenceNo/${settlementReferenceNo}`, config);
}

export function updateSettlementOrder(settlementOrderId, data) {
    return axios.put(`/api/v1/admin/settlement/settlement-order/${settlementOrderId}`, data);
}

export function getNewSettlementOrders(config = {}) {
    return axios.get(`/api/v1/admin/settlement/new-settlement-orders`, config);
}

const actions = {
    settleOrder,
    createSettlementOrder,
    getSettlementOrders,
    getSettlementByOrderId,
    getSettlementByBankReferenceNo,
    getSettlementBySettlementReferenceNo,
    updateSettlementOrder,
    getNewSettlementOrders
}

export default actions;
