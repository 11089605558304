// https://material.io/resources/icons/?style=baseline

export const getSidebarItems = () => {
  return [
    {
      title: "revenue",
      icon: "monetization_on",
      link: "/revenue",
      roles: ["ADMIN", "MERCHANT"]
    },
    {
      title: "pos_users",
      icon: "supervised_user_circle",
      link: "/users",
      roles: ["MERCHANT"],
      divider: true
    },
    {
      title: "merchants",
      icon: "supervised_user_circle",
      link: "/merchants",
      roles: ["ADMIN"],
      divider: true
    },
    {
      title: "orders",
      icon: "bookmark_border",
      link: "/orders",
      roles: ["ADMIN", "MERCHANT", "POS"]
    },
    {
      title: "transactions",
      icon: "credit_score",
      link: "/transactions",
      roles: ["ADMIN"]
    },
    {
      title: "settlements",
      icon: "handshake",
      link: "/settlements",
      roles: ["ADMIN", "MERCHANT"],
      divider: true
    },
    {
      title: "settings",
      icon: "settings",
      link: "/settings",
      roles: ["ADMIN", "MERCHANT", "POS"]
    },
    {
      title: "system_settings",
      icon: "settings_suggest",
      link: "/system-settings",
      roles: ["ADMIN"]
    },
    {
      title: "exchange_settings",
      icon: "sync_alt",
      link: "/exchange-order-settings",
      roles: ["ADMIN"]
    },
    {
      title: "notifications",
      icon: "notifications",
      link: "/notifications",
      roles: ["ADMIN", "MERCHANT"],
      divider: true
    },
    {
      title: "health_check",
      icon: "health_and_safety_icon",
      link: "/health-check",
      roles: ["ADMIN"]
    },
    {
      title: "tokens",
      icon: "token",
      link: "/tokens",
      roles: ["MERCHANT"]
    },
    {
      title: "integration",
      icon: "integration_instructions",
      link: "/integration",
      roles: ["ADMIN", "MERCHANT"],
      divider: true
    },
    {
      title: "pos_reports",
      icon: "summarize",
      link: "/reports",
      roles: ["MERCHANT", "POS", "ADMIN"]
    },
    {
      title: "pos",
      icon: "point_of_sale",
      link: "/pos",
      roles: ["MERCHANT", "POS", "ADMIN"]
    }
  ];
}
