import types from './types';
import api from '../../api/settlementApi';
import merchantApi from '../../api/merchantApi';
import { snackActions } from '../snackbar/reducer';
import i18next from 'i18next';

export const createSettlementOrder = (data) => async (dispatch) => {
  await api.createSettlementOrder(data).then(response => {
    dispatch(snackActions.success(i18next.t("create_settlement_order_message")));
  }).catch(error => {
    if (error.response.data.code === 11004) {
      dispatch(snackActions.warning(i18next.t("create_settlement_warning_message")));
    } else {
      dispatch(snackActions.error(i18next.t("error_message")));
    }
    return Promise.reject();
  });
}

export const settleOrder = (data) => async (dispatch) => {
  return await api.settleOrder(data).then(response => {
    dispatch(snackActions.success(i18next.t("settle_order_message")));
  }).catch(error => {
    dispatch(snackActions.error(i18next.t("error_message")));
    return Promise.reject();
  });
}

export const getSettlementOrders = (config = {}, isAdmin = false) => async (dispatch) => {
  const action = isAdmin ? api.getSettlementOrders(config) : merchantApi.getSettlementOrders(config);
  return await action.then(response => {
    dispatch({
      type: types.SET_SETTLEMENT_ORDERS,
      payload: response.data
    });
    return response;

  }).catch(error => {
    dispatch({
      type: types.SET_SETTLEMENT_ORDERS,
      payload: []
    });
    dispatch(snackActions.error(i18next.t("error_message")));
  });
}

export const updateSettlementOrdersPageSize = (value) => (dispatch) => {
  dispatch({
    type: types.SET_SETTLEMENT_ORDERS_PAGE_SIZE,
    payload: Number(value)
  })
  return Promise.resolve();
};

export const updateSettlementOrder = (id, data) => async (dispatch) => {
  await api.updateSettlementOrder(id, data).then(response => {
    dispatch(snackActions.success(i18next.t("update_settlement_order_message")));
  }).catch(error => {
    dispatch(snackActions.error(i18next.t("error_message")));
    return Promise.reject();
  });
}

export const getNewSettlementOrders = (config = {}) => async (dispatch) => {
  return await api.getNewSettlementOrders(config).then(response => {
    dispatch({
      type: types.SET_NEW_SETTLEMENT_ORDERS,
      payload: response.data
    });
    return response;

  }).catch(error => {
    dispatch({
      type: types.SET_NEW_SETTLEMENT_ORDERS,
      payload: []
    });
    dispatch(snackActions.error(i18next.t("error_message")));
  });
}