import types from './types';
import api from '../../api/exchangeApi';
import { snackActions } from '../snackbar/reducer';

export const createExchangeOrderSetting = (data) => async (dispatch) => {
  return await api.createExchangeOrderSetting(data).then((response) => {
    dispatch(snackActions.success("Exchange order settings created successfully"));
    return response
  }).catch(error => {
    if (error.response.data.code === 19007) {
      dispatch(snackActions.error(error.response.data.message));
    } else if (error.response && error.response.data.message && !error.response.data.errors) {
      dispatch(snackActions.error(error.response.data.message));
    } else {
      dispatch(snackActions.error(
        error.response.data.errors.map((item, index) => {
          return <li key={index}>{item}</li>
        })
      ));
    }
    return error.response
  })
}

export const getExchangeOrderSettings = (config = {}) => async (dispatch) => {
  dispatch({
    type: types.SET_EXCHANGE_ORDER_SETTINGS_LOADING
  });
  await api.getExchangeOrderSettings(config).then(response => {
    dispatch({
      type: types.SET_EXCHANGE_ORDER_SETTINGS,
      payload: response.data
    });
  }).catch(() => {
    dispatch({
      type: types.SET_EXCHANGE_ORDER_SETTINGS,
      payload: []
    });
    dispatch(snackActions.error("Get Exchange Order Settings Error"));
  });
  return Promise.resolve();
}

export const updateExchangeOrderSettingsPageSize = (value) => (dispatch) => {
  dispatch({
    type: types.SET_EXCHANGE_ORDER_SETTINGS_PAGE_SIZE,
    payload: Number(value)
  });
  return Promise.resolve();
};

export const getExchangeOrderSetting = (id, config = {}) => async (dispatch) => {
  return await api.getExchangeOrderSetting(id, config).then(response => {
    return response;
  }).catch(() => {
    dispatch(snackActions.error("Get Exchange Order Setting Error"));
  });
}

export const updateExchangeOrderSetting = (id, data) => async (dispatch) => {
  return await api.updateExchangeOrderSetting(id, data).then(response => {
    dispatch(snackActions.success(response.data.info.message));
    return response
  }).catch(error => {
    if (error.response.data.code === 19007) {
      dispatch(snackActions.error(error.response.data.message));
    } else if (error.response && error.response.data.message && !error.response.data.errors) {
      dispatch(snackActions.error(error.response.data.message));
    }
    else {
      dispatch(snackActions.error(
        error.response.data.errors.map((item, index) => {
          return <li key={index}>{item}</li>
        })
      ));
    }
    return error.response
  })
}

export const getExchanges = (config = {}) => async (dispatch) => {
  await api.getExchanges(config).then(response => {
    dispatch({
      type: types.SET_EXCHANGES,
      payload: response.data
    });
  }).catch(() => {
    dispatch({
      type: types.SET_EXCHANGES,
      payload: []
    });
    dispatch(snackActions.error("Get Exchanges Error"));
  });
  return Promise.resolve();
}

export const getCurrencyPairs = (exchange, config = {}) => async (dispatch) => {
  await api.getCurrencyPairs(exchange, config).then(response => {
    dispatch({
      type: types.SET_CURRENCY_PAIRS,
      payload: response.data
    });
  }).catch(() => {
    dispatch({
      type: types.SET_CURRENCY_PAIRS,
      payload: []
    });
    dispatch(snackActions.error("Get Currency Pairs Error"));
  });
  return Promise.resolve();
}

export const clearCurrencyPairs = () => (dispatch) => {
  dispatch({
    type: types.SET_CURRENCY_PAIRS,
    payload: []
  });
  return Promise.resolve();
};

export const activateExchangeOrderSetting = (id) => async (dispatch) => {
  await api.activateExchangeOrderSetting(id).then(() => {
  }).catch(() => {
    dispatch(snackActions.error("Activate Exchange Order Setting Error"));
  });
  return Promise.resolve();
}

export const deactivateExchangeOrderSetting = (id) => async (dispatch) => {
  await api.deactivateExchangeOrderSetting(id).then(() => {
  }).catch(() => {
    dispatch(snackActions.error("Deactivate Exchange Order Setting Error"));
  });
  return Promise.resolve();
}


export const getExchangeOrderSettingGroups = (config = {}) => async (dispatch) => {
  await api.getExchangeOrderSettingGroups(config).then(response => {
    dispatch({
      type: types.SET_EXCHANGE_ORDER_SETTINGS_GROUPS,
      payload: response.data
    });
  }).catch(() => {
    dispatch({
      type: types.SET_EXCHANGE_ORDER_SETTINGS_GROUPS,
      payload: []
    });
    dispatch(snackActions.error("Get Exchange Order Settings Groups Error"));
  });
  return Promise.resolve();
}

export const deleteExchangeOrderSetting = (id) => async (dispatch) => {
  await api.deleteExchangeOrderSetting(id).then(() => {
    dispatch(snackActions.success("deleted_exchange_order_setting"))
  }).catch(() => {
    dispatch(snackActions.error("Error"));
  });
  return Promise.resolve();
}
