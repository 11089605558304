import axios from '../axiosConfig';

export function login(data) {
    return axios.post('/api/v1/merchant/auth/login', data);
}

export function logout(data) {
    return axios.post('/api/v1/merchant/auth/logout', data);
}

export function changePassword(data) {
    return axios.post('/api/v1/merchant/change-password', data);
}

export function notificationPassword(data) {
    return axios.post('/api/v1/merchant/notification-password', data);
}

export function getOrderHistory(orderId, config = {}) {
    return axios.get(`/api/v1/merchant/order-history/${orderId}`, config);
}

export function getOrders(config = {}) {
    return axios.get(`/api/v1/merchant/orders`, config);
}

export function getProfile(config = {}) {
    return axios.get(`/api/v1/merchant/profile`, config);
}

export function createToken(data) {
    return axios.post('/api/v1/merchant/tokens', data);
}

export function updateToken(id, data) {
    return axios.put(`/api/v1/merchant/tokens/${id}`, data);
}

export function deleteToken(id) {
    return axios.delete(`/api/v1/merchant/tokens/${id}`);
}

export function getTokens(config = {}) {
    return axios.get(`/api/v1/merchant/tokens`, config);
}

export function getToken(id, config = {}) {
    return axios.get(`/api/v1/merchant/tokens/${id}`, config);
}

export function getTransactions(config = {}) {
    return axios.get(`/api/v1/merchant/transactions`, config);
}

export function getOrderByOrderId(orderId, config = {}) {
    return axios.get(`/api/v1/merchant/order-by-orderId/${orderId}`, config);
}

export function getOrderByReferenceNo(referenceNo, config = {}) {
    return axios.get(`/api/v1/merchant/order-by-referenceNo/${referenceNo}`, config);
}

export function createPOSUser(data) {
    return axios.post('/api/v1/merchant/pos', data);
}

export function getPOSUsers(config = {}) {
    return axios.get(`/api/v1/merchant/pos`, config);
}

export function getPOSUser(id, config = {}) {
    return axios.get(`/api/v1/merchant/pos/${id}`, config);
}

export function updatePOSUser(id, data) {
    return axios.put(`/api/v1/merchant/pos/${id}`, data);
}

export function getReceiptData(config = {}) {
    return axios.get(`/api/v1/merchant/receipt-data`, config);
}

export function getRevenue(config = {}) {
    return axios.get(`/api/v1/merchant/revenue`, config);
}

export function setSignatureSecret(data) {
    return axios.post('/api/v1/merchant/signature-secret', data);
}

export function get2FA(config = {}) {
    return axios.get(`/api/v1/merchant/2fa`, config);
}

export function enable2FA(data) {
    return axios.post('/api/v1/merchant/2fa/enable', data);
}

export function disable2FA(data) {
    return axios.post('/api/v1/merchant/2fa/disable', data);
}

export function resetPaymentNonce(data) {
    return axios.post('/api/v1/merchant/reset-payment-nonce', data);
}

export function getSettlementOrders(config = {}) {
    return axios.get(`/api/v1/merchant/settlement/settlement-orders`, config);
}

export function getPaymentData(orderId, config = {}) {
    return axios.get(`api/v1/merchant/payments/data/${orderId}`, config);
}

export function getEmails(config = {}) {
    return axios.get(`/api/v1/merchant/emails`, config);
}

export function createEmail(data, config = {}) {
    return axios.post(`/api/v1/merchant/emails`, data, config);
}

export function updateEmail(id, data, config = {}) {
    return axios.put(`/api/v1/merchant/emails/${id}`, data, config);
}

export function deleteEmail(id, config = {}) {
    return axios.delete(`/api/v1/merchant/emails/${id}`, config);
}

export function getCurrentBalance(config = {}) {
    return axios.get(`/api/v1/merchant/current-balance`, config);
}

const actions = {
    login,
    logout,
    changePassword,
    notificationPassword,
    getOrderHistory,
    getOrders,
    getProfile,
    createToken,
    updateToken,
    deleteToken,
    getTokens,
    getToken,
    getTransactions,
    getOrderByOrderId,
    getOrderByReferenceNo,
    createPOSUser,
    getPOSUsers,
    getPOSUser,
    updatePOSUser,
    getReceiptData,
    getRevenue,
    setSignatureSecret,
    get2FA,
    enable2FA,
    disable2FA,
    resetPaymentNonce,
    getSettlementOrders,
    getPaymentData,
    getEmails,
    createEmail,
    updateEmail,
    deleteEmail,
    getCurrentBalance
}

export default actions;
