import React from "react";
import { withTranslation } from 'react-i18next';
import moment from "moment-timezone";
import { connect } from 'react-redux';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Fab,
  Typography,
  Collapse,
  Box,
  TableHead,
  Button,
  Tooltip,
  Checkbox,
  Alert
} from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';
import PointOfSale from '@mui/icons-material/PointOfSale';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { hasRole } from '../../security/Security';
import Base58Table from "../../components/common/Base58Table";
import NumberFormat from 'react-number-format';
import CreateSettlementDialog from './CreateSettlementDialog';

class Orders extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      params: { sort: 'created,desc' },
      collapseIndex: -1,
      settlementOrders: [],
      settlementDialogOpen: false,
      copyAlert: false
    };
  }

  componentDidMount() {
    this.getOrders(0);
  }

  getOrders = (page) => {
    let { params } = this.state;
    params.page = page;
    params.size = this.props.reducer.size;

    if (!params.sort) {
      params.sort = "created,desc";
    }

    this.setState({ page: page, collapseIndex: -1 },
      () => this.props.match.params.id ?
        this.props.getOrders(this.props.match.params.id, { params: params }) :
        this.props.getOrders({ params: params }));
  }

  onPageChange = (event, page) => {
    this.setState({ page: page }, () => {
      this.getOrders(page);
    })
  }

  onRowsPerPageChange = (event) => {
    this.props.updateOrdersPageSize(event.target.value).then(() => {
      this.setState({ page: 0 });
      this.getOrders(0);
    })
  }

  handleChange = (order) => {
    let { settlementOrders } = this.state;
    let so = settlementOrders.findIndex(so => so.orderId === order.orderId);
    if (so > -1) {
      settlementOrders.splice(so, 1);
    } else {
      settlementOrders.push(order);
    }
    this.setState({ settlementOrders: settlementOrders });
  }

  status = (status) => {
    switch (status) {
      case 'NEW':
        return <span style={{ color: 'blue', fontWeight: 'bold' }}>{this.props.t(status)}</span>;
      case 'PAID':
        return <span style={{ color: 'green', fontWeight: 'bold' }}>{this.props.t(status)}</span>;
      case 'CONFIRMED':
        return <span style={{ color: 'green', fontWeight: 'bold' }}>{this.props.t(status)}</span>;
      case 'SETTLEMENT_COMPLETED':
        return <span style={{ color: 'green', fontWeight: 'bold' }}>{this.props.t(status)}</span>;
      case 'IMMEDIATE_EXCHANGE':
        return <span style={{ color: 'green', fontWeight: 'bold' }}>{this.props.t(status)}</span>;
      case 'SELECTED_FOR_SETTLEMENT':
        return <span style={{ color: 'blue', fontWeight: 'bold' }}>{this.props.t(status)}</span>;
      default:
        return <span style={{ color: 'red', fontWeight: 'bold' }}>{this.props.t(status)}</span>;
    }
  }

  getSettlementStatus = (order, settlementOrders) => {
    if (order.status === "PAID" || order.status === "CONFIRMED" || order.status === "CONFIRMED_WITH_CHANGE") {
      if (order.settlementStatus === "UNSELECTED_FOR_SETTLEMENT") {
        return <Checkbox
          disabled={settlementOrders.length > 0 && !settlementOrders.some(so => so.currency === order.currency && so.merchantUsername === order.merchantUsername)}
          color="primary"
          name="so"
          checked={settlementOrders.some(so => so.orderId === order.orderId)}
          onChange={() => this.handleChange(order)}
        />
      } else {
        return this.status(order.settlementStatus)
      }
    }
  }

  copyToClipboard = () => {
    this.setState({ copyAlert: true });
    setTimeout(() => {
      this.setState({ copyAlert: false });
    }, 3000);
  }

  getPaymentData = (orderId, status) => {
    this.props.getPaymentData(orderId).then(() => {
      this.props.history.push(status.startsWith("UNDERPAID") ? "/pos/UNDERPAID" : "/pos/NEW");
    });
  }

  render() {
    const { page, settlementOrders, settlementDialogOpen, copyAlert } = this.state;
    let { orders, totalElements, size, isLoading } = this.props.reducer;

    let colSpan = hasRole(this.props.user, ["ADMIN"]) ? 16 : 15;

    let data;
    if (orders && orders.length > 0) {
      data = orders.map((order, index) => {
        return <React.Fragment key={order.orderId}>
          <TableRow>
            <TableCell style={{ fontWeight: index === this.state.collapseIndex ? 'bold' : 'unset' }} className="table-cell">
              {hasRole(this.props.user, ["ADMIN", "POS"]) && <CopyToClipboard text={order.orderId} onCopy={() => this.copyToClipboard()}>
                <Tooltip placement="right" interactive="true" disableFocusListener disableTouchListener arrow title={<div style={{ fontSize: '14px', maxWidth: '300px' }} >
                  <p>{order.orderId}</p>
                </div>}>
                  <span style={{ cursor: 'pointer', color: '#094b79' }}>{order.orderId.substring(0, 8) + "..."}</span>
                </Tooltip>
              </CopyToClipboard>}
              {hasRole(this.props.user, ["MERCHANT"]) && order.orderId}
            </TableCell>
            {hasRole(this.props.user, ["ADMIN", "POS"]) && <TableCell style={{ fontWeight: index === this.state.collapseIndex ? 'bold' : 'unset' }} className="table-cell">{order.merchantUsername}</TableCell>}
            {hasRole(this.props.user, ["ADMIN", "MERCHANT"]) && <TableCell style={{ fontWeight: index === this.state.collapseIndex ? 'bold' : 'unset' }} className="table-cell">{order.childMerchantUsername ? order.childMerchantUsername : order.merchantUsername}</TableCell>}
            {hasRole(this.props.user, ["ADMIN", "MERCHANT"]) && <TableCell style={{ fontWeight: index === this.state.collapseIndex ? 'bold' : 'unset' }} className="table-cell">{order.referenceNo}</TableCell>}
            <TableCell style={{ fontWeight: index === this.state.collapseIndex ? 'bold' : 'unset' }} className="table-cell" align="right"><NumberFormat value={order.fiatPrice} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} /></TableCell>
            <TableCell style={{ fontWeight: index === this.state.collapseIndex ? 'bold' : 'unset' }} className="table-cell" align="left">{order.currency}</TableCell>
            {hasRole(this.props.user, ["ADMIN"]) && <TableCell style={{ fontWeight: index === this.state.collapseIndex ? 'bold' : 'unset' }} className="table-cell" align="right"><NumberFormat value={order.quota} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={8} fixedDecimalScale={true} suffix={" " + order.cryptoAsset} /></TableCell>}
            {hasRole(this.props.user, ["ADMIN"]) && <TableCell style={{ fontWeight: index === this.state.collapseIndex ? 'bold' : 'unset' }} className="table-cell"><NumberFormat value={order.fee} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={6} fixedDecimalScale={true} /></TableCell>}
            <TableCell style={{ fontWeight: index === this.state.collapseIndex ? 'bold' : 'unset' }} className="table-cell">
              {this.status(order.status)}
            </TableCell>
            {hasRole(this.props.user, ["ADMIN"]) && <TableCell className="table-cell">
              <Button
                id={"order-history-list-" + index}
                onClick={() => this.setState({ collapseIndex: this.state.collapseIndex !== index ? index : -1 },
                  () => this.props.getOrderHistory(order.orderId))}>
                {index === this.state.collapseIndex ? this.props.t("collapse") : this.props.t("expand")}
              </Button>
            </TableCell>}
            {hasRole(this.props.user, ["ADMIN"]) && <TableCell align="center" style={{ fontWeight: index === this.state.collapseIndex ? 'bold' : 'unset' }} className="table-cell">
              {this.getSettlementStatus(order, settlementOrders)}
            </TableCell>}
            {hasRole(this.props.user, ["POS"]) && <TableCell style={{ fontWeight: index === this.state.collapseIndex ? 'bold' : 'unset' }} className="table-cell">{order.settlementReferenceNo}</TableCell>}
            {hasRole(this.props.user, ["POS"]) && <TableCell style={{ fontWeight: index === this.state.collapseIndex ? 'bold' : 'unset' }} className="table-cell">{order.bankReferenceNo}</TableCell>}
            <TableCell style={{ fontWeight: index === this.state.collapseIndex ? 'bold' : 'unset' }} className="table-cell"><span style={{ fontWeight: 'bold' }}>{order.settlementReferenceNo}</span></TableCell>
            <TableCell style={{ fontWeight: index === this.state.collapseIndex ? 'bold' : 'unset' }} className="table-cell">{moment.tz(order.created, order.timeZone).format('DD.MM.YYYY. HH:mm:ss')}</TableCell>
            <TableCell style={{ fontWeight: index === this.state.collapseIndex ? 'bold' : 'unset' }} className="table-cell">{moment.tz(order.updated, order.timeZone).format('DD.MM.YYYY. HH:mm:ss')}</TableCell>
            <TableCell style={{ fontWeight: index === this.state.collapseIndex ? 'bold' : 'unset' }} className="table-cell">
              {(order.status === "NEW" || order.status === "UNDERPAID") && <Fab title={this.props.t("go_to_pos")} className="table-fab" onClick={() => this.getPaymentData(order.orderId, order.status)} color="primary" aria-label="pos">
                <PointOfSale className="table-fab-icon" />
              </Fab>}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0, backgroundColor: '#E4E9F8' }} colSpan={colSpan}>
              <Collapse in={index === this.state.collapseIndex} timeout="auto" unmountOnExit style={{ paddingTop: 10, paddingBottom: 20 }}>
                <Box sx={{ margin: 1 }}>
                  <Typography variant="h6" gutterBottom>
                    {this.props.t("order_history")}
                  </Typography>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell component="th" scope="row" width="10%">{this.props.t("id")}</TableCell>
                          <TableCell component="th" scope="row" width="50%">{this.props.t("description")}</TableCell>
                          <TableCell component="th" scope="row" width="20%">{this.props.t("status")}</TableCell>
                          <TableCell component="th" scope="row" width="20%">{this.props.t("created")}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.props.reducer.orderHistory && this.props.reducer.orderHistory.length > 0 && this.props.reducer.orderHistory.map(item => {
                          return <TableRow key={item.id}>
                            <TableCell className="table-cell">{item.id}</TableCell>
                            <TableCell className="table-cell">{item.description}</TableCell>
                            <TableCell className="table-cell">{this.status(item.status)}</TableCell>
                            <TableCell className="table-cell">{moment.tz(item.created, item.timeZone).format('DD.MM.YYYY. HH:mm:ss')}</TableCell>
                          </TableRow>
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        </React.Fragment>
      });
    } else {
      data = <TableRow>
        <TableCell align="center" colSpan={colSpan}>
          {this.props.t("records_not_found")}
        </TableCell>
      </TableRow>
    }

    let columns = [];

    let id = { name: this.props.t("id"), dbName: 'orderId', width: '13%', sort: false, filter: true };
    const parentMerchant = { name: this.props.t("parent_merchant"), dbName: 'merchantUsername', width: '8%', sort: true, filter: true };
    const childMerchant = { name: this.props.t("child_merchant"), dbName: 'childMerchantUsername', width: '8%', sort: false, filter: true };
    const referenceNo = { name: this.props.t("reference_no"), dbName: 'referenceNo', width: '6%', sort: false, filter: true }

    if (hasRole(this.props.user, ["ADMIN", "POS"])) {
      id = { name: this.props.t("id"), dbName: 'orderId', width: '6%', sort: false, filter: true };
      columns.push(id);
      columns.push(parentMerchant);
      if (hasRole(this.props.user, ["ADMIN"])) {
        columns.push(childMerchant);
        columns.push(referenceNo)
      }
    }

    if (hasRole(this.props.user, ["MERCHANT"])) {
      columns.push(id);
      columns.push(childMerchant);
      columns.push(referenceNo)
    }

    let commonColumns = [
      {
        name: "amount",
        dbName: 'fiatPrice',
        width: '5%',
        sort: true,
        filter: false
      },
      {
        name: "currency",
        dbName: 'currency',
        width: '5%',
        type: "select",
        items: ["EUR", "USD", "HRK"],
        sort: false,
        filter: true
      }];

    columns = columns.concat(commonColumns);

    if (hasRole(this.props.user, ["ADMIN"])) {
      const cryptoAmount = {
        name: "crypto_amount",
        dbName: 'quota',
        width: '7%',
        sort: true,
        filter: false
      }
      columns.push(cryptoAmount);
    }


    if (hasRole(this.props.user, ["ADMIN"])) {
      const fee = {
        name: "fee",
        dbName: 'fee',
        width: '4%',
        sort: false,
        filter: false
      }
      columns.push(fee);
    }

    const commonColumns2 = [{
      name: "status",
      dbName: 'status',
      type: "select",
      items: ["NEW",
        "EXPIRED",
        "CHANGE_ADDRESS_DIRTY",
        "PAID",
        "CONFIRMED",
        "CONFIRMED_WITH_CHANGE",
        "COMPLETED",
        "FAILED",
        "UNDERPAID_RETURNED",
        "UNDERPAID_NO_RETURN",
        "UNDERPAID_CONFIRMED",
        "UNDERPAID",
        "TIMEOUT_RETURNED",
        "TIMEOUT_NO_RETURN",
        "TIMEOUT_CONFIRMED",
        "TIMEOUT",
        "ADDRESS_DIRTY",
        "DUPLICATE",
        "DUPLICATE_ADDRESS_DIRTY",
        "NOTIFY_FAILED",
        "NOTIFIED",
        "RETURN_MONEY_FAILED",
        "LOCK_INPUTS_FAILED",
        "IMMEDIATE_EXCHANGE",
        "IMMEDIATE_EXCHANGE_FAILED",
        "ADMIN_CHANGED_STATUS"],
      width: '6%',
      sort: false,
      filter: true
    }
    ];

    columns = columns.concat(commonColumns2);

    if (hasRole(this.props.user, ["ADMIN"])) {
      let orderHistory = {
        name: "order_history",
        width: '6%'
      }
      columns.push(orderHistory);
    }

    if (hasRole(this.props.user, ["ADMIN"])) {
      const checkForSettlement = {
        name: this.props.t("settlement_status"),
        dbName: "settlementStatus",
        type: "select",
        items: ["SELECTED_FOR_SETTLEMENT",
          "UNSELECTED_FOR_SETTLEMENT",
          "SETTLEMENT_COMPLETED"],
        width: '10%',
        filter: true
      };
      columns.push(checkForSettlement);
    }

    if (hasRole(this.props.user, ["POS"])) {
      const settlementReferenceNo = { name: "settlement_reference_no", width: '11%' };
      columns.push(settlementReferenceNo);
      const bankReferenceNo = { name: "bank_reference_no", width: '8%' };
      columns.push(bankReferenceNo);
    }

    let commonColumns3 = [
      {
        name: "settlement_reference_no",
        dbName: "settlementReferenceNo",
        width: '10%',
        filter: true
      },
      {
        name: "created",
        dbName: 'created',
        width: '8%',
        sort: true
      },
      {
        name: "updated",
        dbName: 'updated',
        width: '8%',
        sort: false
      },
      {
        name: "POS",
        width: '3%'
      }
    ];

    columns = columns.concat(commonColumns3);

    // let sum = 0;
    // columns.forEach(c => {
    //   sum += Number(c.width.split("%")[0])
    //   console.log("sum", sum)
    // })

    return (
      <Grid container direction="row" spacing={2}>
        <Grid item container>
          <Typography variant="h6" style={{ alignItems: 'center', display: 'flex' }}>
            <Fab
              className="table-fab"
              title={this.props.t("back")}
              size="small"
              color="primary"
              onClick={() => this.props.history.goBack()}>
              <ArrowBack />
            </Fab> <span style={{ marginLeft: 10 }}>{this.props.t("orders")} {this.props.location.state && " (" + this.props.location.state.merchantName + ")"}</span>
          </Typography>
          {settlementOrders.length > 0 && <Button style={{ marginLeft: 'auto' }} onClick={() => this.setState({ settlementDialogOpen: true })} color="primary">
            {this.props.t("create_settlement")}
          </Button>}
        </Grid>
        <Grid item container>
          <Base58Table
            isLoading={isLoading}
            columns={columns}
            data={data}
            count={totalElements}
            rowsPerPage={size}
            page={page}
            onPageChange={this.onPageChange}
            onRowsPerPageChange={this.onRowsPerPageChange}
            onFilterChange={(params) => this.setState({ params: params }, () => this.getOrders(0))}
          />
          {copyAlert && <Alert style={{ marginTop: '10px' }} severity="info">{this.props.t("copied_to_clipboard")}</Alert>}
        </Grid>
        <CreateSettlementDialog
          open={settlementDialogOpen}
          onClose={() => this.setState({ settlementDialogOpen: false })}
          onSave={() => this.setState({ settlementDialogOpen: false, settlementOrders: [] }, () => this.getOrders(0))}
          settlementOrders={settlementOrders}
          removeOrder={(order) => this.handleChange(order)} />
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.merchantReducer.user
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(withTranslation()(Orders))

