import React from "react";
import moment from "moment";
import { connect } from 'react-redux';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  CircularProgress,
  Fab,
  Typography,
  TableFooter,
  TablePagination
} from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';
import SortingAndFiltering from "../../components/common/SortingAndFiltering";
import FilterCriteria from "../../components/common/FilterCriteria";

class OrderHistory extends React.Component {

  filter = {
    status: null
  }

  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      filter: JSON.parse(JSON.stringify(this.filter)),
      currentFilter: null
    };
  }

  async componentDidMount() {
    this.getOrderHistory(0);
  }

  getOrderHistory = (page) => {
    const { filter } = this.state;

    this.setState({ currentFilter: filter },
      () => this.props.getOrderHistory(this.props.match.params.id, {
        params: {
          page: page,
          size: this.props.reducer.size,
          status: filter.status
        }
      }));
  }

  onPageChange = (event, page) => {
    this.setState({ page: page }, () => {
      this.getOrderHistory(page);
    })
  }

  onRowsPerPageChange = (event) => {
    this.props.updateOrderHistoryPageSize(event.target.value).then(() => {
      this.setState({ page: 0 });
      this.getOrderHistory(0);
    })
  }

  render() {
    const { page, filter, currentFilter } = this.state;
    const { orderHistory, totalElements, size, isLoading } = this.props.reducer;

    if (isLoading) {
      return (
        <div className="loading-position">
          <CircularProgress className="spinner" color="primary" />
        </div>
      )
    }

    let orderHistoryList;
    let orderHistoryNotFound;
    if (orderHistory && orderHistory.length > 0) {
      orderHistoryList = orderHistory.map((orderHistory, index) => {
        return <TableRow key={orderHistory.id}>
          <TableCell className="table-cell">{orderHistory.id}</TableCell>
          <TableCell className="table-cell">{orderHistory.description}</TableCell>
          <TableCell className="table-cell">{orderHistory.status}</TableCell>
          <TableCell className="table-cell">{moment(orderHistory.created).utc().format('DD.MM.YYYY. HH:mm:ss')}</TableCell>
        </TableRow>
      });
    } else {
      orderHistoryNotFound = <TableRow>
        <TableCell align="center" colSpan={5}>
          Order history not found.
        </TableCell>
      </TableRow>
    }

    return (
      <Grid container direction="row" spacing={2}>
        <Grid item container>
          <Typography variant="h6" style={{ alignItems: 'center', display: 'flex'}}>
            <Fab 
              className="table-fab"
              title={this.props.t("back")}
              size="small"
              color="primary"
              onClick={() => this.props.history.goBack()}>
              <ArrowBack />
            </Fab> <span style={{ marginLeft: 10 }}>Order History</span>
          </Typography>
        </Grid>
        <Grid item container>
          <TableContainer component={Paper}>
            <Table>
              <SortingAndFiltering columns={[
                {
                  name: 'ID',
                  dbName: 'orderId',
                  width: '10%',
                  sort: true,
                  filter: false
                },
                {
                  name: 'Description',
                  dbName: 'description',
                  width: '30%',
                  sort: false,
                  filter: false
                },
                {
                  name: 'Status',
                  dbName: 'status',
                  width: '30%',
                  sort: true,
                  filter: true
                },
                {
                  name: 'Created',
                  dbName: 'created',
                  width: '30%',
                  sort: true
                }
              ]}
                filter={filter}
                onSortChange={(sort) => this.setState({ filter: { ...this.state.filter, sort: sort }, page: 0 }, () => this.getOrderHistory(0))}
                onFilterChange={(e) => this.setState({ filter: { ...this.state.filter, [e.target.name]: (e.target.value != null && e.target.value.length > 0) ? e.target.value : null } }, () => e.type === 'click' && this.getOrderHistory(0))}
                onFilterSearch={() => this.getOrderHistory(0)}
                onFilterClear={(e) => this.setState({ filter: { ...this.state.filter, [e.currentTarget.id]: null } }, () => this.getOrderHistory(0))}
              />
              <TableBody>
                {orderHistoryList}
                {orderHistoryNotFound}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <FilterCriteria
                    colSpan={3}
                    currentFilter={currentFilter}
                    onFilterClear={() => this.setState({ filter: JSON.parse(JSON.stringify(this.filter)) }, () => this.getOrderHistory(0))}
                  />
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 15, 20]}
                    colSpan={2}
                    count={totalElements}
                    rowsPerPage={size}
                    page={page}
                    SelectProps={{
                      inputProps: { 'aria-label': 'rows per page' },
                      native: true,
                    }}
                    onPageChange={this.onPageChange}
                    onRowsPerPageChange={this.onRowsPerPageChange}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({

})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(OrderHistory)

