import types from './types';

const initialState = {
    isLoading: false,
    settlementOrders: [],
    totalElements: 0,
    size: 10,
    newSettlementOrders: []
}

export default function settlement(state = initialState, action) {
    switch (action.type) {
        case types.SET_SETTLEMENT_ORDERS_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case types.SET_SETTLEMENT_ORDERS:
            return {
                ...state,
                settlementOrders: action.payload.content ? action.payload.content : [],
                totalElements: action.payload.totalElements ? action.payload.totalElements : 0,
                isLoading: false
            }
        case types.SET_SETTLEMENT_ORDERS_PAGE_SIZE:
            return {
                ...state,
                size: action.payload
            }
        case types.SET_NEW_SETTLEMENT_ORDERS:
            return {
                ...state,
                newSettlementOrders: action.payload ? action.payload : []
            }
        default:
            return state;
    }
}