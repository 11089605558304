import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
axios.defaults.withCredentials = true;

axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error.response && (error.response.status === 401 || error.response.status === 403) && window.location.pathname !== "/") {
        window.location.href = "/";
    } else {
        return Promise.reject(error);
    }
});

export default axios;
