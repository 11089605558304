import React from "react";
import { withTranslation } from 'react-i18next';
import moment from "moment";
import { connect } from 'react-redux';
import { getPOSMerchants, getAssets } from '../../redux/admin/actions';
import { establishSseConnection, init, resetInitData, clearKYCErrorMessage, getPublicPaymentData, getMerchantPublicPaymentData, setIncorrectPaymentData } from '../../redux/payment/actions';
import { getPaymentPDF } from '../../redux/reports/actions';
import {
  Alert,
  Grid,
  Button,
  Fab,
  Typography,
  Card,
  CardContent,
  TextField,
  Paper,
  Backdrop,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';
import Add from '@mui/icons-material/Add';
import Print from '@mui/icons-material/Print';
import Clear from '@mui/icons-material/Clear';
import NumberFormat from 'react-number-format';
import Countdown, { zeroPad } from "react-countdown";
import NumberTextField from "../../components/common/NumberTextField";
import ReactToPrint from 'react-to-print';
import POSReceipt from "./POSReceipt";
import './POSReceipt.css';
import { hasRole } from '../../security/Security';
import SuccessIcon from "../../components/icons/SuccessIcon";
import ErrorIcon from "../../components/icons/ErrorIcon";
import ClearIcon from '@mui/icons-material/Clear';
import publicLogo from '../../assets/bb-logo.png';
import Carousel from 'react-material-ui-carousel';
import NotFound from "../../components/common/NotFound";
import LanguageMenu from "../../components/common/LanguageMenu";

class POS extends React.Component {

  static PAID = "PAID";
  static CONFIRMED = "CONFIRMED";
  static UNDERPAID = "UNDERPAID";
  static UNDERPAID_CONFIRMED = "UNDERPAID_CONFIRMED";
  static PAYMENT_IN_PROGRESS = "PAYMENT_IN_PROGRESS";
  static PAYMENT_TIME_HAS_EXPIRED = "PAYMENT_TIME_HAS_EXPIRED";
  static TIMEOUT = "TIMEOUT";
  static NEW = "NEW";

  emptyData = {
    cryptoAsset: 'BTC',
    currency: this.props.merchantReducer.user ? this.props.merchantReducer.user.defaultCurrency : 'EUR',
    fiatPrice: null,
    referenceNo: '',
    notificationUrl: null,
    merchantId: null,
    merchantPaymentCode: null,
    items: []
  }

  constructor(props) {
    super(props);
    this.state = {
      data: JSON.parse(JSON.stringify(this.emptyData)),
      selectedMerchant: null,
      selectedPrintOption: "POS"
    };
  }

  componentDidMount() {
    if (this.props.user) {
      this.props.establishSseConnection();
      this.props.clearKYCErrorMessage();
      if (hasRole(this.props.user, ["ADMIN"])) {
        this.props.getPOSMerchants();
      }
    } else {
      this.props.getMerchantPublicPaymentData(this.props.match.params.merchantPaymentCode);
      if (isNaN(+this.props.match.params.amount) || !this.validCurrency(this.props.match.params.currency)) {
        this.props.setIncorrectPaymentData();
      }
      let { data } = this.state;
      data.merchantPaymentCode = this.props.match.params.merchantPaymentCode;
      data.referenceNo = this.props.match.params.referenceNo;
      data.fiatPrice = Number(this.props.match.params.amount);
      data.currency = this.props.match.params.currency;
      this.setState({ data: data });
      if (this.props.match.params.orderId) {
        this.props.establishSseConnection(`${process.env.REACT_APP_BASE_URL}/api/v1/payments/public/sse/${this.props.match.params.orderId}`);
        this.props.getPublicPaymentData(this.props.match.params.orderId);
      }
      this.setLanguage();
    }
    this.props.getAssets(this.props.user);
  }

  setLanguage = () => {
    this.props.i18n.changeLanguage("hr");
  }

  validCurrency = (currency) => {
    switch (currency) {
      case "HRK":
        return true;
      case "EUR":
        return true;
      case "USD":
        return true;
      default:
        return false;
    }
  }

  handleChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    let { data } = this.state;
    data[name] = value;
    this.setState({ data: data });
  }

  handleChangeMerchant = (event) => {
    const target = event.target;
    const value = target.value;
    let { data } = this.state;
    data.merchantId = value.id;
    data.currency = value.defaultCurrency;
    this.setState({ data: data, selectedMerchant: value });
  }

  handleChangePrice = (values) => {
    const { floatValue } = values;
    this.setState({ data: { ...this.state.data, fiatPrice: floatValue } });
  }

  init = (event) => {
    event.preventDefault();
    let { data } = this.state;
    if (!data.referenceNo) {
      data.referenceNo = moment().unix();
    }
    this.props.init(data, this.props.user, data.merchantId, data.currency).then(response => {
      if (!this.props.user && response && response.data) {
        this.props.establishSseConnection(`${process.env.REACT_APP_BASE_URL}/api/v1/payments/public/sse/${response.data.orderId}`);
        this.props.history.replace(`${this.props.location.pathname}/${response.data.orderId}`);
      }
    });
  }

  refresh = () => {
    this.props.resetInitData();
    this.setState({ data: JSON.parse(JSON.stringify(this.emptyData)), selectedMerchant: null });
    this.props.history.push("/pos");
  }

  refreshPos = () => {
    this.props.paymentReducer.incorrectPaymentData = false
    this.props.resetInitData();
    this.setState({ data: JSON.parse(JSON.stringify(this.emptyData)), selectedMerchant: null });
  }

  handleChangeAsset = (index) => {
    const { assets } = this.props.adminReducer;
    if (assets[index] && assets[index].active) {
      this.setState({ data: { ...this.state.data, cryptoAsset: assets[index].shortName } });
    } else {
      this.setState({ data: { ...this.state.data, cryptoAsset: null } });
    }
  }

  addItem = (e) => {
    e.stopPropagation();
    const { data } = this.state;
    const item = {
      item: '',
      quantity: null,
      price: null
    };
    data.items.push(item);
    this.setState({ data: data });
  }

  handleChangeItemName = (e, index) => {
    let { data } = this.state;
    data.items[index].item = e.target.value;
    this.setState({ data: data });
  }

  handleChangeItemQuantity = (values, index) => {
    const { floatValue } = values;
    let { data } = this.state;
    data.items[index].quantity = floatValue;
    this.setState({ data: data });
  }

  handleChangeItemAmount = (values, index) => {
    const { floatValue } = values;
    let { data } = this.state;
    data.items[index].price = floatValue;

    let total = 0;
    data.items.forEach(item => {
      total += Number(item.price);
    });
    data.fiatPrice = total;

    this.setState({ data: data });
  }

  onItemDelete = (index) => {
    let { data } = this.state;
    data.items.splice(index, 1);
    this.setState({ data: data });
  }

  checkItems = () => {
    const { data } = this.state;
    for (let i = 0; i < data.items.length; i++) {
      if (!data.items[i].item || !data.items[i].price || !data.items[i].quantity) {
        return true;
      }
    }
    return false;
  }

  getPaymentPDF = (orderId) => {
    this.props.getPaymentPDF({
      params: { orderId: orderId },
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/pdf',
        //'Access-Control-Expose-Headers': 'Content-Disposition',
        "Accept-Language": localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') : "en"
      },
      responseType: 'blob'
    }).then(response => {
      this.handleContentDisposition(response);
    });
  }

  handleContentDisposition(response) {
    const blob = new Blob([response.data], { type: 'application/pdf' }); //this make the magic
    const url = window.URL.createObjectURL(blob);
    const iframe = document.createElement('iframe'); //load content in an iframe to print later
    document.body.appendChild(iframe);
    iframe.style.display = 'none';
    iframe.src = url;
    iframe.onload = function () {
      setTimeout(function () {
        iframe.focus();
        iframe.contentWindow.print();
      }, 1);
    };
  }

  componentWillUnmount() {
    this.props.paymentReducer.initData = null
    this.props.paymentReducer.posList = []
  }

  render() {

    const { data, selectedPrintOption } = this.state;
    const { initLoading, initData, posList, errorMessageKyc, incorrectPaymentData, merchantPublicPaymentData } = this.props.paymentReducer;
    const { assets } = this.props.adminReducer;

    const button = (icon, title, marginTop) => {
      return <Button style={{ width: '100px', marginTop: marginTop, height: '40px' }} color="primary" size="small" onClick={this.refresh} startIcon={icon}>{this.props.t(title)}</Button>
    }

    const message = (color, status, pte = false) => {
      return <div style={{ textAlign: 'center' }}>
        <strong>{!pte && <span>{this.props.t("status")}: </span>}<span style={{ color: color }}>{this.props.t(status)}</span></strong>
        {this.props.user && <div>
          {button(<ArrowBack />, "back", 20)}
        </div>}
      </div>;
    }

    let status = initData && posList.some(p => p.orderId === initData.orderId) ? posList.find(p => p.orderId === initData.orderId).status : "";

    if (this.props.match.params.status === "UNDERPAID" && status.startsWith("UNDERPAID")) {

      if (posList[0].status !== null) {
        status = posList[0].status
      } else {
        status = null
      }

    }
    const image = () => {
      /* if (this.props.match.params.status === "UNDERPAID" && status.startsWith("UNDERPAID")) {
        return <img alt="qrCode" width="200px" height="200px" src={"data:image/png;base64," + initData.qrCode} />
      } */

      switch (status) {
        case POS.PAID:
          return <SuccessIcon />
        case POS.CONFIRMED:
          return <SuccessIcon />
        case POS.UNDERPAID:
          return <ErrorIcon />
        case POS.UNDERPAID_CONFIRMED:
          return <ErrorIcon />
        case POS.TIMEOUT:
          return <ErrorIcon />
        default:
          return <img alt="qrCode" width="200px" height="200px" src={"data:image/png;base64," + initData.qrCode} />
      }
    }

    const renderer = ({ hours, minutes, seconds, completed }) => {
      if (initData && initData.orderStatus.startsWith("UNDERPAID")) {
        return status === "PAID" || status === "CONFIRMED" ? message("green", status) : message("red", initData.orderStatus);
      }

      if (!status || status === POS.PAYMENT_IN_PROGRESS || status === POS.NEW) {
        status = !completed ? POS.PAYMENT_IN_PROGRESS : POS.PAYMENT_TIME_HAS_EXPIRED;
      }

      switch (status) {
        case POS.PAYMENT_IN_PROGRESS:
          return <span>
            <strong>{this.props.t("remaining_payment_time")}: <span style={{ color: 'red' }}>{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span></strong>
            {this.props.user && <div style={{ textAlign: 'center' }}>
              <strong style={{ display: 'block', marginTop: '20px', fontSize: '14px' }}>{this.props.t("print_qr_code_for_payment")}</strong>
              <Select
                style={{ marginLeft: '10px', marginRight: '10px', marginTop: '10px', width: '100px' }}
                id="print-option"
                name="print-option"
                value={selectedPrintOption}
                onChange={(e) => this.setState({ selectedPrintOption: e.target.value })}
              >
                <MenuItem key="POS" value="POS">POS</MenuItem>
                <MenuItem key="A4" value="A4">A4</MenuItem>
              </Select>
              {selectedPrintOption === "POS" ? <ReactToPrint
                trigger={() => {
                  return button(<Print />, "print", 0)
                }}
                content={() => this.componentRef}
              /> :
                <Button style={{ width: '100px', height: '40px' }} color="primary" size="small" onClick={() => this.getPaymentPDF(initData.orderId)} startIcon={<Print />}>{this.props.t("print")}</Button>
              }
            </div>}
            {this.props.user && <div style={{ textAlign: 'center' }}>
              {button(<Clear />, "cancel", 20)}
            </div>}
          </span>;
        case POS.PAID:
          return message("green", status);
        case POS.CONFIRMED:
          return message("green", status);
        case POS.PAYMENT_TIME_HAS_EXPIRED:
          return message("red", "payment_time_has_expired", true);
        default:
          return message("red", status);
      }
    };

    return (
      <>
        {incorrectPaymentData ? <NotFound /> :
          <Grid container direction="row" spacing={2} style={!this.props.user ? { height: "100vh", backgroundColor: "#202942", margin: "0px" } : {}}>
            {this.props.user && <Grid item container>
              <Typography variant="h6" style={{ alignItems: 'center', display: 'flex' }}>
                <Fab
                  className="table-fab"
                  title={this.props.t("back")}
                  size="small"
                  color="primary"
                  onClick={() => this.props.history.goBack()}>
                  <ArrowBack />
                </Fab> <span style={{ marginLeft: 10 }}>POS</span>
              </Typography>
            </Grid>}
            <Grid item container direction="column" xs={12} sm={12} md={6} lg={6} xl={3} className={!this.props.user ? "loading-position" : ""} style={!this.props.user ? { backgroundColor: "#202942", textAlign: "center", color: "#ebeff0", paddingRight: '16px' } : { paddingRight: '0px' }}>
              {!this.props.user && <Grid container direction="row" spacing={2} style={{ marginBottom: '20px' }}>
                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12} alignContent="center" alignItems="center">
                  {merchantPublicPaymentData && <Typography variant="h6" style={{ alignItems: 'center', display: 'flex', fontWeight: "700" }}>
                    {merchantPublicPaymentData.logo && <img src={merchantPublicPaymentData.logo} height="auto" width="50px" alt="merchant-logo" style={{ marginRight: '10px' }} />}
                    {merchantPublicPaymentData.name}
                  </Typography>}
                  {merchantPublicPaymentData && <Typography variant="body1" style={{ textAlign: 'center', fontWeight: "400" }}>
                    {merchantPublicPaymentData.paymentDescription}
                  </Typography>}
                </Grid>
              </Grid>}
              {!initData && <Grid container direction="row" spacing={2}>
                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Carousel
                    sx={!this.props.user && { backgroundColor: "white", borderRadius: "10px", border: "1px solid black" }}
                    navButtonsAlwaysVisible={true}
                    indicators={false}
                    autoPlay={false}
                    next={(next) => this.handleChangeAsset(next)}
                    prev={(prev) => this.handleChangeAsset(prev)}
                    navButtonsProps={{
                      style: {
                        color: '#155DA7', backgroundColor: '#E4E9F8'
                      }
                    }}
                  >
                    {assets && assets.length > 0 && assets.map(asset => {
                      return <Paper
                        key={asset.shortName}
                        className="pos-menu"
                        style={{
                          textAlign: 'center',
                          padding: 10,
                          opacity: !asset.active ? '0.4' : 'unset',
                          backgroundColor: !asset.active ? '#e0e0e0' : 'unset'
                        }}
                      >
                        <img alt="edit" src={"data:image/png;base64," + asset.image} style={{ width: "100px", height: "100px" }} />
                        <Typography variant="h6">
                          {asset.name}
                        </Typography>
                      </Paper>
                    })}
                  </Carousel>
                </Grid>

                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                  <form onSubmit={this.handleSubmit} className="" noValidate autoComplete="off" spellCheck="false">
                    <Card className="card-border" style={!this.props.user ? { backgroundColor: "white", borderRadius: "10px", border: "1px solid black" } : { marginBottom: '20px' }}>
                      <CardContent>
                        {hasRole(this.props.user, ["ADMIN"]) && <Grid item>
                          <FormControl fullWidth required>
                            <InputLabel id="merchant">{this.props.t("pos_merchant")}</InputLabel>
                            <Select
                              labelId="merchant"
                              id="merchant"
                              name="merchant"
                              value={this.state.selectedMerchant || ''}
                              onChange={this.handleChangeMerchant}
                              label="merchant">
                              {this.props.adminReducer.posMerchants && this.props.adminReducer.posMerchants.length > 0 && this.props.adminReducer.posMerchants.map(posMerchant => {
                                return <MenuItem key={posMerchant.id} value={posMerchant}>{posMerchant.name}</MenuItem>
                              })}
                            </Select>
                          </FormControl>
                        </Grid>}
                        <Grid item>
                          <TextField
                            label={this.props.t("reference_no")}
                            id="referenceNo"
                            name="referenceNo"
                            onChange={this.handleChange}
                            value={data.referenceNo || ''}
                          />
                        </Grid>
                        {this.props.user && <Grid item style={{ borderRadius: '4px', border: '1px solid lightGray', marginTop: '8px', marginBottom: '4px' }}>
                          <Typography style={{ paddingLeft: '14px', alignItems: 'center', display: 'flex', height: '40px' }}>
                            {this.props.t("items")}
                            <Fab
                              style={{ marginLeft: 'auto', color: '#155DA7', backgroundColor: '#E4E9F8', marginRight: '16px' }}
                              className="table-fab"
                              title={this.props.t("add_item")}
                              size="small"
                              color="primary"
                              onClick={(e) => this.addItem(e)}>
                              <Add />
                            </Fab>
                          </Typography>
                          {data.items && data.items.length > 0 &&
                            <TableContainer component={Paper} style={{ boxShadow: 'none', marginTop: '4px' }}>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell style={{ color: '#155DA7', backgroundColor: '#E4E9F8' }} component="th" scope="row" width="40%">{this.props.t("name")} *</TableCell>
                                    <TableCell style={{ color: '#155DA7', backgroundColor: '#E4E9F8' }} component="th" scope="row" width="25%">{this.props.t("quantity")} *</TableCell>
                                    <TableCell style={{ color: '#155DA7', backgroundColor: '#E4E9F8' }} component="th" scope="row" width="25%">{this.props.t("amount")} *</TableCell>
                                    <TableCell style={{ color: '#155DA7', backgroundColor: '#E4E9F8' }} component="th" scope="row" width="10%"></TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {data.items.map((item, index) => {
                                    return <TableRow key={index}>
                                      <TableCell className="table-cell">
                                        <TextField
                                          autoFocus
                                          id={"item" + index}
                                          name={"item" + index}
                                          onChange={(e) => this.handleChangeItemName(e, index)}
                                          value={item.item}
                                          required
                                          inputProps={{
                                            autoComplete: 'off',
                                            spellCheck: false
                                          }}
                                        />
                                      </TableCell>
                                      <TableCell className="table-cell">
                                        <NumberFormat
                                          required
                                          id={"quantity" + index}
                                          name={"quantity" + index}
                                          thousandSeparator={'.'}
                                          decimalSeparator={','}
                                          decimalScale={2}
                                          fixedDecimalScale={true}
                                          onValueChange={(e) => this.handleChangeItemQuantity(e, index)}
                                          value={item.quantity}
                                          customInput={TextField}
                                        />
                                      </TableCell>
                                      <TableCell className="table-cell">
                                        <NumberFormat
                                          required
                                          id={"amount" + index}
                                          name={"amount" + index}
                                          thousandSeparator={'.'}
                                          decimalSeparator={','}
                                          decimalScale={2}
                                          fixedDecimalScale={true}
                                          onValueChange={(e) => this.handleChangeItemAmount(e, index)}
                                          value={item.amount}
                                          customInput={TextField}
                                        />
                                      </TableCell>
                                      <TableCell className="table-cell">
                                        <Fab
                                          style={{ marginLeft: 'auto', color: 'red', backgroundColor: '#E4E9F8' }}
                                          className="table-fab"
                                          title={this.props.t("delete_item")}
                                          size="small"
                                          color="primary"
                                          onClick={() => this.onItemDelete(index)}>
                                          <ClearIcon className="table-fab-icon" />
                                        </Fab>
                                      </TableCell>
                                    </TableRow>
                                  })}
                                </TableBody>
                              </Table>
                            </TableContainer>}
                        </Grid>}
                        <Grid item>
                          <NumberFormat
                            required
                            id="fiatPrice"
                            name="fiatPrice"
                            label={this.props.t("price")}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            onValueChange={this.handleChangePrice}
                            value={data.fiatPrice}
                            currency={data.currency}
                            customInput={NumberTextField}
                          />
                        </Grid>
                        <Grid item textAlign="center">
                          {hasRole(this.props.user, ["ADMIN"]) ? <Button disabled={!data.cryptoAsset || !data.fiatPrice || !data.merchantId || this.checkItems()} size="large" style={{ marginTop: 10 }} type="submit" variant="contained" color="primary" onClick={this.init}>
                            {this.props.t("pay")}
                          </Button> :
                            <Button disabled={!data.cryptoAsset || !data.fiatPrice || this.checkItems()} size="large" style={{ marginTop: 10 }} type="submit" variant="contained" color="primary" onClick={this.init}>
                              {this.props.t("pay")}
                            </Button>}
                        </Grid>
                      </CardContent>
                    </Card>
                  </form>
                </Grid>
              </Grid>}
              <Grid item>
                {errorMessageKyc !== '' && <Alert style={{ marginTop: '15px' }} severity="error">{this.props.t("incorrect_payment_data")}</Alert>}
              </Grid>
              {incorrectPaymentData && <Grid item>
                {this.props.user && <div style={{ textAlign: 'center' }}>
                  <Button style={{ width: '200px', marginTop: '20px', height: '40px' }} color="primary" size="small" onClick={this.refreshPos} startIcon={<ArrowBack />}>{this.props.t("return_to_pos")}</Button>
                </div>}
              </Grid>}
              {initData && <Grid item container direction="column" xs={12} sm={12} md={12} lg={4} xl={4} style={{ textAlign: 'center' }}>
                <Typography variant="h6">
                  <span><strong>{this.props.t("reference_no")}: {initData.referenceNo}</strong></span>
                  <span style={{ display: 'block' }}><strong>{this.props.t("price")}: <NumberFormat value={initData.fiatPriceMinusPayedFiatAmount ? initData.fiatPriceMinusPayedFiatAmount : initData.fiatPrice} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} /> {initData.currency}</strong></span>
                </Typography>

                <div style={{ textAlign: 'center', margin: "10px", height: "210px" }}>
                  {image()}
                </div>

                <div style={{ textAlign: 'center', fontSize: '14px' }}>
                  <strong>{initData.cryptoAddress}</strong>
                </div>

                {assets && assets.length > 0 && <div style={{ textAlign: 'center', margin: 20 }}>
                  <img alt="logo" style={{ verticalAlign: 'middle', marginRight: 10 }} width="50px" height="50px" src={"data:image/png;base64," + assets.find(asset => asset.shortName === initData.cryptoAsset).image} />
                  <span style={{ fontSize: 20, fontWeight: 'bold' }}><NumberFormat value={initData.quotaPriceMinusPayedCryptoAmount ? initData.quotaPriceMinusPayedCryptoAmount : initData.quotaPrice} displayType={'text'} thousandSeparator={','} decimalSeparator={'.'} decimalScale={8} fixedDecimalScale={true} />{" " + (data.cryptoAsset ? data.cryptoAsset : initData.cryptoAsset)}</span>
                </div>}

                <Countdown zeroPadTime={3} date={initData.timeout} renderer={renderer} />

              </Grid>}
              {!this.props.user && <div className="receipt" style={{ textAlign: 'center', width: '100%' }}>
                <div className="powered-by-wrapper">
                  <span className="powered-by">{this.props.t("powered_by")}</span>
                </div>
                <div>
                  <img width="120px" height="auto" src={publicLogo} alt="public-logo" />
                </div>
                <LanguageMenu />
              </div>}
            </Grid>
            <Grid item container direction="column" xs={12} sm={12} md={6} lg={6} xl={3} style={{ visibility: 'hidden' }}>
              <ComponentToPrint ref={el => (this.componentRef = el)} merchantId={this.state.data.merchantId} />
            </Grid>
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={initLoading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </Grid >}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  merchantReducer: state.merchantReducer,
  paymentReducer: state.paymentReducer,
  adminReducer: state.adminReducer,
  user: state.merchantReducer.user
})

const mapActionsToProps = { init, resetInitData, establishSseConnection, clearKYCErrorMessage, getPOSMerchants, getAssets, getPublicPaymentData, getMerchantPublicPaymentData, setIncorrectPaymentData, getPaymentPDF };

export default connect(mapStateToProps, mapActionsToProps)(withTranslation()(POS))

class ComponentToPrint extends React.Component {
  render() {
    return (
      <POSReceipt merchantId={this.props.merchantId} />
    );
  }
}
