export const SET_EXCHANGE_ORDER_SETTINGS_LOADING = 'SET_EXCHANGE_ORDER_SETTINGS_LOADING';
export const SET_EXCHANGE_ORDER_SETTINGS = 'SET_EXCHANGE_ORDER_SETTINGS';
export const SET_EXCHANGE_ORDER_SETTINGS_PAGE_SIZE = 'SET_EXCHANGE_ORDER_SETTINGS_PAGE_SIZE';
export const SET_EXCHANGES = 'SET_EXCHANGES';
export const SET_CURRENCY_PAIRS = 'SET_CURRENCY_PAIRS';
export const SET_EXCHANGE_ORDER_SETTINGS_GROUPS = 'SET_EXCHANGE_ORDER_SETTINGS_GROUPS'

const types = {
    SET_EXCHANGE_ORDER_SETTINGS_LOADING,
    SET_EXCHANGE_ORDER_SETTINGS,
    SET_EXCHANGE_ORDER_SETTINGS_PAGE_SIZE,
    SET_EXCHANGES,
    SET_CURRENCY_PAIRS,
    SET_EXCHANGE_ORDER_SETTINGS_GROUPS
};

export default types;

