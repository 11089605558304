import React from "react";
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { connect } from 'react-redux';
import { getTokens, updateTokensPageSize, updateToken, deleteToken, createToken } from '../../redux/merchant/actions';
import {
  Grid,
  Button,
  TableCell,
  TableRow,
  CircularProgress,
  Fab,
  Typography
} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBack from '@mui/icons-material/ArrowBack';
import ConfirmationDialog from '../../components/common/ConfirmationDialog';
import Base58Table from "../../components/common/Base58Table";

class Tokens extends React.Component {

  emptyToken = {
    id: null,
    name: '',
    status: ''
  }

  constructor(props) {
    super(props);
    this.state = {
      tokens: [],
      page: 0,
      tokenToDelete: this.emptyToken,
      tokenToLock: this.emptyToken,
      openDialog: false,
      openLockDialog: false,
      openDeleteDialog: false
    };
  }

  componentDidMount() {
    this.getTokens(0);
  }

  getTokens = (page) => {
    this.props.getTokens({
      params: {
        page: page
      }
    });
  }

  render() {
    const { tokenToDelete } = this.state;
    const { tokens, tokensLoading } = this.props.merchantReducer;

    if (tokensLoading) {
      return (
        <div className="loading-position">
          <CircularProgress className="spinner" color="primary" />
        </div>
      )
    }

    let data;
    if (tokens && tokens.length > 0) {
      data = tokens.map((token, index) => {
        return <TableRow key={token.id}>
          <TableCell className="table-cell">{token.id}</TableCell>
          <TableCell className="table-cell">{token.token}</TableCell>
          <TableCell className="table-cell">{this.props.t(token.status.toLowerCase())}</TableCell>
          <TableCell className="table-cell">{moment.tz(token.created, token.timeZone).format('DD.MM.YYYY. HH:mm:ss')}</TableCell>
          <TableCell className="table-cell">{moment.tz(token.updated, token.timeZone).format('DD.MM.YYYY. HH:mm:ss')}</TableCell>
          <TableCell className="table-cell">
            <Fab className="table-fab" title={token.status === "DISABLED" ? this.props.t("activate") : this.props.t("disable")}
              id={"tokens-tbl-actions-lock-" + index} style={{ marginRight: 10 }} color={token.status === "DISABLED" ? "inherit" : "secondary"} onClick={() => this.setState({ openLockDialog: true, tokenToLock: token })}>
              {token.status === "DISABLED" ? <LockIcon className="table-fab-icon" /> : <LockOpenIcon className="table-fab-icon" />}
            </Fab>
            <Fab className="table-fab" title={this.props.t("delete")} id={"tokens-tbl-actions-del-btn-" + index} color="inherit" aria-label="delete" onClick={() => this.setState({ openDeleteDialog: true, tokenToDelete: token })}>
              <DeleteIcon className="table-fab-icon" />
            </Fab>
          </TableCell>
        </TableRow>
      });
    } else {
      data = <TableRow>
        <TableCell align="center" colSpan={6}>
          {this.props.t("records_not_found")}
        </TableCell>
      </TableRow>
    }

    let columns = [
      {
        name: "id",
        dbName: 'id',
        width: '10%',
        sort: false,
        filter: false
      },
      {
        name: "token",
        dbName: 'token',
        width: '20%',
        sort: false,
        filter: false
      },
      {
        name: "status",
        dbName: 'status',
        width: '10%',
        sort: false,
        filter: false
      },
      {
        name: "created",
        dbName: 'created',
        width: '10%',
        sort: false,
        filter: false
      },
      {
        name: "updated",
        dbName: 'updated',
        width: '40%',
        sort: false,
        filter: false
      },
      {
        name: "actions",
        width: '10%'
      }
    ];

    return (
      <Grid container direction="row" spacing={2}>
        <Grid item container>
          <Typography variant="h6" style={{ alignItems: 'center', display: 'flex'}}>
            <Fab 
              className="table-fab"
              title={this.props.t("back")}
              size="small"
              color="primary"
              onClick={() => this.props.history.goBack()}>
              <ArrowBack />
            </Fab> <span style={{ marginLeft: 10 }}>{this.props.t("tokens")}</span>
          </Typography>
          <Button style={{ marginLeft: 'auto' }} onClick={() => this.props.createToken()} color="primary">
            {this.props.t("create_token")}
          </Button>
        </Grid>
        <Grid item container>
          <Base58Table
            columns={columns}
            data={data}
            pagination={false}
          />
        </Grid>
        <ConfirmationDialog
          isOpen={this.state.openLockDialog}
          title={this.state.tokenToLock.status === "ACTIVE" ? this.props.t("disable_token") : this.props.t("activate_token")}
          message={this.props.t("disable_activate_token_message", { action: this.state.tokenToLock.status === "ACTIVE" ? this.props.t("disable_2") : this.props.t("activate_2"), token: this.state.tokenToLock.token })}
          onClose={() => this.setState({ openLockDialog: false })}
          onConfirm={() => this.props.updateToken(this.state.tokenToLock.id, { status: this.state.tokenToLock.status === "ACTIVE" ? "DISABLED" : "ACTIVE" }).then(() => {
            this.setState({ openLockDialog: false }, () => this.getTokens(this.state.page));
          })}
          confirmButtonTitle={this.state.tokenToLock.status === "ACTIVE" ? this.props.t("disable") : this.props.t("activate")}
          closeButtonTitle={this.props.t("close")}
        />
        <ConfirmationDialog
          isOpen={this.state.openDeleteDialog}
          title={this.props.t("delete_token")}
          message={this.props.t("delete_token_message", { token: tokenToDelete.token })}
          onClose={() => this.setState({ openDeleteDialog: false })}
          onConfirm={() => this.props.deleteToken(tokenToDelete.id).then(() => {
            this.setState({ openDeleteDialog: false });
          })}
          confirmButtonTitle={this.props.t("delete")}
          closeButtonTitle={this.props.t("close")}
        />
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  merchantReducer: state.merchantReducer
})

const mapActionsToProps = { getTokens, updateTokensPageSize, updateToken, deleteToken, createToken }

export default connect(mapStateToProps, mapActionsToProps)(withTranslation()(Tokens))
