import types from './types';

const initialState = {
    userLoading: false,
    user: null,
    loginError: null,

    totalElements: 0,
    size: 10,
    isLoading: false,
    orders: [],

    tokensLoading: false,
    tokens: [],

    transactionsLoading: false,
    transactions: [],
    transactionsSize: 10,
    transactionsTotalElements: 0,

    posUsersLoading: false,
    posUsers: [],
    posUsersSize: 10,
    posUsersTotalElements: 0,

    receiptData: null,

    revenue: [],
    dailyRevenues: [],

    twoFADataLoading: false,
    twoFAData: null,

    emailsLoading: false,
    emails: [],

    currentBalances: 0
}

export default function merchant(state = initialState, action) {
    switch (action.type) {
        case types.SET_USER_LOADING:
            return {
                ...state,
                userLoading: true
            }
        case types.SET_USER:
            return {
                ...state,
                user: action.payload,
                userLoading: false
            }
        case types.SET_LOGIN_ERROR:
            return {
                ...state,
                loginError: action.payload,
                userLoading: false,
                user: null
            }
        case types.SET_ORDERS_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case types.SET_ORDERS:
            return {
                ...state,
                orders: action.payload.content ? action.payload.content : [],
                totalElements: action.payload.totalElements ? action.payload.totalElements : 0,
                isLoading: false
            }
        case types.SET_ORDERS_PAGE_SIZE:
            return {
                ...state,
                size: action.payload
            }
        case types.SET_ORDER_HISTORY_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case types.SET_ORDER_HISTORY:
            return {
                ...state,
                orderHistory: action.payload.content ? action.payload.content : [],
                isLoading: false
            }
        case types.SET_ORDER_HISTORY_PAGE_SIZE:
            return {
                ...state,
                size: action.payload
            }
        case types.SET_TOKENS_LOADING:
            return {
                ...state,
                tokensLoading: true
            }
        case types.SET_TOKENS:
            return {
                ...state,
                tokens: action.payload ? action.payload.tokens : [],
                tokensLoading: false
            }
        case types.SET_TOKENS_PAGE_SIZE:
            return {
                ...state,
                tokensSize: action.payload
            }
        case types.SET_TRANSACTIONS_LOADING:
            return {
                ...state,
                transactionsLoading: true
            }
        case types.SET_TRANSACTIONS:
            return {
                ...state,
                transactions: action.payload.content ? action.payload.content : [],
                transactionsTotalElements: action.payload.totalElements ? action.payload.totalElements : 0,
                transactionsLoading: false
            }
        case types.SET_TRANSACTIONS_PAGE_SIZE:
            return {
                ...state,
                transactionsSize: action.payload
            }
        case types.SET_POS_USERS_LOADING:
            return {
                ...state,
                posUsersLoading: true
            }
        case types.SET_POS_USERS:
            return {
                ...state,
                posUsers: action.payload.content,
                posUsersTotalElements: action.payload.totalElements ? action.payload.totalElements : 0,
                posUsersLoading: false
            }
        case types.SET_POS_USERS_PAGE_SIZE:
            return {
                ...state,
                posUsersSize: action.payload
            }
        case types.SET_RECEIPT_DATA:
            return {
                ...state,
                receiptData: action.payload
            }
        case types.SET_REVENUE:
            return {
                ...state,
                revenue: action.payload
            }
        case types.SET_DAILY_REVENUES:
            return {
                ...state,
                dailyRevenues: action.payload
            }
        case types.SET_2FA_DATA_LOADING:
            return {
                ...state,
                twoFADataLoading: true
            }
        case types.SET_2FA_DATA:
            return {
                ...state,
                twoFADataLoading: false,
                twoFAData: action.payload
            }
        case types.SET_EMAILS_LOADING:
            return {
                ...state,
                emailsLoading: true
            }
        case types.SET_EMAILS:
            return {
                ...state,
                emails: action.payload ? action.payload : [],
                emailsLoading: false
            }
        case types.SET_CURRENT_BALANCE:
            return {
                ...state,
                currentBalances: action.payload
            }
        default:
            return state;
    }
}