import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  Grid,
  Typography,
  Fab
} from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';

class Integration extends React.Component {

  constructor(props) {
    super(props);
    this.state = {

    };
  }

  render() {

    const json = {
      "fiatPrice": 10.01,
      "currency": "EUR",
      "cryptoAsset": "BTC",
      "referenceNo": "ref1",
      "notificationUrl": "http://localhost:8885/payment-status",
      "changeAddress": null,
      "items": [
        {
          "item": "testItem",
          "price": 34.80,
          "quantity": 1
        },
        {
          "item": "testItem2",
          "price": 23.00,
          "quantity": 2
        }
      ]
    }

    const json2 = {
      "orderId": "59f26f93-9ea8-47c1-998a-d0da7bd443c1",
      "cryptoAddress": "tb1qxxdaeux3tjxl736yc3asts2j2ync7ykh4rstuu",
      "quotaPrice": 0.0002547722066890,
      "fee": 0,
      "qrCode": "iVBORw0KGgoAAAANSUhEUgAAAMgAAADIAQAAAACFI5MzAAABaElEQVR42u2XwYrDMAxEDfotgX5d4N8SeGfkFFyWvXn2FDcNjd8hljUeqWP9NcZLXvISFckxrMaIGpbBBxGZuBLzgfuc/awhabMCVzqpKUm6O4KdS0xW8TvclAQXRtna26kiFMX8jF/auUf2wApm7VkRSSh9DIdAbBUyGCoCFTJnyNxoTYpIYQEOSsglhIpgHue3drx1ZO4uoQ7hFDy+XMaZ09sE22qc7mCniKRRJHw5DBaGESoSRXNdZIjXlogU5RH8YCX8KSJpsHDnjkIh9uROQQLMsmfS41zBVcL4aEdGy2D6RKS6IiFnNKXI0ymuEpY/y8+Oflf0qwQDNwofeB07epswXz3ZApkqUqzjULzT+/YBVpCnOnFH8fazc7hLWMTZDBmLB81WRNpUEeq2ozwc6TLJZ0cZJ5pjUxLvLtJmlw8d6WMVi2X2VPxdQtGj747dNcwlIq2Q6F6LvYOHiLz/NV/ykn8jP17NZLoaN3PIAAAAAElFTkSuQmCC",
      "timeout": 1631259137343
    }

    const json3 = {
      "orderId": "8d9c7847-93d5-4246-b633-8bb97df5297a",
      "referenceNo": "7cbc37c6-ee71-4b51-badd-790f32e1a58a",
      "cryptoAddress": "tb1qmzmcpagdfds3g6jjfjzn23le87cwqf8n9kxjh7",
      "amount": 0.50023747277724170000,
      "change": 0,
      "status": "PAID",
      "date": 1641883252517,
      "signature": "efeac2e57c36296024ec795a7520949d4379032de976ec412c4ed53e84bf96a7"
    }

    return (
      <Grid container direction="row" spacing={2}>
        <Grid item container>
          <Typography variant="h6" style={{ alignItems: 'center', display: 'flex' }}>
            <Fab
              className="table-fab"
              title={this.props.t("back")}
              size="small"
              color="primary"
              onClick={() => this.props.history.goBack()}>
              <ArrowBack />
            </Fab> <span style={{ marginLeft: 10 }}>{this.props.t("integration")}</span>
          </Typography>
        </Grid>
        <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid item style={{ padding: '20px' }}>
            <Typography variant="h6" gutterBottom component="div">
              <strong>Init payment request</strong>
            </Typography>
            <Typography variant="subtitle1" gutterBottom style={{ marginTop: "20px" }}>
              <strong>Security</strong>
            </Typography>
            <Typography variant="body1">
              Each request must contain "ApiAuth-Key" header with merchant token.
            </Typography>
            <Typography variant="body1">
              Each request must contain "ApiAuth-Nonce" header with request nonce (Long value). Must have bigger value for each merchant payment request.
            </Typography>
            <Typography variant="body1" gutterBottom>
              Each request must contain "ApiAuth-MAC" header with calculated signature.
            </Typography>

            <Typography variant="subtitle1" gutterBottom style={{ marginTop: "20px" }}>
              <strong>Staging test merchant API TOKEN:</strong>
            </Typography>
            <Typography variant="body1" gutterBottom>
              b7aa5ef0-28aa-42e4-9d80-15976e5b7579
            </Typography>
            <Typography variant="body1" gutterBottom>
              <strong>POST request body to http://localhost:8098/api/v1/payments/init</strong>
            </Typography>
            <Typography variant="body1" gutterBottom component={'div'}>
              <pre>
                <code>
                  {JSON.stringify(json, null, 2)}
                </code>
              </pre>
            </Typography>
            <Typography variant="body1" gutterBottom>
              <strong>Response body:</strong>
            </Typography>
            <Typography variant="body1" gutterBottom component={'div'}>
              <pre>
                <code>
                  {JSON.stringify(json2, null, 2)}
                </code>
              </pre>
            </Typography>

            <Typography variant="subtitle1" gutterBottom style={{ marginTop: "20px" }}>
              <strong>Init payment request signature calculation</strong>
            </Typography>
            <Typography variant="body1">
              Append all values (not field names) in the following order:
            </Typography>
            <Typography variant="body1">
              ApiAuth-Key + ApiAuth-Nonce + referenceNo + fiatPrice + cryptoAsset + SECRET (secret is set on merchant admin site)
            </Typography>
            <Typography variant="body1">
              So for example notification above (where secret is set to "testSecret", test merchat API token and nonce=1):
            </Typography>
            <Typography variant="body1" gutterBottom>
              appended values are:
            </Typography>
            <Typography variant="body1" gutterBottom>
              <code>b7aa5ef0-28aa-42e4-9d80-15976e5b75791ref110.01BTCtestSecret</code>
            </Typography>
            <Typography variant="body1" gutterBottom>
              and hash all the appended values using SHA-256 to calculate ApiAuth-MAC header value. Result:
            </Typography>
            <Typography variant="body1" gutterBottom>
              <code>2ac7b76923c4d9104c963c1ec1b4cf3ccdf494a44273a9d7526a211e89e8f1b5</code>
            </Typography>

            <Typography variant="subtitle1" gutterBottom style={{ marginTop: "20px" }}>
              <strong>Notification POST request body to "notificationUrl"</strong>
            </Typography>
            <Typography variant="body1" gutterBottom component={'div'}>
              <pre>
                <code>
                  {JSON.stringify(json3, null, 2)}
                </code>
              </pre>
            </Typography>

            <Typography variant="subtitle1" gutterBottom style={{ marginTop: "20px" }}>
              <strong>Notification signature check</strong>
            </Typography>
            <Typography variant="body1" gutterBottom>
              Append all values (not field names) in the following order:
            </Typography>
            <Typography variant="body1" gutterBottom>
              orderId+referenceNo+cryptoAddress+amount+change+status+date+PASSWORD (password is set on merchant admin site)
            </Typography>
            <Typography variant="body1" gutterBottom>
              So for example notification above (where password is set to "testPassword"):
            </Typography>
            <Typography variant="body1" gutterBottom>
              appended values are:
            </Typography>
            <Typography variant="body1" gutterBottom>
              <code>8d9c7847-93d5-4246-b633-8bb97df5297a7cbc37c6-ee71-4b51-badd-790f32e1a58atb1qmzmcpagdfds3g6jjfjzn23le87cwqf8n9kxjh70.500237472777241700000PAID1641883252517testPassword</code>
            </Typography>
            <Typography variant="body1" gutterBottom>
              and hash all the appended values using SHA-256 to get the data to be compared with received signature.
            </Typography>

            <Typography variant="subtitle1" gutterBottom style={{ marginTop: "20px" }}>
              <strong>Payment order STATUSES in notification</strong>
            </Typography>
            <Typography variant="body1" gutterBottom>
              <strong>NEW</strong> - payment order initiated
            </Typography>
            <Typography variant="body1" gutterBottom>
              <strong>CHANGE_ADDRESS_DIRTY</strong> - change address dirty
            </Typography>
            <Typography variant="body1" gutterBottom>
              <strong>PAID</strong> - 0 confirmations
            </Typography>
            <Typography variant="body1" gutterBottom>
              <strong>CONFIRMED</strong> - enough confirmations fulfilled
            </Typography>
            <Typography variant="body1" gutterBottom>
              <strong>CONFIRMED_WITH_CHANGE</strong> - enough confirmations fulfilled with change returned
            </Typography>
            <Typography variant="body1" gutterBottom>
              <strong>COMPLETED</strong> - settlement with merchant done
            </Typography>
            <Typography variant="body1" gutterBottom>
              <strong>UNDERPAID</strong> - 0 confirmations - payment is with underpaid
            </Typography>
            <Typography variant="body1" gutterBottom>
              <strong>UNDERPAID_CONFIRMED</strong> - payment is with underpaid - no return because returning of money is disabled for merchant
            </Typography>
            <Typography variant="body1" gutterBottom>
              <strong>UNDERPAID_RETURNED</strong> - enough confirmations fulfilled - payment is with underpaid amount which is returned
            </Typography>
            <Typography variant="body1" gutterBottom>
              <strong>UNDERPAID_NO_RETURN</strong> - enough confirmations fulfilled - payment is with underpaid and amount is not returned
            </Typography>
            <Typography variant="body1" gutterBottom>
              <strong>TIMEOUT</strong> - 0 confirmations - payment is done after timeout
            </Typography>
            <Typography variant="body1" gutterBottom>
              <strong>TIMEOUT_CONFIRMED</strong> - payment is done after timeout and amount is not returned because returning of money is disabled for merchant
            </Typography>
            <Typography variant="body1" gutterBottom>
              <strong>TIMEOUT_RETURNED</strong> - enough confirmations fulfilled - payment is done after timeout and amount is returned
            </Typography>
            <Typography variant="body1" gutterBottom>
              <strong>TIMEOUT_NO_RETURN</strong> - enough confirmations fulfilled - payment is done after timeout and amount is not returned
            </Typography>
            <Typography variant="body1" gutterBottom>
              <strong>ADDRESS_DIRTY</strong> - address dirty, inputs locked
            </Typography>
            <Typography variant="body1" gutterBottom>
              <strong>FAILED</strong> - payment order failed
            </Typography>

          </Grid>
        </Grid >
      </Grid >
    );
  }
}

const mapStateToProps = (state) => ({

})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(withTranslation()(Integration))
