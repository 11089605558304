import axios from '../axiosConfig';

export function getMerchantPOSReports(config) {
    return axios.get('/api/v1/reports/merchant/pos', config);
}

export function getAdminPOSReports(config) {
    return axios.get('/api/v1/reports/admin/pos', config);
}

export function getSettlementReport(settlementOrderId, config) {
    return axios.get(`/api/v1/reports/settlements/${settlementOrderId}`, config);
}

export function getAccountingReport(config) {
    return axios.get(`/api/v1/reports/admin/accounting`, config);
}

export function getPaymentPDF(config) {
    return axios.get(`/api/v1/reports/payment-pdf`, config);
}

const actions = {
    getMerchantPOSReports,
    getAdminPOSReports,
    getSettlementReport,
    getAccountingReport,
    getPaymentPDF
}

export default actions;
