import api from '../../api/reportsApi';
import { snackActions } from '../snackbar/reducer';

export const getMerchantPOSReports = (config) => async (dispatch) => {
  return await api.getMerchantPOSReports(config).then(response => {
    dispatch(snackActions.success("Export downloaded successfully"));
    return response;
  }).catch(error => {
    dispatch(snackActions.error("Error occurred."));
  });
}

export const getAdminPOSReports = (config) => async (dispatch) => {
  return await api.getAdminPOSReports(config).then(response => {
    dispatch(snackActions.success("Export downloaded successfully"));
    return response;
  }).catch(error => {
    dispatch(snackActions.error("Error occurred."));
  });
}

export const getSettlementReport = (settlementOrderId, config) => async (dispatch) => {
  return await api.getSettlementReport(settlementOrderId, config).then(response => {
    dispatch(snackActions.success("Export downloaded successfully"));
    return response;
  }).catch(error => {
    dispatch(snackActions.error("Error occurred."));
  });
}

export const getAccountingReport = (config) => async (dispatch) => {
  return await api.getAccountingReport(config).then(response => {
    dispatch(snackActions.success("Export downloaded successfully"));
    return response;
  }).catch(error => {
    dispatch(snackActions.error("Error occurred."));
  });
}

export const getPaymentPDF = (config) => async (dispatch) => {
  return await api.getPaymentPDF(config).then(response => {
    //dispatch(snackActions.success("Payment PDF downloaded successfully"));
    return response;
  }).catch(error => {
    dispatch(snackActions.error("Error occurred."));
  });
}