import axios from '../axiosConfig';
import { hasRole } from '../security/Security';

export function createMerchant(data) {
    return axios.post('/api/v1/admin/merchants', data);
}

export function getMerchants(config = {}) {
    return axios.get(`/api/v1/admin/merchants`, config);
}

export function getMerchant(id, config = {}) {
    return axios.get(`/api/v1/admin/merchants/${id}`, config);
}

export function updateMerchant(id, data) {
    return axios.put(`/api/v1/admin/merchants/${id}`, data);
}

export function deleteMerchant(id) {
    return axios.delete(`/api/v1/admin/merchants/${id}`);
}

export function getOrders(config = {}) {
    return axios.get(`/api/v1/admin/orders`, config);
}

export function getOrderHistory(orderId, config = {}) {
    return axios.get(`/api/v1/admin/order-history/${orderId}`, config);
}

export function getMerchantOrders(merchantId, config = {}) {
    return axios.get(`/api/v1/admin/orders/${merchantId}`, config);
}

export function getTransactions(config = {}) {
    return axios.get(`/api/v1/admin/transactions`, config);
}

export function getMerchantTransactions(merchantId, config = {}) {
    return axios.get(`/api/v1/admin/transactions/${merchantId}`, config);
}

export function getRevenue(config = {}) {
    return axios.get(`/api/v1/admin/revenue`, config);
}

export function getSystemConfigurationParameters(config = {}) {
    return axios.get(`/api/v1/admin/system/config/params`, config);
}

export function getSystemConfigurationParameterByKey(key, config = {}) {
    return axios.get(`/api/v1/admin/system/config/get-param/${key}`, config);
}

export function setSystemConfigurationParameter(data, config = {}) {
    return axios.post(`/api/v1/admin/system/config/set-param`, data, config);
}

export function getPOSMerchants(config = {}) {
    return axios.get(`/api/v1/admin/merchants-pos-data`, config);
}

export function getMerchantReceiptData(merchantId, config = {}) {
    return axios.get(`/api/v1/admin/merchant-receipt-data/${merchantId}`, config);
}

export function getHealthChecks(config = {}) {
    return axios.get(`api/v1/admin/general/health-check`, config);
}

export function getDaemonsStatus(config = {}) {
    return axios.get(`api/v1/admin/daemon-service/daemons/status`, config);
}

export function getPaymentData(orderId, config = {}) {
    return axios.get(`api/v1/admin/payments/data/${orderId}`, config);
}

export function getAssets(user, config = {}) {
    let url = null;
    if (hasRole(user, ["ADMIN"])) {
        url = `api/v1/admin/general/asset/info`;
    } else if (hasRole(user, ["MERCHANT"])) {
        url = `api/v1/merchant/asset/info`;
    } else {
        url = `api/v1/payments/public/asset/info`;
    }
    return axios.get(url, config);
}

export function activateAsset(asset, config = {}) {
    return axios.patch(`/api/v1/admin/general/asset/${asset}`, {}, config);
}

export function updateOrderStatus(orderId, data) {
    return axios.post(`/api/v1/admin/order-status-change/${orderId}`, data);
}

export function getMerchantEmails(merchantId, config = {}) {
    return axios.get(`/api/v1/admin/emails/${merchantId}`, config);
}

export function createMerchantEmail(data, config = {}) {
    return axios.post(`/api/v1/admin/emails`, data, config);
}

export function updateMerchantEmail(merchantId, data, config = {}) {
    return axios.put(`/api/v1/admin/emails/${merchantId}`, data, config);
}

export function deleteMerchantEmail(merchantId, config = {}) {
    return axios.delete(`/api/v1/admin/emails/${merchantId}`, config);
}

const actions = {
    createMerchant,
    getMerchants,
    getMerchant,
    updateMerchant,
    deleteMerchant,
    getOrders,
    getOrderHistory,
    getMerchantOrders,
    getTransactions,
    getMerchantTransactions,
    getRevenue,
    getSystemConfigurationParameters,
    getSystemConfigurationParameterByKey,
    setSystemConfigurationParameter,
    getPOSMerchants,
    getMerchantReceiptData,
    getDaemonsStatus,
    getHealthChecks,
    getPaymentData,
    getAssets,
    activateAsset,
    updateOrderStatus,
    getMerchantEmails,
    createMerchantEmail,
    updateMerchantEmail,
    deleteMerchantEmail
}

export default actions;
