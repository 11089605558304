import types from './types';

const initialState = {
    initLoading: false,
    initData: null,
    errorMessageKyc: '',
    posList: [],
    incorrectPaymentData: false,
    merchantPublicPaymentData: null
}

export default function payment(state = initialState, action) {
    switch (action.type) {
        case types.SET_INIT_LOADING:
            return {
                ...state,
                initLoading: true
            }
        case types.SET_INIT_DATA:
            return {
                ...state,
                initLoading: false,
                initData: action.payload
            }
        case types.SET_ERROR_MESSAGE_KYC:
            return {
                ...state,
                errorMessageKyc: action.payload
            }
        case types.SET_POS_LIST:
            return {
                ...state,
                posList: action.payload
            }
        case types.SET_INCORRECT_PAYMENT_DATA:
            return {
                ...state,
                incorrectPaymentData: true
            }
        case types.SET_MERCHANT_PUBLIC_PAYMENT_DATA:
            return {
                ...state,
                merchantPublicPaymentData: action.payload
            }
        default:
            return state;
    }
}