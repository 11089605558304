import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
    Grid,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@mui/material';
import {
    getExchangeOrderSetting,
    getExchanges,
    getCurrencyPairs,
    clearCurrencyPairs,
    createExchangeOrderSetting,
    updateExchangeOrderSetting
} from '../../redux/exchangeSettings/actions';
import NumberFormat from 'react-number-format';

class CreateOrUpdateExchangeOrderSettingDialog extends Component {

    emptyData = {
        active: true,
        currencyPair: "",
        exchange: "",
        percentFee: null,
        id: "",
        maxAmount: null,
        minAmount: null,
        name: "",
        group: ""
    };

    emptyValidation = {
        nameError: false,
        nameErrorText: '',
    };

    constructor(props) {
        super(props);
        this.state = {
            data: JSON.parse(JSON.stringify(this.emptyData)),
            initialData: JSON.parse(JSON.stringify(this.emptyData)),
            validation: JSON.parse(JSON.stringify(this.emptyValidation)),
            uuid: null
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.uuid && prevProps.uuid !== this.props.uuid) {
            this.props.getExchangeOrderSetting(this.props.uuid.toString()).then(response => {
                this.setState({
                    data: response.data.data,
                    initialData: JSON.parse(JSON.stringify(response.data.data)),
                }, () => this.props.getCurrencyPairs(response.data.data.exchange));
            })
        }

        if (this.props.open && prevProps.open !== this.props.open) {
            this.props.getExchanges();
        }
    }

    handleChangeExchange = (event) => {
        const target = event.target;
        let value = target.value;
        const name = target.name;
        let { data } = this.state;
        data[name] = value;
        this.setState({ data: data }, () => this.props.getCurrencyPairs(value));
    }

    handleChange = (event) => {
        const target = event.target;
        let value = target.value;
        const name = target.name;
        let { data } = this.state;
        data[name] = value;
        this.validate(name, value);
        this.setState({ data: data });
    }

    handleChangeNumber = (values, name) => {
        const { floatValue } = values;
        let { data } = this.state;
        data[name] = floatValue;
        this.setState({ data: data });
    }

    createOrUpdateExchangeOrderSetting = (e) => {
        e.preventDefault();
        const { data } = this.state;

        const dataObject = {
            ...data,
            group: data.group === "" ? null : data.group
        }
        const action = this.props.uuid ?
            this.props.updateExchangeOrderSetting(this.props.uuid, dataObject) :
            this.props.createExchangeOrderSetting(dataObject);
        action.then((response) => {
            if (response.status !== 400) {
                this.setState({ data: JSON.parse(JSON.stringify(this.emptyData)) });
                this.props.onSave();
            }
        })
    }

    onClose = () => {
        this.setState({
            data: JSON.parse(JSON.stringify(this.emptyData)),
            validation: JSON.parse(JSON.stringify(this.emptyValidation)),
            initialData: JSON.parse(JSON.stringify(this.emptyData))
        }, () => this.props.onClose());
        this.props.clearCurrencyPairs();
    }

    disableButton = () => {
        const { data, validation, initialData } = this.state;

        if (validation.nameError) {
            return true;
        }

        if (JSON.stringify(initialData) === JSON.stringify(data)) {
            return true;
        }

        if (data.name &&
            data.exchange &&
            data.currencyPair &&
            (data.percentFee || data.percentFee === 0) &&
            (data.minAmount || data.minAmount === 0) &&
            (data.maxAmount || data.maxAmount === 0)) {
            return false;
        }
        return true;
    }

    validate(field, value) {
        let { validation } = this.state;
        let regex = /^.{6,255}$/;
        switch (field) {
            case "name":
                if (value.length === 0) {
                    validation.nameError = true;
                    validation.nameErrorText = this.props.t("required_field");
                } else if (!regex.test(value)) {
                    validation.nameError = true;
                    validation.nameErrorText = this.props.t("validation_message", { field: this.props.t("name") })
                } else {
                    validation.nameError = false;
                    validation.nameErrorText = "";
                }
                this.setState({ validation: validation });
                break;
            default:
                return;
        }
    }

    render() {
        const { data, validation } = this.state;

        return (
            <Dialog fullWidth open={this.props.open} onClose={() => this.onClose()}>
                <DialogTitle>{this.props.uuid ? this.props.t("edit_exchange_order_setting") : this.props.t("new_exchange_order_setting")}</DialogTitle>
                <form onSubmit={this.createOrUpdateExchangeOrderSetting} autoComplete="off" spellCheck="false">
                    <DialogContent>
                        <Grid container direction="row" spacing={2}>
                            <Grid item container direction="column" xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Grid item>
                                    <TextField
                                        className=""
                                        label={this.props.t("name")}
                                        id="name"
                                        name="name"
                                        onChange={this.handleChange}
                                        value={data.name}
                                        required
                                        helperText={validation.nameErrorText}
                                        error={validation.nameError}
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        label={this.props.t("group")}
                                        id="group"
                                        name="group"
                                        onChange={this.handleChange}
                                        value={data.group || ""}
                                    />
                                </Grid>
                                <Grid item>
                                    <FormControl variant="outlined" margin="dense" fullWidth required>
                                        <InputLabel id="exchange" margin="dense">{this.props.t("exchange")}</InputLabel>
                                        <Select
                                            labelId="exchange"
                                            id="exchange"
                                            name="exchange"
                                            value={data.exchange}
                                            disabled={this.props.uuid ? true : false}
                                            onChange={this.handleChangeExchange}
                                            label="exchange">
                                            {this.props.exchanges && this.props.exchanges.length > 0 && this.props.exchanges.map(exchange => {
                                                return <MenuItem key={exchange} value={exchange}>{exchange}</MenuItem>
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <FormControl variant="outlined" margin="dense" fullWidth required>
                                        <InputLabel id="currencyPair" margin="dense">{this.props.t("currency_pair")}</InputLabel>
                                        <Select
                                            labelId="currencyPair"
                                            id="currencyPair"
                                            name="currencyPair"
                                            value={data.currencyPair}
                                            onChange={this.handleChange}
                                            label="currencyPair"
                                            disabled={this.props.uuid ? true : false}>
                                            {this.props.currencyPairs && this.props.currencyPairs.length > 0 && this.props.currencyPairs.map(currencyPair => {
                                                return <MenuItem key={currencyPair} value={currencyPair}>{currencyPair}</MenuItem>
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid item container direction="column" xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Grid item>
                                    <NumberFormat
                                        required
                                        id="minAmount"
                                        name="minAmount"
                                        label={this.props.t("min_amount")}
                                        thousandSeparator={'.'}
                                        decimalSeparator={','}
                                        decimalScale={8}
                                        //fixedDecimalScale={true}
                                        onValueChange={(values) => this.handleChangeNumber(values, "minAmount")}
                                        value={data.minAmount}
                                        customInput={TextField}
                                    />
                                </Grid>
                                <Grid item>
                                    <NumberFormat
                                        required
                                        id="maxAmount"
                                        name="maxAmount"
                                        label={this.props.t("max_amount")}
                                        thousandSeparator={'.'}
                                        decimalSeparator={','}
                                        decimalScale={8}
                                        //fixedDecimalScale={true}
                                        onValueChange={(values) => this.handleChangeNumber(values, "maxAmount")}
                                        value={data.maxAmount}
                                        customInput={TextField}
                                    />
                                </Grid>
                                <Grid item>
                                    <NumberFormat
                                        required
                                        id="percentFee"
                                        name="percentFee"
                                        label={this.props.t("fee_percent")}
                                        //thousandSeparator={','}
                                        decimalSeparator={','}
                                        //decimalScale={8}
                                        //fixedDecimalScale={true}
                                        onValueChange={(values) => this.handleChangeNumber(values, "percentFee")}
                                        value={data.percentFee}
                                        customInput={TextField}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button color="inherit" onClick={() => this.onClose()} variant="contained">
                            {this.props.t("close")}
                        </Button>
                        <Button disabled={this.disableButton()} type="submit" variant="contained" color="primary">
                            {this.props.uuid ? this.props.t("update") : this.props.t("save")}
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => ({
    exchanges: state.exchangeSettingsReducer.exchanges,
    currencyPairs: state.exchangeSettingsReducer.currencyPairs,
});

const mapActionsToProps = { getExchangeOrderSetting, getExchanges, createExchangeOrderSetting, updateExchangeOrderSetting, getCurrencyPairs, clearCurrencyPairs };

export default connect(mapStateToProps, mapActionsToProps)(withTranslation()(CreateOrUpdateExchangeOrderSettingDialog));
